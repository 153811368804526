import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useGetEULA } from 'hooks/api/Settings/useGetEULA'
import { useEULAAcceptedDate } from 'stores/useEULAAcceptedDate'

export const EULADialog = ({
  isModalOpen,
  onClose,
  onConfirm,
  showEULAUpdatedLabel,
  showCancelButton,
}: {
  isModalOpen: boolean
  showEULAUpdatedLabel: boolean
  showCancelButton: boolean
  onClose: () => void
  onConfirm: () => void | Promise<void>
}) => {
  const { data: fileData } = useGetEULA()

  const setAcceptedDate = useEULAAcceptedDate((state) => state.setAcceptedDate)

  if (fileData?.fileData === undefined) {
    return <>No EULA Found.</>
  }

  const base64 = `data:application/pdf;base64,${String(fileData?.fileData)}`
  const pdfContentType = 'application/pdf'
  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substring(
      `data:${pdfContentType};base64,`.length
    )

    const bytes = atob(base64WithoutPrefix)
    let length = bytes.length
    const out = new Uint8Array(length)

    while (length--) {
      out[length] = bytes.charCodeAt(length)
    }

    return new Blob([out], { type: pdfContentType })
  }
  const url = !base64.includes('undefined')
    ? URL.createObjectURL(base64toBlob(base64))
    : null

  return (
    <Dialog
      fullScreen={true}
      open={isModalOpen}
      onClose={() => {
        onClose()
      }}
      fullWidth
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={{ xs: 2, sm: 4 }} py={{ xs: 3, sm: 4 }}>
          <Typography variant="h3">End User License Agreement</Typography>
          <div hidden={!showEULAUpdatedLabel}>
            <Typography variant="label-form" color={'text.secondary'}>
              In order to continue to J&J Connect, you must accept the updated
              EULA.
            </Typography>
          </div>
        </Box>
      </DialogTitle>

      {/*   <object
        width="100%"
        height="100%"
        data={`data:application/pdf;base64,${String(fileData?.fileData)}`}
        type="application/pdf"
      /> */}

      {/* <embed
        src={`data:application/pdf;base64,${String(fileData?.fileData)}`}
      /> */}

      <iframe
        title="EULA"
        allowFullScreen
        width="100%"
        height="100%"
        src={url ?? ''}
      ></iframe>

      <DialogActions className="border-t">
        <Box className="flex flex-row justify-end py-4 md:px-8 sm:px-4">
          <div hidden={!showCancelButton}>
            <Button
              variant="text"
              onClick={async () => {
                setAcceptedDate('')
                await onConfirm()
                onClose()
              }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            onClick={async () => {
              setAcceptedDate(new Date().toLocaleString())
              await onConfirm()
              onClose()
            }}
          >
            Accept
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
