import { Typography } from '@mui/material'
import { isNil } from 'lodash'

export const DeployVersion = () => {
  if (isNil(import.meta.env.VITE_DEPLOY_VERSION)) {
    return null
  }

  return (
    <Typography variant="body-2" className="text-secondary">
      v{import.meta.env.VITE_DEPLOY_VERSION}
    </Typography>
  )
}
