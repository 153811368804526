import { ProfileContent } from './ProfileContent'
import { Box } from '@mui/material'

export const MainContent = () => {
  return (
    <Box className="App">
      <ProfileContent />
    </Box>
  )
}
