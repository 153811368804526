import { Typography, styled } from '@mui/material'

const RequiredStar = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  marginLeft: '4px',
}))

export const RequiredSelectLocations = () => {
  return (
    <>
      <Typography variant="h3" py={1}>
        Select Locations<RequiredStar>*</RequiredStar>
      </Typography>
    </>
  )
}
