import { Box, Typography } from '@mui/material'
import NoDataFound from 'assets/noData.svg'
import { colorPrimitives } from 'components/Theme'

interface NoDataIndicatorProps {
  noDataMessage?: string
  imageSource?: string
  noImage?: boolean
}

export const NoDataIndicator = ({
  noDataMessage = 'No data found',
  imageSource = NoDataFound,
  noImage = false,
}: NoDataIndicatorProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgcolor={colorPrimitives.lightGray}
      padding={{ xs: 7 }}
      marginTop={{ xs: 2 }}
    >
      {!noImage && <img src={imageSource} alt="No data found icon" />}
      <Typography
        variant="label-form"
        marginTop={{ xs: noImage ? 1 : 3 }} // Adjust margin top based on presence of image
        color="rgba(41, 43, 38, 0.60)"
      >
        {noDataMessage}
      </Typography>
    </Box>
  )
}
