import { useMutation, type UseMutationOptions } from '@tanstack/react-query'
import {
  type FetchReturnType,
  type ApiError,
  type ApiResponse,
} from 'openapi-typescript-fetch'
import { type ISchema, type InferType } from 'yup'

export const useApiMutation = <FetchT, SchemaT extends ISchema<unknown>>(
  args: UseMutationOptions<
    ApiResponse<FetchReturnType<FetchT>>,
    ApiError,
    InferType<SchemaT>
  >
) =>
  useMutation<
    ApiResponse<FetchReturnType<FetchT>>,
    ApiError,
    InferType<SchemaT>
  >(args)
