import { isNil } from 'lodash'
import { useApiQuery } from 'hooks/useApiQuery'
import { type LicensedEstablishment } from 'src/types/api'
import { type UseQueryResult } from '@tanstack/react-query'

const getAllRoute = '/le-connect/licensed-establishments/corporate-account/{id}'

export const useGetLicensedEstablishmentByCorporateAccount = ({
  corporateAccountId,
}: {
  corporateAccountId: number
}): UseQueryResult<LicensedEstablishment[]> => {
  return useApiQuery({
    path: getAllRoute,
    method: 'get',
    queryKey: [getAllRoute, corporateAccountId],
    queryArgs: { id: corporateAccountId },
    enabled: !isNil(corporateAccountId),
  })
}
