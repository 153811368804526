import {
  DataGrid,
  type GridSortModel,
  type DataGridProps,
} from '@mui/x-data-grid'
import { useLayout } from '../../hooks/useLayout'
import { MobileDataTable } from './MobileDataTable'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import { NoDataIndicator } from './NoDataIndicator'
import NoDataFound from 'assets/noData.svg'

export const DataTable = ({
  sortFieldName,
  sort,
  noDataMessage = 'No data found',
  imageSource = NoDataFound,
  ...props
}: DataGridProps & {
  sortFieldName?: string
  sort?: 'asc' | 'desc'
  noDataMessage?: string
  imageSource?: string
}) => {
  const { isMobile } = useLayout()
  const { columns, rows, onRowClick } = props

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: sortFieldName ?? 'patronCheckinDate',
      sort: sort ?? 'desc',
    },
  ])

  if (isEmpty(rows)) {
    return (
      <NoDataIndicator
        noDataMessage={noDataMessage}
        imageSource={imageSource}
      />
    )
  }

  if (isMobile) {
    return (
      <MobileDataTable columns={columns} rows={rows} onRowClick={onRowClick} />
    )
  }

  return (
    <DataGrid
      autoHeight
      disableRowSelectionOnClick
      disableColumnMenu
      sortModel={sortModel}
      onSortModelChange={(model) => {
        setSortModel(model)
      }}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 25 },
        },
      }}
      hideFooter={props.rows.length <= 25}
      pageSizeOptions={[25, 50, 100]}
      {...props}
    />
  )
}
