import { Box } from '@mui/material'

const indentation = 24

interface IndentedCellProps {
  depth: number
  extraIndentation?: number
  children: React.ReactNode
}

export const IndentedCell = ({
  depth,
  children,
  extraIndentation = 0,
}: IndentedCellProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        pl: `${depth * indentation + extraIndentation}px`,
      }}
    >
      {children}
    </Box>
  )
}
