import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'

export const useNumberParam = (key: string) => {
  const params = useParams()
  const value = params[key]
  if (isNil(value)) {
    throw new Error(`Param ${key} not found`)
  }

  return parseInt(value)
}
