import { type components } from 'api/playerPayback/api'
import { useApiQuery } from 'hooks/useApiQuery'

const path = '/le-connect/dashboards/corporate-accounts/{corporateAccountId}'
export type GetDashboardData = components['schemas']['DashboardResponse']

export const useGetDashBoard = ({
  corporateAccountId,
}: {
  corporateAccountId: number | undefined
}) => {
  return useApiQuery({
    path,
    method: 'get',
    queryKey: [path, corporateAccountId],
    queryArgs: { corporateAccountId },
  })
}
