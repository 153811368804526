import { Box, Typography, capitalize } from '@mui/material'
import { TextField } from 'components/TextField/TextField'
import { colorPrimitives } from 'components/Theme'
import { Controller, useFormContext } from 'react-hook-form'
import type { SignUpFormFields } from './FormUtils/SignUpFormFields'
import { isPresent } from '@jjvgaming/player-payback-library'
import {
  FormFieldMessage,
  FormFieldMessageVariant,
} from 'components/FormFieldMessage/FormFieldMessage'
import { useSignUpPageSchemaState } from 'stores/useSignUpPageSchemaStateStore'
import ErrorIcon from 'assets/errorIcon.svg'
import { useState } from 'react'
import { SectionsHeader } from './SectionsHeader'
import { ActivityButton } from 'components/ActivityButton/ActivityButton'
import { useSnackbar } from 'stores/useSnackbar'
import { Link as ReactRouterLink } from 'react-router-dom'

export const EmailSection = ({
  changeSection,
}: {
  changeSection: (section: string) => void
}) => {
  const formMethods = useFormContext<SignUpFormFields>()
  const {
    formState: { errors },
    trigger,
    watch,
    setValue,
  } = formMethods
  const [email, setEmail] = useState(watch('email'))
  const isValidating = useSignUpPageSchemaState(
    (state) => state.isValidatingEmail
  )
  const setIsValidating = useSignUpPageSchemaState(
    (state) => state.setValidatingEmail
  )
  const setMessage = useSnackbar((state) => state.setMessage)

  const validateAndNavigateToNextSection = async () => {
    setValue('email', email)
    setMessage(null)
    setIsValidating(true)
    const validationPassed = await trigger(['email'])
    if (validationPassed) {
      changeSection('passwordSection')
    }
    setIsValidating(false)
  }

  return (
    <Box className="flex justify-center flex-col w-full">
      <SectionsHeader
        title="Enter Email"
        changeSection={changeSection}
        previousSection="legalDisclaimerSection"
      />
      <Controller
        name="email"
        render={() => (
          <TextField
            name="email"
            placeholder="Enter your Email"
            type="email"
            error={isPresent(errors.email)}
            autoCorrect="false"
            spellCheck={false}
            autoComplete="true"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        )}
      />
      {isPresent(errors.email) && isPresent(errors.email.message) && (
        <FormFieldMessage
          message={capitalize(errors.email.message)}
          variant={FormFieldMessageVariant.Error}
          icon={ErrorIcon}
        />
      )}
      <Box className="pt-6 pb-8">
        <ActivityButton
          sx={{ width: '100%' }}
          variant="contained"
          onClick={async () => {
            await validateAndNavigateToNextSection()
          }}
          active={isValidating}
        >
          <Typography variant="label-CTA">Next</Typography>
        </ActivityButton>
      </Box>
      <Typography variant="label-CTA" color={colorPrimitives.redGaming}>
        <ReactRouterLink to={'/'}>
          Already have an account? Sign In
        </ReactRouterLink>
      </Typography>
    </Box>
  )
}
