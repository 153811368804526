import { MenuItem, Select, FormControl, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { RequiredSelectLocation } from 'components/Shared/RequiredSelectLocation'
import { colorPrimitives } from 'components/Theme'
import { useGetMe } from 'hooks/api/useGetMe'
import { useLayout } from 'hooks/useLayout'
import { type LicensedEstablishment } from 'src/types/api'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

interface SelectLicensedEstablishmentProps {
  onSelectLicensedEstablishment: (le: LicensedEstablishment) => void
}

const formatLicensedEstablishment = ({
  name,
  licenseNumber,
}: LicensedEstablishment) => {
  return (
    <span>
      {name}
      <br />
      <span style={{ color: colorPrimitives.darkGray }}>
        License # {licenseNumber}
      </span>
    </span>
  )
}

const findLicensedEstablishment = ({
  id,
  licensedEstablishments,
}: {
  id: number
  licensedEstablishments: LicensedEstablishment[]
}) => {
  const licensedEstablishment = licensedEstablishments.find(
    (le) => le.id === id
  )

  if (!licensedEstablishment) {
    return <ErrorIndicator />
  }

  return licensedEstablishment
}

export const SelectLicensedEstablishment = ({
  onSelectLicensedEstablishment,
}: SelectLicensedEstablishmentProps) => {
  const isMobile = useLayout()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const meQuery = useGetMe()
  if (meQuery.isPending) <ActivityIndicator />
  if (meQuery.isError) <ErrorIndicator />
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  ) as LicensedEstablishment[]

  return (
    <FormControl fullWidth>
      <RequiredSelectLocation />
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      <Select
        sx={{ width: isMobile.isMobile ? '90vw' : '500px' }}
        displayEmpty
        renderValue={(selectedId: number) => {
          if (!selectedId) {
            return <em>Select Location</em>
          }

          const selectedLicensedEstablishment = findLicensedEstablishment({
            id: selectedId,
            licensedEstablishments,
          })

          return (
            <Typography>
              {formatLicensedEstablishment(
                selectedLicensedEstablishment as LicensedEstablishment
              )}
            </Typography>
          )
        }}
        variant="outlined"
        required={false}
        onChange={(event) => {
          const licensedEstablishment = findLicensedEstablishment({
            id: Number(event.target.value),
            licensedEstablishments,
          })

          onSelectLicensedEstablishment(
            licensedEstablishment as LicensedEstablishment
          )
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem disabled value="">
          <em>Select Location</em>
        </MenuItem>
        {licensedEstablishments.map((le) => (
          <MenuItem
            key={le.id}
            value={le.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {formatLicensedEstablishment(le)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
