import { Box, IconButton, Stack, Typography, Zoom } from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import SupportAgentIcon from 'assets/SupportAgent.svg'
import { isNil } from 'lodash'
import { useState } from 'react'
import { type LicensedEstablishment } from 'src/types/api'

export const AccountManager = ({
  licensedEstablishment,
}: {
  licensedEstablishment: LicensedEstablishment
}) => {
  const [showAccountManager, setShowAccountManager] = useState(false)

  const { accountManagerName, accountManagerEmail, accountManagerPhoneNumber } =
    licensedEstablishment ?? {}
  const isNotNull = (s: string | null | undefined): s is string => {
    return !isNil(s) && s !== ''
  }

  if (
    isNotNull(accountManagerName) &&
    isNotNull(accountManagerEmail) &&
    isNotNull(accountManagerPhoneNumber)
  ) {
    return (
      <>
        <Zoom in={showAccountManager}>
          <Box
            className="h-[80px] w-full max-w-[375px] md:max-w-[475px] md:h-[100px] fixed bottom-5 right-7 rounded-l rounded-r-xl md:rounded-r-full text-white p-6 flex justify-start items-center "
            bgcolor={colorPrimitives.darkRed}
          >
            <Stack spacing={1}>
              <Stack justifyContent={'center'}>
                <Typography
                  lineHeight={'18px'}
                  variant="subtitle-2"
                  fontWeight={700}
                >
                  {licensedEstablishment?.accountManagerName}
                </Typography>
                <Typography lineHeight={'18px'} variant="label-form">
                  Account Manager
                </Typography>
              </Stack>
              <Stack
                direction={'row'}
                spacing={1}
                justifyContent={'center'}
                alignItems={'center'}
                className="whitespace-nowrap"
              >
                <Typography variant="body-2">
                  {licensedEstablishment?.accountManagerPhoneNumber?.replace(
                    / +/g,
                    ''
                  )}
                </Typography>
                <Typography>l</Typography>
                <Typography variant="body-2">
                  {licensedEstablishment?.accountManagerEmail?.replace(
                    / +/g,
                    ''
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Zoom>
        <Box className="fixed bottom-5 right-2 rounded-full text-white flex justify-end items-center">
          <IconButton
            aria-label="accountmanagerbutton"
            focusRipple
            sx={{
              backgroundColor: colorPrimitives.redGaming,
              boxShadow: 10,
            }}
            className="w-[80px] h-[80px] md:w-[100px] md:h-[100px]"
            onClick={() => {
              setShowAccountManager((prev) => !prev)
            }}
          >
            <img className="w-full h-full p-2" src={SupportAgentIcon} />
          </IconButton>
        </Box>
      </>
    )
  }

  return <></>
}
