import { useTypedApiClient } from './useTypedApiClient'
import { type paths } from 'api/playerPayback/api'
import { type OpArgType } from 'openapi-typescript-fetch'
import { useQuery } from '@tanstack/react-query'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'

function isCompleteArg<
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
>(
  arg: Partial<OpArgType<paths[PathT][MethodT]>>
): arg is OpArgType<paths[PathT][MethodT]> {
  for (const key of Object.keys(arg)) {
    if (arg[key as keyof OpArgType<paths[PathT][MethodT]>] === undefined) {
      return false
    }
  }

  return true
}

const getHeaders = ({ accessToken }: { accessToken: string | undefined }) => {
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    }
  } else {
    return undefined
  }
}

interface UseApiQueryParams<
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
> {
  enabled?: boolean
  method: MethodT
  path: PathT
  queryKey: Array<string | number | undefined>
  // We use Partial<> in order to allow for sequential queries
  queryArgs: Partial<OpArgType<paths[PathT][MethodT]>>
  refetchOnWindowFocus?: boolean
}

export const useApiQuery = <
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
>({
  enabled = true,
  method,
  path,
  queryKey,
  queryArgs,
  refetchOnWindowFocus = true,
}: UseApiQueryParams<PathT, MethodT>) => {
  const { accessToken } = useAccessTokenContext()
  const apiCall = useTypedApiClient({ path, method })
  return useQuery({
    enabled: enabled && !!accessToken && isCompleteArg(queryArgs),
    queryKey,
    refetchOnWindowFocus,
    queryFn: async () => {
      const results = await apiCall(
        // This `as` is ugly, but we can be assured all params are available because of `enabled`
        queryArgs as OpArgType<paths[PathT][MethodT]>,
        { headers: getHeaders({ accessToken }) }
      )
      return results.data
    },
  })
}
