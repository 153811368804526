import { Box, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { type LicensedEstablishment } from 'src/types/api'
import { SelectLicensedEstablishment } from '../SelectLicensedEstablishment'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { DataTable } from 'components/Shared/DataTable'
import { ActivityButton } from 'components/ActivityButton'
import { colorPrimitives } from 'components/Theme'
import { useGetFlexPayStatementsByLeId } from 'hooks/api/Reports/useGetFlexPayStatementsByLeId'
import { formatFullDate, pdfDownloadFromByteArray } from 'utils/util'
import { useGetDownloadFlexPayStatements } from 'hooks/api/Reports/useGetDownloadFlexPayStatements'
import { useSnackbar } from 'stores/useSnackbar'

export const FlexPayPage = () => {
  const [activeId, setActiveId] = useState<number | null>(null)
  const [downloadedReports, setDownloadedReports] = useState<number[]>([])
  const meQuery = useGetMe()

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const reportQuery = useGetFlexPayStatementsByLeId({
    licensedEstablishmentId: Number(licensedEstablishment?.id),
    enabled: licensedEstablishment?.id !== undefined,
  })

  const dataRows = Array.isArray(reportQuery.data) ? reportQuery.data : []

  const useGetDownloadFlexPayStatementsMutation =
    useGetDownloadFlexPayStatements({
      onSuccess: () => {},
      onError: () => {},
    })

  const handleDownload = (params: any) => {
    setActiveId(params.id)

    useGetDownloadFlexPayStatementsMutation.mutate(
      {
        licensedEstablishmentId: params.row.licensedEstablishmentId,
        license: params.row.license,
        type: params.row.type,
        blobName: params.row.blobName,
        blobDate: params.row.date,
      },
      {
        onSuccess: (data) => {
          setDownloadedReports([...downloadedReports, params.id])
          pdfDownloadFromByteArray(data, params.row.blobName)
          setActiveId(null)
          setSnackbarMessage('Report successfully downloaded')
        },
        onError: (error) => {
          setActiveId(null)
          console.error('Error:', error)
          setSnackbarMessage(
            'There was an error downloading the Report',
            'error'
          )
        },
      }
    )
  }

  const columns = [
    {
      field: 'name',
      headerName: 'LE Name',
      flex: 0.25,
      valueGetter: (params: {
        row: { licensedEstablishment: { name: string } }
      }) => params.row.licensedEstablishment.name,
    },
    { field: 'type', headerName: 'Type', flex: 0.25 },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.25,
      valueGetter: (params: { row: { date: string } }) => params.row.date,
      valueFormatter: ({ value }: { value: string }) => formatFullDate(value),
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      width: 175,
      renderCell: (params: { row: any }) =>
        !downloadedReports?.includes(params.row.id) ? (
          <ActivityButton
            sx={{
              border: `1px solid ${colorPrimitives.redGaming}`,
              background: 'white',
            }}
            active={
              useGetDownloadFlexPayStatementsMutation.isPending &&
              activeId === params.row.id
            }
            fullWidth
            variant="outlined"
            className="px-6 py-2 rounded"
            onClick={() => handleDownload(params)}
          >
            Download
          </ActivityButton>
        ) : (
          <ActivityButton
            active={
              useGetDownloadFlexPayStatementsMutation.isPending &&
              activeId === params.row.id
            }
            color="success"
            variant="contained"
            onClick={() => handleDownload(params)}
          >
            Complete
          </ActivityButton>
        ),
    },
  ]

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="FlexPay / Income Statements"
            backText="View Reports"
            backPath="/Reports"
            isSecondary={true}
          />
        </Box>
      }
    >
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          <SelectLicensedEstablishment
            onSelectLicensedEstablishment={setLicensedEstablishment}
          />

          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {!reportQuery.data ? (
            <Typography variant="h4">
              No data available for the selected location
            </Typography>
          ) : (
            <Box py={3}>
              <Typography pb={3} variant="h3">
                Download Reports
              </Typography>
              <DataTable
                rowHeight={80}
                columns={columns}
                rows={dataRows}
                getRowId={(row) => row.id}
                hideFooterSelectedRowCount
              />
            </Box>
          )}
        </Stack>
      )}
    </Page>
  )
}
