import { Typography } from '@mui/material'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { isNil } from 'lodash'
import { SweepstakesDetailsTabs } from './SweepstakesDetailsPage/SweepstakesDetailsTabs'
import { type Sweepstake } from '../../types/api'
import { SweepstakesStatusLabel } from './SweepstakesStatusLabel'

export const SweepstakesDetailsHeader = ({
  sweepstake,
  currentTab,
  actionButton,
  children,
}: {
  sweepstake: Sweepstake
  currentTab: string
  actionButton?: React.ReactNode
  children?: React.ReactNode
}) => {
  if (!sweepstake.id) {
    return <Typography>Sweepstake Id must not be null.</Typography>
  }

  return (
    <Page
      header={
        <PageHeader
          title={isNil(sweepstake.name) ? 'Unknown' : sweepstake.name}
          backText="Sweepstakes"
          backPath="/Sweepstakes"
          isSecondary={true}
          status={
            <SweepstakesStatusLabel
              status={sweepstake.sweepstakeData?.state ?? 'Draft'}
            />
          }
          actionButton={actionButton}
          tabs={
            <SweepstakesDetailsTabs
              currentTab={currentTab}
              sweepstakeId={sweepstake.id}
            />
          }
        />
      }
    >
      {children}
    </Page>
  )
}
