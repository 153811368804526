import { useMemo } from 'react'
import * as _ from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { type HourlyCheckinSummary } from 'src/types/api'
import { sub, add, startOfToday } from 'date-fns'
import { colorPrimitives } from 'components/Theme'
import { Box, Stack, Typography } from '@mui/material'

interface CheckinBarChartProps {
  // Results of preparedData
  data: Array<{
    hour: number
    lastDay: number | undefined
    lastSevenDayAverage: number
  }>
  color: string
  dataKey: 'lastDay' | 'lastSevenDayAverage'
}

const CheckinBarChart = ({ color, data, dataKey }: CheckinBarChartProps) => {
  return (
    <ResponsiveContainer height={250} width="100%">
      <BarChart data={data} margin={{ bottom: 20 }}>
        <CartesianGrid strokeOpacity={0.1} />
        <XAxis
          dataKey="hour"
          ticks={[0, 3, 6, 9, 12, 15, 18, 21]}
          tickFormatter={(v: number) => {
            const meridiem = v < 12 ? 'AM' : 'PM'
            const hour = String(v % 12 === 0 ? '12' : v % 12)
            return `${hour}${meridiem}`
          }}
          tick={{ fill: colorPrimitives.black }}
        >
          <Label
            value="Hour"
            offset={0}
            position="bottom"
            style={{ fill: colorPrimitives.black }}
          />
        </XAxis>
        <YAxis
          tick={{ fill: colorPrimitives.black }}
          domain={([_, dataMax]) => [0, Math.max(10, dataMax)]}
        >
          <Label
            value="Check-ins"
            position="center"
            angle={-90}
            style={{ fill: colorPrimitives.black }}
          />
        </YAxis>
        <Bar dataKey={dataKey} fill={color} />
      </BarChart>
    </ResponsiveContainer>
  )
}

interface PatronActivityChartProps {
  data: HourlyCheckinSummary[]
}

export const PatronActivityChart = ({ data }: PatronActivityChartProps) => {
  const preparedData = useMemo(() => {
    return prepareData(data)
  }, [data])

  return (
    <Stack gap={8}>
      <Box>
        <Typography align="center" variant="h2">
          Check-ins over Last 24 hours
        </Typography>
        <CheckinBarChart
          data={preparedData}
          color={colorPrimitives.redGaming}
          dataKey="lastDay"
        />
      </Box>

      <Box>
        <Typography align="center" variant="h2">
          Average Check-ins over Last 7 Days
        </Typography>
        <CheckinBarChart
          data={preparedData}
          color={colorPrimitives.yellow}
          dataKey="lastSevenDayAverage"
        />
      </Box>
    </Stack>
  )
}

function prepareData(data: HourlyCheckinSummary[]) {
  // Use en-UK because it's easier to deal with (no AM/PM)
  const locale = 'en-UK'
  const timeZone = 'America/Chicago'

  // build 24h hour string to match en-UK based hour strings
  const buildHourString = (hourIndex: number) =>
    `${String(hourIndex).padStart(2, '0')}:00:00`

  const now = new Date()
  const today = startOfToday()
  const sevenDaysAgo = sub(today, { days: 7 })
  const localeNowDate = now.toLocaleDateString(locale, { timeZone })
  const localeNowTime = now.toLocaleTimeString(locale, { timeZone })

  const cData = data.map(({ time, numPatronCheckInsTotal }) => {
    const cTime = new Date(time)

    // en-UK formats DD/MM/YYYY
    const localeDate = cTime.toLocaleDateString(locale, { timeZone })
    // en-UK formats 00:00:00
    const localeTime = cTime.toLocaleTimeString(locale, { timeZone })

    return {
      localeDate,
      localeTime,
      numPatronCheckInsTotal,
    }
  })

  // API does a simple group by over PatronCheckIns table, so there might be gaps in hours
  //  eg if there are checkins for 1pm and 4pm, and zero checkins for 2pm and 3pm,
  //  the API will only return rows for 1pm & 4pm
  const dataByDays = _.groupBy(cData, 'localeDate')
  const zeroToTwentyFour = Array.from(Array(24).keys())
  const allDays = Array.from(Array(8).keys()).map((n) =>
    add(sevenDaysAgo, { days: n }).toLocaleDateString(locale, { timeZone })
  )
  const dataByDateAndHours = allDays
    .map((dateString) => {
      return zeroToTwentyFour.map((h) => {
        const calculatedLocaleTime = buildHourString(h)
        const emptyData = {
          localeDate: dateString,
          localeTime: calculatedLocaleTime,
          numPatronCheckInsTotal: 0,
        }

        return (
          dataByDays[dateString]?.find(
            ({ localeTime }) => localeTime === calculatedLocaleTime
          ) ?? emptyData
        )
      })
    })
    .flat()
    .filter(({ localeTime, localeDate }) => {
      return (
        localeDate < localeNowDate ||
        (localeDate === localeNowDate && localeTime <= localeNowTime)
      )
    })

  const reversedData = _.reverse(dataByDateAndHours)

  const getDailyData = (hourIndex: number) => {
    const hourString = buildHourString(hourIndex)
    return reversedData.find(({ localeTime }) => localeTime === hourString)
      ?.numPatronCheckInsTotal
  }

  const getAverageData = (hourIndex: number, n: number) => {
    const allMatches = reversedData.filter(
      ({ localeTime }) => localeTime === buildHourString(hourIndex)
    )

    const x = _.take(allMatches, n).map(
      ({ numPatronCheckInsTotal }) => numPatronCheckInsTotal
    )

    return _.floor(_.sum(x) / n, 1)
  }

  return Array.from(Array(24).keys()).map((hourIndex) => {
    return {
      hour: hourIndex,
      lastDay: getDailyData(hourIndex),
      lastSevenDayAverage: getAverageData(hourIndex, 7),
    }
  })
}
