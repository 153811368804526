import { Box, Stack } from '@mui/material'
import { Page } from 'components/Page'
import { useState } from 'react'
import { useGetHourlyCheckinsReport } from 'hooks/api/Reports/useGetHourlyCheckinsReport'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { type LicensedEstablishment } from 'src/types/api'
import { SelectLicensedEstablishment } from '../SelectLicensedEstablishment'
import { add, sub, startOfToday } from 'date-fns'
import { PatronActivityChart } from './PatronActivityChart'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetMe } from 'hooks/api/useGetMe'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'

export const PatronActivityPage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const today = startOfToday()
  const tomorrow = add(today, { days: 1 })
  const sevenDaysAgo = sub(today, { days: 7 })

  const reportQuery = useGetHourlyCheckinsReport({
    licensedEstablishmentId: licensedEstablishment?.id,
    startDate: sevenDaysAgo,
    endDate: tomorrow,
  })

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Patron Activity"
            backText="View Reports"
            backPath="/Reports"
            isSecondary={true}
          />
        </Box>
      }
    >
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          <SelectLicensedEstablishment
            onSelectLicensedEstablishment={setLicensedEstablishment}
          />

          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data && <PatronActivityChart data={reportQuery.data} />}
        </Stack>
      )}
    </Page>
  )
}
