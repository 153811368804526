import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { NumberField } from 'components/NumberField'
import { useApiMutation } from 'hooks/useApiMutation'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useForm } from 'react-hook-form'
import { type RewardsProgram } from 'src/types/api'
import { number, object } from 'yup'

export const EditAttendantPointMaximumModal = ({
  isOpen,
  closeModal,
  rewardsProgram,
}: {
  isOpen: boolean
  closeModal: () => void
  rewardsProgram: RewardsProgram
}) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(RewardsProgramSchema),
    defaultValues: {
      maxAllowedDailyPoints: rewardsProgram.maxAllowedDailyPoints ?? 0,
    },
  })
  const currentMaxAllowedDailyPoints = watch('maxAllowedDailyPoints', 0)
  const putRewardsProgram = useTypedApiClient({
    path: '/le-connect/rewards-programs/{id}',
    method: 'put',
  })
  const queryClient = useQueryClient()
  const updateRewardsProgram = useApiMutation<
    typeof putRewardsProgram,
    typeof RewardsProgramSchema
  >({
    mutationFn: async (formData) => {
      return await putRewardsProgram({
        ...rewardsProgram,
        ...formData,
      })
    },
    onSuccess: (formData) => {
      rewardsProgram.maxAllowedDailyPoints = formData.data.maxAllowedDailyPoints
      closeModal()
      queryClient
        .invalidateQueries({
          queryKey: [
            '/le-connect/rewards-programs/{id}',
            rewardsProgram.id.toString(),
          ],
        })
        .catch(() => null)
    },
  })

  return (
    <Modal isOpen={isOpen} toggleIsOpen={closeModal}>
      <ModalFullHeader
        toggleIsOpen={closeModal}
        title="Daily Max Points to Award"
      />
      <form>
        <Box className="p-8 max-w-lg flex flex-col gap-4">
          <Typography variant="body-3" className="block">
            This is the amount of points attendants will be able to award a
            patron on a daily basis. Note: This is only available through the
            attendant view when a patron has checked-in at a location.
          </Typography>
          <NumberField
            {...register('maxAllowedDailyPoints')}
            onIncrement={() => {
              setValue(
                'maxAllowedDailyPoints',
                Number(currentMaxAllowedDailyPoints) + 1
              )
            }}
            onDecrement={() => {
              if (Number(currentMaxAllowedDailyPoints) > 0) {
                setValue(
                  'maxAllowedDailyPoints',
                  Number(currentMaxAllowedDailyPoints) - 1
                )
              }
            }}
          />
          <Typography variant="body-3" className="block">
            * To enable this functionality, enter an amount greater than 0 by
            clicking the edit button above.
          </Typography>
        </Box>
      </form>
      <ModalFullFooter>
        <Button onClick={closeModal}>Cancel</Button>
        <ActivityButton
          active={updateRewardsProgram.isPending}
          variant="contained"
          onClick={handleSubmit((data) => updateRewardsProgram.mutate(data))}
        >
          Submit
        </ActivityButton>
      </ModalFullFooter>
    </Modal>
  )
}

const RewardsProgramSchema = object().shape({
  maxAllowedDailyPoints: number().min(0).required(),
})
