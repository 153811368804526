import { type Sweepstake } from '../../../types/api'
import { Box, Grid, Typography } from '@mui/material'
import { SectionHeaderButton } from 'components/Sweepstakes/SectionHeaderButton'
import { SimpleImageCard } from 'components/Sweepstakes/SimpleImageCard'

export const SweepstakeImageSection = ({
  sweepstake,
}: {
  sweepstake: Sweepstake
}) => {
  return (
    <>
      <SectionHeaderButton titleText="Image" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '16px',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          p: '16px',
          mb: '40px',
          mt: '15px',
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body-3" className="w-full">
            Sweepstakes Image
          </Typography>
          <SimpleImageCard
            imageSource={sweepstake.publicImageUrl}
            sx={{ width: '358px' }}
          />
        </Grid>
      </Box>
    </>
  )
}
