import { isPresent } from '@jjvgaming/player-payback-library'
import { Grid, Stack, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton/ActivityButton'
import { useManualTransactionPointAward } from 'hooks/api/useManualTransactionPointAward'
import { type RewardsProgram } from 'src/types/api'
import { useSnackbar } from 'stores/useSnackbar'

export const RewardBox = ({
  points,
  patronId,
  patronName,
  licensedEstablishmentUserId,
  rewardsProgram,
}: {
  points: number
  patronId: number
  patronName: string
  licensedEstablishmentUserId?: number
  rewardsProgram: RewardsProgram
}) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const qc = useQueryClient()
  const mutation = useManualTransactionPointAward({
    onError: (err) => {
      if (
        err.data.type === 'RewardsRedemptionNotAllowedException' &&
        err.data.message.includes('does not have any points to award today')
      ) {
        setSnackbarMessage(`You do not have any points to award`, 'error')
      } else if (
        err.data.type === 'RewardsRedemptionNotAllowedException' &&
        err.data.message.includes('does not have sufficient points to award')
      ) {
        setSnackbarMessage(
          `The point awarding max for ${rewardsProgram.name} has been reached for the day`,
          'error'
        )
      } else if (isPresent(err.data.type)) {
        setSnackbarMessage(err.data.message)
      } else {
        setSnackbarMessage('Something went wrong')
      }
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ['/me'],
      }).catch(() => null)
      qc.invalidateQueries({
        queryKey: ['get-recent-patron-checkin-by-le-id'],
      }).catch(() => null)
      qc.invalidateQueries({
        queryKey: [
          'get-active-patron-rewards-programs-by-licensed-establishment-id',
        ],
      }).catch(() => null)
      qc.invalidateQueries({
        queryKey: ['/patrons/{id}'],
      }).catch(() => null)
      setSnackbarMessage(
        `1 ${rewardsProgram.name} point awarded to ${patronName} `,
        'success'
      )
      qc.invalidateQueries({
        queryKey: ['activitylog'],
      }).catch(() => null)
    },
  })

  const canAwardPoints =
    isPresent(rewardsProgram.maxAllowedDailyPoints) &&
    rewardsProgram.maxAllowedDailyPoints > 0

  return (
    <Grid
      item
      xs={5.8}
      key={`${rewardsProgram.name}-${points}`}
      className="rounded-lg border  p-6 bg-white"
    >
      <Stack>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          pb={canAwardPoints ? '32px' : '0px'}
        >
          <Typography pt={1} variant="body-2">
            {rewardsProgram.name}
          </Typography>
          <Typography pt={1} variant="h2">
            {points}
          </Typography>
        </Stack>
        {canAwardPoints && (
          <ActivityButton
            active={mutation.isPending}
            fullWidth
            variant="contained"
            className="px-6 py-2 rounded"
            onClick={async () => {
              await mutation.mutateAsync({
                patronId,
                amount: 1,
                rewardProgramId: rewardsProgram.id,
                licensedEstablishmentUserId,
              })
            }}
          >
            Award +1 Point
          </ActivityButton>
        )}
      </Stack>
    </Grid>
  )
}
