import { type DefaultValues } from 'react-hook-form'

export interface SignUpFormFields {
  email: string
  password: string
  phoneNumber: string
  firstName: string
  lastName: string
}

export const defaultValues: DefaultValues<SignUpFormFields> = {
  email: '',
  password: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
}
