import { useMsal } from '@azure/msal-react'
import { useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { b2cLoginRequest } from 'src/authConfig'

export const usePostLePatronProfileMetrics = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: () => void
}) => {
  const { instance: msalInstance } = useMsal()
  const post = useTypedApiClient({
    method: 'post',
    path: '/le-connect/patron-profile',
  })
  return useMutation({
    mutationFn: async (input: {
      patronId: number
      licensedEstablishmentId: number
      averageBetInCents?: number
      perceivedLengthOfStayInMinutes?: number
    }) => {
      const { accessToken } = await msalInstance.acquireTokenSilent(
        b2cLoginRequest
      )

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }

      const { data } = await post(
        {
          patronId: input.patronId,
          licensedEstablishmentId: input.licensedEstablishmentId,
          averageBetInCents: input.averageBetInCents,
          perceivedLengthOfStayInMinutes: input.perceivedLengthOfStayInMinutes,
        },
        { headers }
      )
      return data
    },
    onSuccess,
    onError,
  })
}
