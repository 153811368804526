import Box from '@mui/material/Box'
import { BusinessDetailsDesktop } from './BusinessDetails/BusinessDetailsDesktop'
import { ContactDetailsDesktop } from './ContactDetails/ContactDetailsDesktop'
import { AddressDetailsDesktop } from './AddressDetails/AddressDetailsDesktop'
import { ScheduleDetails } from './ScheduleDetails/ScheduleDetails'
import { type LicensedEstablishment } from 'src/types/api'

interface LicensedEstablishmentDetailsProps {
  licensedEstablishment: LicensedEstablishment
}

export const LicensedEstablishmentDetailsDesktop = ({
  licensedEstablishment,
}: LicensedEstablishmentDetailsProps) => {
  return (
    <Box className={'pt-4'}>
      <Box className="pt-4">
        <BusinessDetailsDesktop
          licensedEstablishment={licensedEstablishment}
        ></BusinessDetailsDesktop>
      </Box>

      <Box className="pt-12">
        <ContactDetailsDesktop
          licensedEstablishment={licensedEstablishment}
        ></ContactDetailsDesktop>
      </Box>

      <Box className="pt-12">
        <AddressDetailsDesktop
          licensedEstablishment={licensedEstablishment}
        ></AddressDetailsDesktop>
      </Box>

      <Box className="pt-12">
        <ScheduleDetails
          licensedEstablishment={licensedEstablishment}
        ></ScheduleDetails>
      </Box>
    </Box>
  )
}
