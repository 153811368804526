import { Box, Typography } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { useLayout } from 'hooks/useLayout'
import { dataTableAddressFormatter, formatDateTime } from 'utils/util'
import {
  type CorporateAccount,
  type Organization,
  type LicensedEstablishment,
} from 'src/types/api'
import { CorporateAccountsTreeDataGrid } from 'components/TreeDataGrid/CorporateAccountsTreeDataGrid'
import { get, isNil, last } from 'lodash'
import { type components } from 'api/playerPayback/api'
import notFoundLocation from 'assets/JJlocationNotFound.png'
import { colorPrimitives } from 'components/Theme'

const defaultColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'name',
      minWidth: 300,
      headerName: 'Account Name',
      flex: 1,
      renderCell: (params) => {
        const isLicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        const displayName = isLicensedEstablishment
          ? get(params.row, 'standardName', '')
          : get(params.row, 'name')

        return <p>{displayName}</p>
      },
    },
    {
      field: 'licenseNumber',
      minWidth: 200,
      headerName: 'License Number',
      flex: 0.8,
      valueFormatter: (params: { value: string }) => {
        return isMobile
          ? `License #: ${params.value}`
          : params.value
          ? `#${params.value}`
          : '-'
      },
    },
    {
      field: 'addresses',
      minWidth: 400,
      headerName: 'Address',
      flex: 2,
      valueFormatter: dataTableAddressFormatter(true),
      renderCell: (params) => {
        return (
          <p className="overflow-hidden whitespace-nowrap text-ellipsis	">
            {params.formattedValue === 'Unspecified Address'
              ? '-'
              : params.formattedValue}
          </p>
        )
      },
    },
    {
      field: 'contacts.email',
      minWidth: 300,
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => {
        const contacts = params.row.contacts as
          | Array<components['schemas']['FullLicensedEstablishmentContactDTO']>
          | undefined
        return isNil(contacts) || contacts.length === 0 ? (
          <>-</>
        ) : (
          <>{!isNil(last(contacts)?.email) && last(contacts)?.email}</>
        )
      },
    },
    {
      field: 'signage1',
      headerName: 'Decal',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const isLicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return isLicensedEstablishment
          ? params.row.signage1
            ? 'Yes'
            : 'No'
          : '-'
      },
    },
    {
      field: 'signage2',
      headerName: 'Banner',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const isLicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return isLicensedEstablishment
          ? params.row.signage2
            ? 'Yes'
            : 'No'
          : '-'
      },
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const isLicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return isLicensedEstablishment
          ? params.row.dateAdded
            ? formatDateTime(params.row.dateAdded, 'P p')
            : '-'
          : '-'
      },
    },
  ]
}

export const ParticipatingLocationList = ({
  licensedEstablishments,
  corporateAccounts,
  organizations,
}: {
  licensedEstablishments: LicensedEstablishment[]
  corporateAccounts: CorporateAccount[]
  organizations: Organization[]
}) => {
  const { isMobile } = useLayout()

  if (licensedEstablishments.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor={colorPrimitives.lightGray}
        padding={{ xs: 7 }}
        marginTop={{ xs: 2 }}
      >
        <img src={notFoundLocation} alt="No data found icon" />
        <Typography
          variant="label-form"
          marginTop={{ xs: 3 }}
          color="rgba(41, 43, 38, 0.60)"
        >
          None of your locations are currently enrolled into the sweepstakes
        </Typography>
      </Box>
    )
  }

  return (
    <Box paddingTop={3}>
      <CorporateAccountsTreeDataGrid
        columns={defaultColumns(isMobile)}
        corporateAccounts={corporateAccounts}
        organizations={organizations}
        licensedEstablishments={licensedEstablishments}
        hideFooter={licensedEstablishments.length <= 20}
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } },
        }}
        hideFooterSelectedRowCount
        pageSizeOptions={[50, 75, 100]}
        sx={{ maxWidth: '82.5vw' }}
        getRowClassName={() => '.white-background'}
      />
    </Box>
  )
}
