import { useApiQuery } from 'hooks/useApiQuery'

const path =
  '/le-connect/patrons/{patronId}/rewards-programs/licensed-Establishments/{licensedEstablishmentId}/related'

export const useGetActivePatronRewardsProgramsByLicensedEstablishmentId = ({
  patronId,
  licensedEstablishmentId,
  includeGlobal = false,
}: {
  patronId: number
  licensedEstablishmentId: number
  includeGlobal?: boolean
}) => {
  return useApiQuery({
    method: 'get',
    path,
    queryKey: [
      'get-active-patron-rewards-programs-by-licensed-establishment-id',
      patronId,
      licensedEstablishmentId,
    ],
    queryArgs: { includeGlobal, patronId, licensedEstablishmentId },
  })
}
