import { Page } from 'components/Page'
import { RewardProgramDetailHeader } from './RewardProgramDetailHeader'
import { useParams } from 'react-router-dom'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Stack, Typography, styled } from '@mui/material'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { DatePicker } from '@mui/x-date-pickers'
import { Colors } from 'components/Theme'
import { RedemptionSection } from './RedemptionSection'
import { format } from 'date-fns'
import { useState } from 'react'

const StyledDatePicker = styled<typeof DatePicker<Date>>(DatePicker)({
  '& .MuiFormLabel-root': {
    fontSize: '1rem',
    fontWeight: '100',
    color: Colors.muiLabels.placeholder,
  },
  '& .Mui-focused': {
    color: !Colors.muiLabels.placeholder,
  },
})

export const RewardProgramRedemptionPage = () => {
  const { id: rewardProgramId } = useParams()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()

  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId,
  })

  if (rewardProgramQuery.isPending) {
    return <ActivityIndicator />
  }

  if (rewardProgramQuery.isError) {
    return <p>An error occurred.</p>
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/RewardsProgram/${rewardProgramId}/Redemption`}
        />
      }
    >
      <Stack spacing={'24px'}>
        <Typography variant="h2">Reward Redemption</Typography>
        <Stack direction={'row'} spacing={'16px'}>
          <StyledDatePicker
            disableFuture
            onChange={(date: Date | null) => {
              if (date) {
                setStartDate(format(date, 'MM/dd/yyyy'))
              }
            }}
            label="Start Date"
          />
          <StyledDatePicker
            onChange={(date: Date | null) => {
              if (date) {
                setEndDate(format(date, 'MM/dd/yyyy'))
              }
            }}
            label="End Date"
          />
        </Stack>
        <RedemptionSection
          startDate={startDate}
          endDate={endDate}
          rewardProgramName={rewardProgramQuery.data?.name}
          rewardProgramId={Number(rewardProgramId)}
        />
      </Stack>
    </Page>
  )
}
