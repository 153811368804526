import { type GridColDef } from '@mui/x-data-grid'
import { CircularProgress } from '@mui/material'
import { DataTable } from 'components/Shared/DataTable'
import { formatDateTime, pluralize } from 'utils/util'
import { useGetRewardProgramActivityLog } from 'hooks/api/useGetRewardProgramActivityLog'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { orderBy } from 'lodash'
import { isPresent } from '@jjvgaming/player-payback-library'
import { editProfileMetricSelectData } from 'pages/LiveAtLocation/EditGamingHabitsModal'

interface LogEntryType {
  id: string
  date: Date
  message: string
}

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Timestamp',
    minWidth: 80,
    flex: 1,
    valueGetter: (params) => params.row.date,
    valueFormatter: ({ value }) => formatDateTime(value),
  },
  {
    field: 'message',
    headerName: 'Activity',
    minWidth: 120,
    flex: 4,
    valueGetter: (params) => params.row.message,
    valueFormatter: ({ value }) => `${value}`,
  },
]
export const RewardProgramActivityLogTable = ({
  daysDisplayed,
}: {
  daysDisplayed: string
}) => {
  const { id: rewardsProgramId } = useParams()
  const query = useGetRewardProgramActivityLog({
    numOfDays: Number(daysDisplayed),
    rewardsProgramId: Number(rewardsProgramId),
  })

  const sortedLog: LogEntryType[] = useMemo(() => {
    const res: LogEntryType[] = []
    // Get Logs for Profile Metrics
    query.data?.licensedEstablishmentPatronProfileMetrics?.forEach((metric) => {
      if (isPresent(metric.averageBetInCents)) {
        let logMessage = `Perceived average bet updated to ${editProfileMetricSelectData.avgBet.find(
          (x) => x.value === metric.averageBetInCents
        )?.title}`
        if (isPresent(metric.licensedEstablishmentUser)) {
          logMessage =
            logMessage +
            ` by ${metric.licensedEstablishmentUser?.firstName} ${metric.licensedEstablishmentUser?.lastName}`
        }
        if (isPresent(metric.licensedEstablishment)) {
          logMessage = logMessage + ` at ${metric.licensedEstablishment?.name}`
        }
        res.push({
          id: metric.id + 'a',
          date: new Date(String(metric.createdOn)),
          message: logMessage,
        })
      }
      if (isPresent(metric.perceivedLengthOfStayInMinutes)) {
        let logMessage = `Perceived average visit length updated to ${editProfileMetricSelectData.visitLength.find(
          (x) => x.value === metric.perceivedLengthOfStayInMinutes
        )?.title}`

        if (isPresent(metric.licensedEstablishmentUser)) {
          logMessage =
            logMessage +
            ` by ${metric.licensedEstablishmentUser?.firstName} ${metric.licensedEstablishmentUser?.lastName}`
        }
        if (isPresent(metric.licensedEstablishment)) {
          logMessage = logMessage + ` at ${metric.licensedEstablishment?.name}`
        }
        res.push({
          id: metric.id + 'p',
          date: new Date(String(metric.createdOn)),
          message: logMessage,
        })
      }
    })

    // Get Logs for Manual Point Awards For CA/LE
    // CA/LE is differentiated by the reward program
    query.data?.pointsLedgerEntries?.forEach((pointsLedgerEntry) => {
      const logMessage: string[] = []
      logMessage.push(pluralize(pointsLedgerEntry.amount, 'point'))

      if (pointsLedgerEntry.transactionType === 'ManualPoint') {
        logMessage.push(` awarded`)

        if (isPresent(pointsLedgerEntry.licensedEstablishmentUser)) {
          logMessage.push(
            ` by ${pointsLedgerEntry.licensedEstablishmentUser.firstName} ${pointsLedgerEntry.licensedEstablishmentUser.lastName}`
          )
        }
        if (
          isPresent(pointsLedgerEntry.rewardsProgram?.licensedEstablishment)
        ) {
          logMessage.push(
            ` at ${pointsLedgerEntry.rewardsProgram?.licensedEstablishment.name}`
          )
        }
        res.push({
          id: pointsLedgerEntry.id + 'l',
          date: new Date(String(pointsLedgerEntry.createdOn)),
          message: logMessage.join(''),
        })
      }
    })

    // Get Logs for Manual and Patron Redeemed Rewards For CA/LE
    // CA/LE is differentiated by the reward program
    query.data?.rewardRedemptions?.forEach((rewardRedemption) => {
      let message = ''
      const actingUser =
        rewardRedemption.createdBy !== 'JJVentures.PlayerPAYBACK.WebApi'
          ? `by ${rewardRedemption.createdBy}`
          : null
      const locationName =
        rewardRedemption.rewardsProgram?.licensedEstablishment?.name
      const pointAmount = rewardRedemption.amount
      const rewardName =
        rewardRedemption.rewardsCatalogReward?.reward?.name ?? ''

      const option = isPresent(rewardRedemption.options?.value)
        ? `(${String(rewardRedemption.options?.type)}: ${String(
            rewardRedemption.options?.value
          )})`
        : ''

      switch (rewardRedemption.type) {
        case 'ManualReward':
          message = [
            rewardName,
            'sent',
            actingUser && actingUser,
            locationName && `at ${locationName}`,
            option,
          ]
            .filter(isPresent)
            .join(' ')
          break

        case 'PatronRedemption':
          message = [
            rewardName,
            'redeemed',
            locationName && `at ${locationName}`,
            `for ${pluralize(pointAmount, 'point')}`,
            option,
          ]
            .filter(isPresent)
            .join(' ')
          break
      }

      res.push({
        id: rewardRedemption.id + 'l',
        date: new Date(String(rewardRedemption.createdOn)),
        message,
      })
    })
    // Sort
    return orderBy(res, ['date'], ['desc'])
  }, [query.data])

  if (query.isPending) {
    return <CircularProgress />
  }

  if (query.isError) {
    return <p>An error occurred.</p>
  }

  if (query.data?.pointsLedgerEntries?.length === 0) {
    return <NoDataIndicator />
  }

  if (sortedLog) return <DataTable columns={columns} rows={sortedLog} />
}
