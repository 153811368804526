import { Page } from 'components/Page'
import { RewardProgramDetailHeader } from './RewardProgramDetailHeader'
import { useParams } from 'react-router-dom'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import {
  Box,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { useState } from 'react'
import { RewardProgramActivityLogTable } from './RewardProgramActivityLogTable'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { colorPrimitives } from 'components/Theme'

export const RewardProgramActivityLogPage = () => {
  const { id: rewardProgramId } = useParams()

  const [tableDaysDisplayed, setTableDaysDisplayed] = useState<string>('30')

  const handleChange = (event: SelectChangeEvent) => {
    setTableDaysDisplayed(event.target.value)
  }

  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId,
  })

  if (rewardProgramQuery.isPending) {
    return <ActivityIndicator />
  }

  if (rewardProgramQuery.isError) {
    return <p>An error occurred.</p>
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/RewardsProgram/${rewardProgramId}/ActivityLog`}
        />
      }
    >
      <Stack spacing={'24px'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: { xs: 'start', sm: 'space-between' },
            alignItems: { xs: 'start', sm: 'center' },
            rowGap: '8px',
          }}
        >
          <Box>
            <Typography variant="h3">ActivityLog</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              'svg path': {
                fill: colorPrimitives.redGaming,
              },
            }}
          >
            <Select
              value={tableDaysDisplayed}
              onChange={handleChange}
              displayEmpty
              sx={{
                padding: '0px 0px 0px 0px !important',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none !important',
                },
                '.Mui-focused': { border: 'none !important' },
                color: colorPrimitives.redGaming,
              }}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={30}>Last 30 days </MenuItem>
              <MenuItem value={90}>last 3 Months</MenuItem>
              <MenuItem value={365}>Year to Date</MenuItem>
            </Select>
          </Box>
        </Box>
        <Grid container columnGap={24}>
          <Grid item sm={12}>
            <RewardProgramActivityLogTable daysDisplayed={tableDaysDisplayed} />
          </Grid>
        </Grid>
      </Stack>
    </Page>
  )
}
