import { useApiQuery } from 'hooks/useApiQuery'
import { isNil } from 'lodash'

interface useGetRewardRedemptionByRewardProgramParams {
  rewardProgramId: number
  startTransactionDate?: string
  endTransactionDate?: string
}

export const useGetRewardRedemptionByRewardProgram = ({
  rewardProgramId,
  startTransactionDate,
  endTransactionDate,
}: useGetRewardRedemptionByRewardProgramParams) => {
  return useApiQuery({
    path: '/le-connect/rewards-programs/{rewardsProgramId}/redemptions',
    method: 'get',
    queryKey: [
      '/rewards-programs/{rewardsProgramId}/redemptions',
      rewardProgramId,
      startTransactionDate,
      endTransactionDate,
    ],
    queryArgs: {
      rewardsProgramId: rewardProgramId,
      startTransactionDate,
      endTransactionDate,
    },
    enabled: !isNil(startTransactionDate) && !isNil(endTransactionDate),
  })
}
