import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { b2cLoginRequest } from 'src/authConfig'
import { EventType, InteractionStatus } from '@azure/msal-browser'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'

export const LoginPage = () => {
  const { loginRequired, isPending } = useAccessTokenContext()
  const { instance, inProgress } = useMsal()

  const handleLogin = async () => {
    await instance.loginRedirect(b2cLoginRequest)
  }

  useEffect(() => {
    // Handle redirect upon B2C handshake
    // More examples here:
    //   https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/tree/main/3-Authorization-II/2-call-api-b2c
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log('B2C login callback failed', { event })
      }
    })

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [instance])

  if (!isPending && !loginRequired) {
    return <Navigate to="/Home" replace />
  }

  if (inProgress !== InteractionStatus.None || isPending) {
    return (
      <ActivityIndicator
        data-testid="LoginPageActivityIndicator"
        size="fullPage"
      />
    )
  }

  void handleLogin()
}
