import { Box, Stack } from '@mui/material'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { colorPrimitives } from 'components/Theme'
import cx from 'classnames'

export enum MessageBannerVariant {
  Default = 'default',
  Success = 'success',
  Error = 'error',
}

export enum MessageBannerIconPosition {
  Left = 'left',
  Right = 'right',
}

const TextColorClassMap = {
  [MessageBannerVariant.Success]: colorPrimitives.successGreen,
  [MessageBannerVariant.Error]: colorPrimitives.red5F,
  [MessageBannerVariant.Default]: colorPrimitives.black,
}

const BackgroundColorClassMap = {
  [MessageBannerVariant.Success]: colorPrimitives.successGreen,
  [MessageBannerVariant.Error]: colorPrimitives.redFD,
  [MessageBannerVariant.Default]: colorPrimitives.black,
}

interface MessageBannerProps {
  message: string
  variant?: MessageBannerVariant
  icon?: string | null
  iconPosition?: MessageBannerIconPosition
}

export const MessageBanner = ({
  message,
  variant = MessageBannerVariant.Default,
  icon,
  iconPosition = MessageBannerIconPosition.Left,
}: MessageBannerProps) => {
  const textStyles: string = TextColorClassMap[variant]

  return (
    <Stack
      direction="row"
      bgcolor={BackgroundColorClassMap[variant]}
      className="px-4 py-2 rounded-md"
    >
      {iconPosition === MessageBannerIconPosition.Left && icon && (
        <Box className="min-w-fit">
          <img src={icon} alt={`messageBanner-icon-${variant}`} width={22} />
        </Box>
      )}
      <ErrorIndicator
        style={cx('py-0 pt-0.5 leading-5', icon ? 'px-1.5' : '')}
        color={textStyles}
        message={message}
      />
      {iconPosition === MessageBannerIconPosition.Right && icon && (
        <img src={icon} alt={`messageBanner-icon-${variant}`} />
      )}
    </Stack>
  )
}
