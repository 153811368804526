import { Box } from '@mui/material'
import { Colors } from 'components/Theme'
import { type SweepstakeStatusType } from 'src/types/api'

const sweepstakeStatusString: Record<SweepstakeStatusType, string> = {
  Draft: 'Draft',
  Enrollable: 'Enrollable',
  Live: 'Live',
  InternalValidation: 'Winners Coming Soon',
  WinnerValidation: 'Winner Validation',
  EnrollmentClosed: 'Enrollment Closed',
  Closed: 'Closed',
}

const getStatusColor = (status: SweepstakeStatusType) => {
  return Colors.sweepstakesStatus.background[status]
}

const getStatusTextColor = (status: SweepstakeStatusType) => {
  return Colors.sweepstakesStatus.text[status]
}

export const SweepstakesStatusLabel = ({
  status,
}: {
  status: SweepstakeStatusType
}) => {
  return (
    <Box
      className="px-2 pt-1 pb-0.5 rounded-sm font-bold uppercase"
      sx={{
        backgroundColor: getStatusColor(status),
        color: getStatusTextColor(status),
      }}
    >
      {sweepstakeStatusString[status]}
    </Box>
  )
}
