import { chartColors } from 'components/Theme'
import type React from 'react'
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from 'recharts'

export interface DataItem {
  amount: number
  name: string
  tagNumber: number
  vgtNumber: string
  percentage: number
}

interface CreditsWageredByMachineChartProps {
  data: DataItem[]
  mobile: boolean
}

interface PieLabelRenderProps {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
  index: number
}

interface TooltipData {
  name: string
  tagNumber: number
  amount: number
}

interface CustomTooltipProps {
  active?: boolean
  payload?: Array<{ payload: TooltipData }>
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload?.length) {
    const item = payload[0].payload

    // Format the amount as a currency string
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, // Optional: Adjust the number of decimal places
    }).format(item.amount)

    return (
      <div
        className="custom-tooltip"
        style={{
          fontSize: '1.2em',
          backgroundColor: '#fff',
          padding: '5px',
          border: '1px solid #ccc',
        }}
      >
        <p>{item.name}</p>
        <p>Tag # {item.tagNumber}</p>
        <p>Amount: {formattedAmount}</p>
      </div>
    )
  }

  return <div className="h-1 w-1" />
}

export const CreditsWageredByMachineChart: React.FC<
  CreditsWageredByMachineChartProps
> = ({ data, mobile }) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: PieLabelRenderProps) => {
    const RADIAN = Math.PI / 180
    // Position labels closer to the edge of the pie
    const multiplier = mobile ? 0.6 : 0.75
    const radius = innerRadius + (outerRadius - innerRadius) * multiplier // Adjust the subtraction value as needed
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const item = data[index]

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize="1.2em"
        fontWeight="bold"
        style={{ pointerEvents: 'none' }}
      >
        {item.vgtNumber}
        <tspan x={x} dy={18}>{`${(percent * 100).toFixed(0)}%`}</tspan>
      </text>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={mobile ? 480 : 620}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={mobile ? 150 : 280}
          fill="#8884d8"
          dataKey="amount"
          startAngle={90}
          endAngle={-270}
        >
          {data.map((_entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={chartColors[index % chartColors.length]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          wrapperStyle={{
            fontSize: '18px',
            top: mobile ? '320px' : '600px',
          }}
          formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}
