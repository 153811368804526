import { Page } from 'components/Page'
import { formatDateTime } from 'utils/util'
import { useParams } from 'react-router-dom'
import { PatronProfileHeader } from './PatronProfileHeader'
import { LivePoints } from './LivePoints'
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetMe } from 'hooks/api/useGetMe'
import { isNil } from 'lodash'
import { useGetPatronById } from 'hooks/api/useGetPatronById'
import { colorPrimitives } from 'components/Theme'
import { useState } from 'react'
import {
  EditGamingHabitsModal,
  editProfileMetricSelectData,
} from './EditGamingHabitsModal'
import { useGetLePatronProfileMetric } from 'hooks/api/useGetLePatronProfileMetric'
import { usePatronCheckInsByLeId } from 'hooks/api/usePatronCheckInsByLeId'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ActivityLog } from './ActivityLog'
import { useGetActivePatronRewardsProgramsByLicensedEstablishmentId } from 'hooks/api/useGetActivePatronRewardsProgramsByLicensedEstablishmentId'

export const PatronDetailsPage = () => {
  const { id, licensedEstablishmentId: licensedEstablishmentIdString } =
    useParams()
  const patronId = Number(id)
  const licensedEstablishmentId = Number(licensedEstablishmentIdString)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const closeModal = () => setIsDialogOpen(false)
  const openModal = () => setIsDialogOpen(true)
  const meQuery = useGetMe()
  const leUser = meQuery.data?.user
  const patronCheckInQuery = usePatronCheckInsByLeId({
    licensedEstablishmentId: Number(licensedEstablishmentIdString),
  })
  const patronQuery = useGetPatronById(patronId)
  const patron = patronQuery.data

  const activePatronRewardsProgramsByLicensedEstablishmentIdQuery =
    useGetActivePatronRewardsProgramsByLicensedEstablishmentId({
      patronId,
      licensedEstablishmentId,
    })

  const activePatronRewardsProgramsByLicensedEstablishmentId =
    activePatronRewardsProgramsByLicensedEstablishmentIdQuery.data

  const profileMetricsQuery = useGetLePatronProfileMetric({
    patronId,
    licensedEstablishmentId,
  })

  if (
    meQuery.isPending ||
    patronCheckInQuery.isPending ||
    profileMetricsQuery.isPending
  ) {
    return (
      <Page>
        <ActivityIndicator />
      </Page>
    )
  }

  const patronList = patronCheckInQuery.data
  const currentPatronCheckIn = patronList?.find(
    (patronCheckIn) => patronCheckIn?.patronId === patronId
  )

  const renderAverageBet = () => {
    const averageBet = editProfileMetricSelectData.avgBet.find(
      (x) => x.value === profileMetricsQuery.data?.averageBetInCents
    )?.title
    if (averageBet === 'N/A' || averageBet === undefined) {
      return (
        <Typography
          variant="body-1"
          color="#CC2027"
          onClick={openModal}
          sx={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          + Add Average Bet
        </Typography>
      )
    }
    return <Typography variant="h1">{averageBet}</Typography>
  }

  const renderVisitLength = () => {
    const visitLength = editProfileMetricSelectData.visitLength.find(
      (x) =>
        (isNil(x.value) ? undefined : Number(x.value)) ===
        profileMetricsQuery.data?.perceivedLengthOfStayInMinutes
    )?.title

    if (visitLength === undefined || visitLength === 'N/A') {
      return (
        <Typography
          variant="body-1"
          color="#CC2027"
          onClick={openModal}
          sx={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          + Add Average Visit Length
        </Typography>
      )
    }
    return <Typography variant="h1">{visitLength}</Typography>
  }

  return (
    <Page
      grayBackground
      header={
        <PatronProfileHeader
          name={
            currentPatronCheckIn
              ? `${currentPatronCheckIn?.patron?.firstName} ${
                  String(currentPatronCheckIn?.patron?.lastName)[0]
                }.`
              : ''
          }
          checkedInTime={
            currentPatronCheckIn?.createdOn
              ? String(
                  formatDateTime(currentPatronCheckIn?.createdOn, 'hh:mma')
                )
              : ''
          }
          tier={currentPatronCheckIn?.patron?.currentTier?.tier?.type}
          patronCheckInQuery={patronCheckInQuery}
        />
      }
    >
      <EditGamingHabitsModal
        lastEdited={profileMetricsQuery.data?.createdOn}
        open={isDialogOpen}
        closeModal={closeModal}
        licensedEstablishmentId={licensedEstablishmentId}
        patronId={patronId}
        patronName={`${patron?.firstName} ${patron?.lastName?.substring(
          0,
          1
        )}.`}
        prevAvgBet={profileMetricsQuery.data?.averageBetInCents ?? null}
        prevVisitLength={
          profileMetricsQuery.data?.perceivedLengthOfStayInMinutes ?? null
        }
      />
      {isNil(currentPatronCheckIn) && (
        <Box textAlign="center">
          <Typography variant="h1" p={4}>
            The patron selected is no longer available.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: '250px',
          left: '0px',
          width: '100%',
        }}
        px={'64px'}
      >
        {!isNil(activePatronRewardsProgramsByLicensedEstablishmentId) &&
          !isNil(currentPatronCheckIn) && (
            <>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                pb={'8px'}
              >
                <Typography variant="subtitle-1" color={'white'}>
                  Perceived Gaming Habits
                </Typography>
                <Box onClick={openModal} className="cursor-pointer" pb={3}>
                  <Typography
                    variant="subtitle-2"
                    color={colorPrimitives.yellow}
                    className="text-left"
                  >
                    <Button variant="contained">Edit Gaming Habits</Button>
                  </Typography>
                </Box>
              </Stack>
              <Card
                elevation={1}
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: '32px',
                  borderRadius: '8px',
                }}
              >
                <Stack
                  direction={'row'}
                  justifyContent={'flex-start'}
                  alignItems={'stretch'}
                  spacing={'64px'}
                >
                  <Box className="whitespace-nowrap w-full">
                    <Typography variant="label-form" color={'text.secondary'}>
                      Average Bet
                    </Typography>
                    {renderAverageBet()}
                  </Box>
                  <Box>
                    <Divider orientation="vertical" sx={{ borderWidth: 1.5 }} />
                  </Box>
                  <Box className="whitespace-nowrap w-full">
                    <Typography variant="label-form" color={'text.secondary'}>
                      Average Visit Length
                    </Typography>
                    {renderVisitLength()}
                  </Box>
                </Stack>
              </Card>
            </>
          )}
      </Box>
      {isPresent(activePatronRewardsProgramsByLicensedEstablishmentId) &&
        isPresent(currentPatronCheckIn) && (
          <Box pt={'48px'} px={'24px'}>
            <LivePoints
              fullPointsLedgerSummary={
                activePatronRewardsProgramsByLicensedEstablishmentId
              }
              licensedEstablishmentUserId={meQuery.data?.user?.id}
              patronName={`${patronQuery.data?.firstName} ${patronQuery.data?.lastName}`}
            />
          </Box>
        )}
      {isPresent(currentPatronCheckIn) && (
        <ActivityLog leUserName={`${leUser?.firstName} ${leUser?.lastName}`} />
      )}
    </Page>
  )
}
