import { MsalProvider } from '@azure/msal-react'
import { Navigation } from './navigation/Navigation'
import { b2cMsalConfig } from './authConfig'
import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from 'components/ThemeProvider'
import { AccessTokenProvider } from './contexts/AccessTokenContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { appInsights, reactPlugin } from './utils/appInsights'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { Container } from '@mui/material'
import './sentry'
import { useSentryIdentification } from 'hooks/useSentryIdentification'

const msalInstance = new PublicClientApplication(b2cMsalConfig)

msalInstance.addEventCallback((event: any) => {
  if (
    [
      EventType.LOGIN_SUCCESS,
      EventType.ACQUIRE_TOKEN_SUCCESS,
      EventType.SSO_SILENT_SUCCESS,
    ].includes(event.eventType) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account)
  }
})

const queryClient = new QueryClient()
appInsights.loadAppInsights()

export const App = () => {
  useSentryIdentification()

  return (
    <ThemeProvider>
      <AppInsightsErrorBoundary
        onError={() => {
          return (
            <Container>
              <ErrorIndicator />
            </Container>
          )
        }}
        appInsights={reactPlugin}
      >
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AccessTokenProvider>
                <Navigation />
              </AccessTokenProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </MsalProvider>
      </AppInsightsErrorBoundary>
    </ThemeProvider>
  )
}
