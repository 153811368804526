import { Alert, Box, Snackbar, Stack } from '@mui/material'
import CreateAccountBackground from 'assets/JJCreateAccountBackground.png'
import { colorPrimitives } from 'components/Theme'
import { EmailSection } from './EmailSection'
import { PasswordSection } from './PasswordSection'
import { PhoneNumberSection } from './PhoneNumberSection'
import { NameSection } from './NameSection'
import { ConfirmationSection } from './ConfirmationSection'
import { type ReactNode, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSignUpPageSchema } from './FormUtils/useSignUpPageSchema'
import {
  type SignUpFormFields,
  defaultValues,
} from './FormUtils/SignUpFormFields'
import { LegalDisclaimerSection } from './LegalDisclaimerSection'
import { useSnackbar } from 'stores/useSnackbar'

export const CreateAccountPage = () => {
  const [section, setSection] = useState('legalDisclaimerSection')

  const changeSection = (section: string) => {
    setSection(section)
  }

  const Sections: Record<string, ReactNode> = {
    legalDisclaimerSection: (
      <LegalDisclaimerSection changeSection={changeSection} />
    ),
    emailSection: <EmailSection changeSection={changeSection} />,
    passwordSection: <PasswordSection changeSection={changeSection} />,
    phoneNumberSection: <PhoneNumberSection changeSection={changeSection} />,
    nameSection: <NameSection changeSection={changeSection} />,
    confirmationSection: <ConfirmationSection />,
  }

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const snackbarMessage = useSnackbar((state) => state.message)
  const snackbarSeverity = useSnackbar((state) => state.severity)
  const snackbarVisible = useSnackbar((state) => state.visible)

  const { schema } = useSignUpPageSchema()
  const formMethods = useForm<SignUpFormFields>({
    defaultValues,
    resolver: yupResolver(schema, {}),
    mode: 'all',
    criteriaMode: 'all',
  })

  return (
    <Stack
      sx={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${CreateAccountBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        bgcolor={colorPrimitives.white}
        className="w-1/3 h-auto rounded-lg p-12"
      >
        <FormProvider {...formMethods}>{Sections[section]}</FormProvider>
        <Snackbar
          open={snackbarVisible}
          autoHideDuration={6000}
          onClose={() => {
            setSnackbarMessage(null)
          }}
          message={snackbarMessage}
        >
          <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>
      </Box>
    </Stack>
  )
}
