import { Box, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { type LicensedEstablishment } from 'src/types/api'
import { SelectLicensedEstablishment } from '../SelectLicensedEstablishment'
import { format } from 'date-fns'
import {
  CreditsWageredByMachineChart,
  type DataItem,
} from './CreditsWageredByMachineChart'
import { useLayout } from 'hooks/useLayout'
import { useGetCreditsWageredByMachine } from 'hooks/api/Reports/useGetCreditsWageredByMachine'
import { PageHeader } from 'components/Shared/PageHeader'
import { getReportStartAndEndDates } from 'utils/util'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'

export const CreditsWageredByMachinePage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const isMobile = useLayout()

  const { startDate, endDate } = getReportStartAndEndDates(8, 1)

  const reportQuery = useGetCreditsWageredByMachine({
    licensedEstablishmentId: licensedEstablishment?.id,
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Credits Wagered By Machine"
            backText="View Reports"
            backPath="/Reports"
            subtitle={`Last 8 Weeks: ${formattedStartDate} - ${formattedEndDate}`}
            isSecondary={true}
          />
        </Box>
      }
    >
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          <SelectLicensedEstablishment
            onSelectLicensedEstablishment={setLicensedEstablishment}
          />

          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.metrics.length === 0 ? (
              <Typography variant="h4">
                No data available for the selected location
              </Typography>
            ) : (
              <CreditsWageredByMachineChart
                data={reportQuery.data.metrics.map(
                  (item) =>
                    ({
                      amount: Number(item.amount),
                      name: String(item.name),
                      tagNumber: Number(item.tagNumber),
                      vgtNumber: String(item.vgtNumber),
                      percentage: Number(item.percentage),
                    }) satisfies DataItem
                )}
                mobile={isMobile.isMobile}
              />
            ))}
        </Stack>
      )}
    </Page>
  )
}
