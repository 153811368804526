import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface EnrollmentDetailsType {
  signageOne: string
  signageTwo: string
  notes: string
  email: string
  usePrimaryContact: boolean
}

export interface UseSweepstakeParticipatingLocationsAndEnrollmentAgreementState {
  participatingLocations: number[] | undefined
  enrollmentDetails?: EnrollmentDetailsType
  setEnrollmentDetails: (x: EnrollmentDetailsType | undefined) => void
  setParticipatingLocations: (x: number[] | undefined) => void
}

export const useSweepstakeParticipatingLocationsAndEnrollmentAgreement = create(
  persist<UseSweepstakeParticipatingLocationsAndEnrollmentAgreementState>(
    (set) => ({
      participatingLocations: undefined,
      enrollmentDetails: undefined,
      setEnrollmentDetails: (enrollmentDetails) => {
        set({ enrollmentDetails })
      },
      setParticipatingLocations: (participatingLocations) => {
        set({ participatingLocations })
      },
    }),
    {
      name: 'location-selections',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
