import { useApiQuery } from 'hooks/useApiQuery'

const path = '/le-connect/patrons/{id}'

export const useGetPatronById = (patronId: number) =>
  useApiQuery({
    path,
    method: 'get',
    queryKey: [path, patronId],
    queryArgs: {
      id: patronId,
    },
  })
