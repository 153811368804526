import { removePhoneMask } from '@jjvgaming/player-payback-library'
import { useApiMutation } from 'hooks/useApiMutation'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import type { ApiError } from 'openapi-typescript-fetch'
import { useSignUpPageSchema } from 'pages/Login/CreateAccount/FormUtils/useSignUpPageSchema'
import type { NewLicensedEstablishmentUser } from 'src/types/api'

interface usePostLicensedEstablishmentUserProps {
  onSuccess?: (data: NewLicensedEstablishmentUser) => void
  onError?: (error: ApiError) => void
}

export const usePostLicensedEstablishmentUser = ({
  onSuccess,
  onError,
}: usePostLicensedEstablishmentUserProps) => {
  const post = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/autoRegister',
    method: 'post',
  })
  const { schema } = useSignUpPageSchema()

  return useApiMutation<typeof post, typeof schema>({
    mutationFn: async (formData) => {
      return await post({
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        phoneNumber: removePhoneMask(formData.phoneNumber),
      })
    },
    onSuccess: (result) => {
      onSuccess?.(result.data)
    },
    onError,
  })
}
