import { useMediaQuery, useTheme } from '@mui/material'

export const useLayout = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isHeightUnder600 = useMediaQuery('(max-height: 600px)')
  const isHeightUnder500 = useMediaQuery('(max-height: 500px)')
  const isHeightUnder400 = useMediaQuery('(max-height: 400px)')

  return { isMobile, isHeightUnder600, isHeightUnder500, isHeightUnder400 }
}
