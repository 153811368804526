import { Box, Grid, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishment } from 'src/types/api'
import { formatPhoneNumber } from 'utils/phoneUtils'

interface ContactDetailsProps {
  licensedEstablishment: LicensedEstablishment
}

export const ContactDetailsDesktop = ({
  licensedEstablishment,
}: ContactDetailsProps) => {
  const theme = useTheme()
  const primaryContact = licensedEstablishment.contacts?.[0]

  return (
    <Box>
      <Typography variant="h3" pb={2}>
        Contact
      </Typography>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={13}>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Primary Contact
            </Typography>
            <Typography variant="body1" pb={2}>
              {`${primaryContact?.firstName ?? ''} ${
                primaryContact?.lastName ?? ''
              }`}
            </Typography>
            <Box>
              <Typography variant="body2" pb={1} color="text.secondary">
                Phone Number
              </Typography>
              <Typography variant="body1" pb={2}>
                {formatPhoneNumber(primaryContact?.phoneNumber)}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4}>
            {primaryContact?.email && (
              <Box>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Email
                </Typography>
                <Typography variant="body1" pb={2}>
                  {primaryContact.email ?? ''}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
