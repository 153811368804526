import { useApiQuery } from 'hooks/useApiQuery'

export const useGetLePatronProfileMetric = ({
  licensedEstablishmentId,
  patronId,
}: {
  licensedEstablishmentId?: number
  patronId?: number
}) => {
  return useApiQuery({
    path: '/le-connect/patron-profile',
    method: 'get',
    queryKey: ['/le-connect/patron-profile', licensedEstablishmentId, patronId],
    queryArgs: { licensedEstablishmentId, patronId },
  })
}
