import { useApiQuery } from 'hooks/useApiQuery'

export const useGetPatronActivityLog = ({
  licensedEstablishmentId,
  patronId,
}: {
  licensedEstablishmentId: number
  patronId: number
}) => {
  return useApiQuery({
    method: 'get',
    path: '/le-connect/activity-logs/attendants/licensed-establishments/{licensedEstablishmentId}/patrons/{patronId}',
    queryKey: ['le-connect/activitylog', patronId, licensedEstablishmentId],
    queryArgs: { licensedEstablishmentId, numOfDays: 30, patronId },
  })
}
