import {
  Box,
  IconButton,
  type SxProps,
  TextField,
  type TextFieldProps,
} from '@mui/material'
import { colorPrimitives } from './Theme'
import { forwardRef } from 'react'

const ICON_SIZE = 36

interface NumberFieldProps extends TextFieldProps<'standard'> {
  onIncrement: () => void
  onDecrement: () => void
}

const ButtonStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 9999,
  color: 'white',
  backgroundColor: colorPrimitives.redGaming,
  height: ICON_SIZE,
  width: ICON_SIZE,
}

export const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>(
  (props, ref) => {
    return (
      <Box className="flex flex-row gap-4 items-center">
        <IconButton sx={ButtonStyle} onClick={() => props.onDecrement()}>
          &ndash;
        </IconButton>
        <TextField {...props} ref={ref} type="number" />
        <IconButton sx={ButtonStyle} onClick={() => props.onIncrement()}>
          +
        </IconButton>
      </Box>
    )
  }
)

NumberField.displayName = 'NumberField'
