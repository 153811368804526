import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { type LicensedEstablishment } from 'src/types/api'
import { SelectLicensedEstablishment } from '../SelectLicensedEstablishment'
import {
  startOfToday,
  format,
  subYears,
  startOfYear,
  endOfMonth,
  subMonths,
  endOfDay,
  startOfDay,
} from 'date-fns'
import { UseGetTrailingNtiTrends } from 'hooks/api/Reports/useGetTrailingNtiTrends'
import PlaceIcon from '@mui/icons-material/Place'
import PublicIcon from '@mui/icons-material/Public'
import { TrailingNtiTrendsChart } from './TrailingNtiTrendsChart'
import { PageHeader } from 'components/Shared/PageHeader'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetMe } from 'hooks/api/useGetMe'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'

export const TrailingNtiTrendsPage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const today = startOfToday()
  const startDate = startOfDay(startOfYear(subYears(today, 3)))
  const endDate = endOfDay(endOfMonth(subMonths(today, 1)))

  const reportQuery = UseGetTrailingNtiTrends({
    licensedEstablishmentId: licensedEstablishment?.id,
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  const [displayType, setDisplayType] = useState<'Location' | 'Total'>(
    'Location'
  )

  const buttonStyle = (type: 'Location' | 'Total') => ({
    maxWidth: '40px',
    maxHeight: '40px',
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '1.8rem',
    backgroundColor: displayType === type ? '#CC2027' : '#e0e0e0',
    color: displayType === type ? 'white' : 'black',
  })

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="NTI Trends"
            backText="View Reports"
            backPath="/Reports"
            subtitle={`Last 3 Years: ${formattedStartDate} - ${formattedEndDate}`}
            isSecondary={true}
          />
        </Box>
      }
    >
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sx={{ marginLeft: '-10px' }}>
              <SelectLicensedEstablishment
                onSelectLicensedEstablishment={setLicensedEstablishment}
              />
            </Grid>
            <Grid item sx={{ mt: 5 }}>
              <Button
                variant="contained"
                onClick={() => setDisplayType('Location')}
                style={buttonStyle('Location')}
              >
                <PlaceIcon />
              </Button>
            </Grid>
            <Grid item sx={{ mt: 5 }}>
              <Button
                variant="contained"
                onClick={() => setDisplayType('Total')}
                style={buttonStyle('Total')}
              >
                <PublicIcon />
              </Button>
            </Grid>
          </Grid>

          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.metrics.length === 0 ? (
              <Typography variant="h4">
                No data available for the selected location
              </Typography>
            ) : (
              <TrailingNtiTrendsChart
                data={reportQuery.data.metrics}
                displayType={displayType}
              />
            ))}
        </Stack>
      )}
    </Page>
  )
}
