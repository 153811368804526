import { Box } from '@mui/material'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetMe } from 'hooks/api/useGetMe'

export const LicensedEstablishmentUsersPage = () => {
  const meQuery = useGetMe()
  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="J&J Connect Users"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!meQuery.data?.user?.leUsersPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view J&J Connect Users. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <></>
      )}
    </Page>
  )
}
