import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useQueryClient } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton/ActivityButton'
import { usePostLePatronProfileMetrics } from 'hooks/api/usePostLePatronProfileMetrics'
import { useState } from 'react'
import { useSnackbar } from 'stores/useSnackbar'
import { formatDateTime } from 'utils/util'

interface SelectItem {
  value: number | null
  title: string
}

export const editProfileMetricSelectData: {
  avgBet: SelectItem[]
  visitLength: SelectItem[]
} = {
  avgBet: [
    { title: 'N/A', value: null },
    { title: '$0', value: 0 },
    { title: '$1', value: 100 },
    { title: '$2', value: 200 },
    { title: '$3', value: 300 },
    { title: '$4', value: 400 },
  ],
  visitLength: [
    { title: 'N/A', value: null },
    { title: '< 5 min', value: 4 },
    { title: '5 min', value: 5 },
    { title: '6 - 10 min', value: 10 },
    { title: '11 - 15 min', value: 15 },
    { title: '16 - 20 min', value: 20 },
    { title: '21 - 25 min', value: 25 },
    { title: '26 - 29 min', value: 29 },
    { title: '30 - 59 min', value: 59 },
    { title: '1 - 2 hr', value: 120 },
    { title: '3 - 4 hr', value: 240 },
    { title: '5 hr +', value: 300 },
  ],
}

export const EditGamingHabitsModal = ({
  open,
  closeModal,
  licensedEstablishmentId,
  patronId,
  lastEdited,
  patronName,
  prevAvgBet,
  prevVisitLength,
}: {
  open: boolean
  closeModal: () => void
  licensedEstablishmentId: number
  patronId: number
  lastEdited?: string | null
  patronName: string
  prevAvgBet: number | null
  prevVisitLength: number | null
}) => {
  const qc = useQueryClient()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [avgBet, setAvgBet] = useState<number | null>(prevAvgBet)
  const [avgLength, setAvgLength] = useState<number | null>(prevVisitLength)
  const clearDataAndCancel = () => {
    setAvgBet(prevAvgBet)
    setAvgLength(prevVisitLength)
    closeModal()
  }

  const usePostLePatronProfileMetricsMutation = usePostLePatronProfileMetrics({
    onError: () => {
      setSnackbarMessage('There was a error saving the Gaming Habits.', 'error')
      closeModal()
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ['/le-connect/patron-profile'],
      }).catch(() => null)
      qc.invalidateQueries({
        queryKey: ['activitylog'],
      }).catch(() => null)
      setSnackbarMessage('Successfully saved Gaming Habits.', 'success')
      closeModal()
    },
  })

  return (
    <Dialog open={open} onClose={clearDataAndCancel}>
      <DialogTitle bgcolor={grey[50]} p={'24px'}>
        <Box>
          <Typography variant="h3" pb={'4px'}>
            Edit {patronName} Perceived Gaming Habits
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            Last edited on {formatDateTime(lastEdited, 'MM-dd-yyyy')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container p={'16px'} gap={'30px'} pb={'50px'}>
          <Grid item sm={7}>
            <Stack direction={'column'} justifyContent={'start'}>
              <Typography variant="subtitle-2">
                Perceived Average Bet
              </Typography>
              <Typography variant="body-3" color={'text.secondary'}>
                On average how much does this patron bet when gaming per session
                at your location?
              </Typography>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Select
              fullWidth
              displayEmpty
              value={avgBet ?? ''}
              onChange={(event) => {
                setAvgBet(
                  event.target.value === '' ? null : Number(event.target.value)
                )
              }}
            >
              {editProfileMetricSelectData.avgBet.map((x) => {
                return x.value === null ? (
                  <MenuItem key={x.title} value={''}>
                    {x.title}
                  </MenuItem>
                ) : (
                  <MenuItem key={x.title} value={x.value}>
                    {x.title}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
          <Grid item sm={7}>
            <Stack direction={'column'} justifyContent={'start'}>
              <Typography variant="subtitle-2">
                Percevied Average Visit Length
              </Typography>
              <Typography variant="body-3" color={'text.secondary'}>
                On average how long does this patron game at your location?
              </Typography>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Select
              fullWidth
              displayEmpty
              value={avgLength ?? ''}
              onChange={(event) => {
                setAvgLength(
                  event.target.value === '' ? null : Number(event.target.value)
                )
              }}
            >
              {editProfileMetricSelectData.visitLength.map((x) => {
                return x.value === null ? (
                  <MenuItem key={x.title} value={''}>
                    {x.title}
                  </MenuItem>
                ) : (
                  <MenuItem key={x.title} value={x.value}>
                    {x.title}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="border-t">
        <Button onClick={clearDataAndCancel}>Cancel</Button>
        <ActivityButton
          active={usePostLePatronProfileMetricsMutation.isPending}
          variant="contained"
          onClick={() => {
            usePostLePatronProfileMetricsMutation.mutate({
              licensedEstablishmentId,
              patronId,
              averageBetInCents: avgBet ?? undefined,
              perceivedLengthOfStayInMinutes: avgLength ?? undefined,
            })
          }}
        >
          Save
        </ActivityButton>
      </DialogActions>
    </Dialog>
  )
}
