import * as yup from 'yup'

export const textValidation = (field: string) =>
  yup
    .string()
    .required(`Must enter your ${field.toLowerCase()}`)
    .matches(/^[a-z ,.'-]+$/i, {
      message: `${field} should contain only letters.`,
    })

export const passwordValidation = yup
  .string()
  .required('A password is required.')
  .min(8, 'Must be 8 to 64 characters.')
  .max(64, 'Must be 8 to 64 characters.')
  .test({
    name: 'passwordGuidelines',
    exclusive: true,
    message:
      'Must include a combination of at least three of the following: lowercase letters, uppercase letters, numbers, or symbols.',
    test: (value) =>
      typeof value === 'string' &&
      [
        /[a-z]/.test(value),
        /[A-Z]/.test(value),
        /\d/.test(value),
        /[\W_]/.test(value),
      ].filter((t) => t).length > 2,
  })
