import { Card, Stack, Typography } from '@mui/material'
import { PatronTierIcon, type TierOption } from './PatronTierIcon'
import CrownBackground from 'assets/CrownBackground.png'
import { useNavigate } from 'react-router-dom'

export const PatronCard = ({
  name,
  id,
  checkinTime,
  tier,
  className,
  licensedEstablishmentId,
}: {
  id: number | undefined
  name: string
  checkinTime: string
  tier: string
  className?: string
  licensedEstablishmentId: number
}) => {
  const navigate = useNavigate()

  return (
    <Card
      className={className}
      onClick={() => {
        navigate(
          `/LiveAtLocation/PatronDetails/${id}/LicensedEstablishment/${licensedEstablishmentId}`
        )
      }}
    >
      <Stack
        className="px-4 py-8 bg-gaming-red justify-center items-start cursor-pointer"
        sx={{
          backgroundImage: 'url(' + CrownBackground + ')',
        }}
      >
        <Typography variant="h3" className="text-white">
          {name}
        </Typography>
        <Stack
          direction={'row'}
          className="justify-center items-center"
          gap={1}
        >
          <PatronTierIcon
            tier={tier as TierOption}
            height="10px"
            width="10px"
          />
          <Typography variant="body-1" className="uppercase text-white pl-1">
            {tier}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} className="justify-between p-4">
        <Typography variant="body-3">Checked In</Typography>
        <Typography variant="body-3">{checkinTime}</Typography>
      </Stack>
    </Card>
  )
}
