import { Box, Button, Typography } from '@mui/material'
import { TextField } from 'components/TextField/TextField'
import { SectionsHeader } from './SectionsHeader'
import { Controller, useFormContext } from 'react-hook-form'
import type { SignUpFormFields } from './FormUtils/SignUpFormFields'
import { isPresent } from '@jjvgaming/player-payback-library'
import checkIcon from 'assets/check.svg'
import notValidIcon from 'assets/error.svg'
import errorIcon from 'assets/errorIcon.svg'
import { capitalize } from 'lodash'
import {
  FormFieldMessage,
  FormFieldMessageVariant,
} from 'components/FormFieldMessage/FormFieldMessage'

export const PasswordSection = ({
  changeSection,
}: {
  changeSection: (section: string) => void
}) => {
  const formMethods = useFormContext<SignUpFormFields>()
  const {
    formState: { errors },
    trigger,
    watch,
  } = formMethods

  const validateAndNavigateToNextSection = async () => {
    const validationPassed = await trigger(['password'])
    if (validationPassed) {
      changeSection('phoneNumberSection')
    }
  }
  const password = watch('password')

  const getStyleForPasswordLengthValidation = (validationTypes: string[]) => {
    if (validationTypes.some((type) => errors.password?.types?.[type])) {
      return { variant: FormFieldMessageVariant.Error, icon: notValidIcon }
    } else if (password) {
      return { variant: FormFieldMessageVariant.Success, icon: checkIcon }
    }

    return {
      variant: FormFieldMessageVariant.Default,
      icon: '',
    }
  }

  return (
    <Box className="flex justify-center flex-col w-full">
      <SectionsHeader
        title="Create Password"
        changeSection={changeSection}
        previousSection="emailSection"
      />
      <Controller
        name="password"
        render={({ field: { onBlur, onChange, value } }) => (
          <TextField
            name="password"
            placeholder="Create Password"
            type="password"
            error={isPresent(errors.password)}
            value={value}
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e)
            }}
          />
        )}
      />
      <Box className="pt-2">
        {isPresent(errors.password?.types?.required) &&
          isPresent(errors.password?.message) && (
            <FormFieldMessage
              message={capitalize(errors.password?.message)}
              variant={FormFieldMessageVariant.Error}
              icon={errorIcon}
            />
          )}
        <FormFieldMessage
          message={capitalize('Must be 8 to 64 characters')}
          variant={getStyleForPasswordLengthValidation(['min', 'max']).variant}
          icon={getStyleForPasswordLengthValidation(['min', 'max']).icon}
        />
        <FormFieldMessage
          message={
            'Must include a combination of at least three of the following:\nlowercase letters, uppercase letters, numbers, or symbols.'
          }
          variant={
            getStyleForPasswordLengthValidation(['passwordGuidelines']).variant
          }
          icon={
            getStyleForPasswordLengthValidation(['passwordGuidelines']).icon
          }
        />
      </Box>
      <Box className="py-6">
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          onClick={async () => {
            await validateAndNavigateToNextSection()
          }}
        >
          <Typography variant="label-CTA">Next</Typography>
        </Button>
      </Box>
    </Box>
  )
}
