import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'

export const useSentryIdentification = () => {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()

  useEffect(() => {
    if (account) {
      Sentry.setUser({
        id: account.localAccountId,
        email: account.username,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [account])
}
