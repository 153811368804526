import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { textValidation, passwordValidation } from './validations'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useSnackbar } from 'stores/useSnackbar'

export const useSignUpPageSchema = () => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const getPatronEmailExists = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/email',
    method: 'get',
  })
  const queryClient = useQueryClient()

  const checkEmailAuthorized = useCallback(
    async (email: string) => {
      if (!email) {
        return true
      }
      try {
        const patronEmailExistsQuery = await queryClient.fetchQuery({
          queryKey: ['/le-connect/licensed-establishments/users/email', email],
          queryFn: async () => {
            return await getPatronEmailExists({
              email,
            })
          },
          staleTime: 60 * 1000,
        })
        return patronEmailExistsQuery
      } catch (error: any) {
        if (error.status !== 401) {
          setMessage(error.data, 'error')
        }
        return error
      }
    },
    [getPatronEmailExists, queryClient]
  )

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        email: yup
          .string()
          .required('Email is required.')
          .email('Please enter a valid email.')
          .test(
            'uniqueEmail',
            'Account not authorized, contact J&J Administrators for more information.',
            async (value) => {
              const { data } = await checkEmailAuthorized(value)
              if (data?.error) {
                return false
              }
              return true
            }
          )
          .test(
            'accountExists',
            'An account with this email already exists.',
            async (value) => {
              const { data } = await checkEmailAuthorized(value)
              if (data?.error) {
                return true
              }
              return data
            }
          ),
        password: passwordValidation,
        phoneNumber: yup
          .string()
          .required('Phone Number is required.')
          .ensure()
          .matches(
            /^\(?\d{3}\)? \d{3} \d{4}$|^$/,
            'Phone number must be exactly 10 digits.'
          ),
        firstName: textValidation('First name'),
        lastName: textValidation('Last name'),
      })
      .required()
  }, [checkEmailAuthorized, passwordValidation, yup])

  return { schema }
}
