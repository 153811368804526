import { grey } from '@mui/material/colors'
import { type GridValidRowModel } from '@mui/x-data-grid'
import { isNil } from 'lodash'

export interface TreeDataGridRow extends GridValidRowModel {
  rowId: string
  parentRowId?: string
  children: TreeDataGridRow[]
  expanded?: boolean
  parentIndexPath: number[]
}

export const buildTreeDataGridRows = (
  list: TreeDataGridRow[]
): TreeDataGridRow[] => {
  const roots: TreeDataGridRow[] = []
  const rows: TreeDataGridRow[] = []
  let i: number, row: TreeDataGridRow

  const map: Record<string, number> = {}

  for (i = 0; i < list.length; i++) {
    rows[i] = { ...list[i], children: [] }
    map[list[i].rowId] = i
  }

  for (i = 0; i < rows.length; i++) {
    row = rows[i]
    if (row.parentRowId) {
      if (!isNil(map[row.parentRowId])) {
        rows[map[row.parentRowId]].children.push(row)
      }
    } else {
      roots.push(row)
    }
  }

  return roots
}

const mapFields = (
  record: Record<string, any>,
  fieldsMap: Record<string, string>
) =>
  Object.keys(fieldsMap).reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current]: record[fieldsMap[current]],
    }),
    {}
  )

export const prepareTreeDataGridRows = ({
  records,
  rowIdPrefix,
  parentAggregationMaps = [],
  fieldsMap = {},
  newFields = {},
}: {
  records: Array<{ [key: string]: any; id?: number }>
  rowIdPrefix: string
  parentAggregationMaps?: Array<{
    parentFieldName: string
    parentRowIdPrefix: string
  }>
  fieldsMap?: Record<string, string>
  newFields?: Record<string, any>
}): TreeDataGridRow[] =>
  records.map((record) => {
    let closestParentMap = null
    for (const fieldNameMap of parentAggregationMaps) {
      if (record[fieldNameMap.parentFieldName]) {
        closestParentMap = fieldNameMap
      }
    }

    return {
      ...record,
      ...mapFields(record, fieldsMap),
      ...newFields,
      rowId: `${rowIdPrefix}-${record.id}`,
      parentIndexPath: [],
      parentRowId: closestParentMap
        ? `${closestParentMap.parentRowIdPrefix}-${
            record[closestParentMap.parentFieldName] as string
          }`
        : undefined,
      children: [],
    } satisfies TreeDataGridRow
  })

export const backgroundColorDepth = ['#ffffff', grey[50], grey[100], grey[200]]
