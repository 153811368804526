import { colorPrimitives } from 'components/Theme'
import type React from 'react'
import { useMemo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  type TooltipProps,
} from 'recharts'

export interface DataItem {
  hour: number
  amount: number
  percentage: number
}

interface CreditsWageredByHourChartProps {
  data: DataItem[]
}

const formatXAxis = (tickItem: number) => {
  // Convert 24-hour time format to 12-hour format with AM/PM
  return `${tickItem % 12 === 0 ? 12 : tickItem % 12} ${
    tickItem < 12 ? 'AM' : 'PM'
  }`
}

const formatCurrency = (value: number) => {
  return `$${value.toLocaleString()}`
}

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload?.length && payload[0].value) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '5px',
          border: '1px solid #ccc',
        }}
      >
        <p>{`${payload[0].payload.hour}`}</p>
        <p>
          {`${formatCurrency(payload[0].value)}`}{' '}
          {`(${payload[0].payload.percentage}%)`}
        </p>
      </div>
    )
  }

  return null
}

export const CreditsWageredByHourChart: React.FC<
  CreditsWageredByHourChartProps
> = ({ data }) => {
  const preparedData = useMemo(() => {
    return data.map((item) => ({
      hour: formatXAxis(item.hour),
      amount: item.amount,
      percentage: item.percentage,
    }))
  }, [data])

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={preparedData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="hour"
          height={40}
          label={{
            value: 'Hour',
            position: 'insideBottom',
            offset: -2,
            fill: colorPrimitives.black,
          }}
          tick={{ fill: colorPrimitives.black }}
        />
        <YAxis
          tickFormatter={formatCurrency}
          label={{
            value: 'Credits Wagered',
            angle: -90,
            textAnchor: 'middle',
            dx: -35,
            fill: colorPrimitives.black,
          }}
          tick={{ fill: colorPrimitives.black }}
          tickCount={10}
          width={80}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="linear"
          dataKey="amount"
          name="Credits Wagered"
          stroke={colorPrimitives.redGaming}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
