import { Box, Grid, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishment } from 'src/types/api'

interface ContactDetailsProps {
  licensedEstablishment: LicensedEstablishment
}

export const ContactDetailsMobile = ({
  licensedEstablishment,
}: ContactDetailsProps) => {
  const theme = useTheme()

  return (
    <Box>
      <Typography variant="h3" pb={2}>
        Contact
      </Typography>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={24}>
          <Grid item sm={8}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Primary Contact
            </Typography>
            <Typography variant="body1" pb={2}>
              {`${licensedEstablishment.contacts?.[0]?.firstName ?? ''} ${
                licensedEstablishment.contacts?.[0]?.lastName ?? ''
              }`}
            </Typography>
            {licensedEstablishment.contacts?.[0]?.email && (
              <Box>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Email
                </Typography>
                <Typography variant="body1" pb={2}>
                  {licensedEstablishment.contacts?.[0].email ?? ''}
                </Typography>
              </Box>
            )}
            {licensedEstablishment.contacts?.[0]?.phoneNumber && (
              <Box>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Phone Number
                </Typography>
                <Typography variant="body1" pb={2}>
                  {licensedEstablishment.contacts?.[0].phoneNumber ?? ''}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
