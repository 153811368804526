import { type components } from 'src/api/playerPayback/api'
import { PageHeader } from 'components/Shared/PageHeader'
import { Box } from '@mui/material'
import { isNil } from 'lodash'
import { getLastUpdated } from 'utils/util'
import { RewardProgramDetailTabs } from './RewardProgramDetailTabs'

interface RewardProgramDetailHeaderProps {
  rewardProgram: components['schemas']['FullRewardsProgramDTO']
  currentTab: string
}

export const RewardProgramDetailHeader = ({
  rewardProgram,
  currentTab,
}: RewardProgramDetailHeaderProps) => {
  return (
    <Box>
      <PageHeader
        title={isNil(rewardProgram.name) ? 'Unknown' : `${rewardProgram.name}`}
        subtitle={getLastUpdated(rewardProgram.modifiedOn)}
        backText="Reward Programs"
        backPath="/Rewards"
        isSecondary={true}
        tabs={
          <RewardProgramDetailTabs
            rewardProgramId={Number(rewardProgram.id)}
            currentTab={currentTab}
          />
        }
      />
    </Box>
  )
}
