import {
  Box,
  FormControl,
  Select,
  MenuItem,
  type SelectChangeEvent,
} from '@mui/material'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { RequiredSelectReport } from 'components/Shared/RequiredSelectReport'
import { useGetMe } from 'hooks/api/useGetMe'
import { useLayout } from 'hooks/useLayout'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const ReportsPage = () => {
  const meQuery = useGetMe()
  const isMobile = useLayout()
  const navigate = useNavigate()

  const handleChange = (event: SelectChangeEvent) => {
    const report = event.target.value

    navigate(report)
  }

  interface FinancialReport {
    key: string
    text: string
    value: string
  }

  const financialReports = useMemo(() => {
    let reports: FinancialReport[] = []

    if (meQuery.data?.user?.reportsFinancialPermission) {
      reports = [
        {
          key: 'FlexPay',
          text: 'FlexPay / Income Statements',
          value: '/Reports/FlexPay',
        },
        {
          key: 'WeeklySnapshot',
          text: 'Weekly Snapshot',
          value: '/Reports/WeeklySnapshotLocationSelect',
        },
        {
          key: 'CreditsWageredByHour',
          text: 'Credits Wagered by Hour',
          value: '/Reports/CreditsWageredByHour',
        },
        {
          key: 'CreditsWageredByMachine',
          text: 'Credits Wagered by Machine',
          value: '/Reports/CreditsWageredByMachine',
        },
        {
          key: 'CreditsWageredHourlyTrends',
          text: 'Credits Wagered: Hourly Trends',
          value: '/Reports/CreditsWageredHourlyTrends',
        },
        {
          key: 'VgtSnapshot',
          text: 'VGT Snapshot',
          value: '/Reports/VgtSnapshot',
        },
        {
          key: 'VgtTrends',
          text: 'VGT Trends',
          value: '/Reports/VgtTrends',
        },
        {
          key: 'TrailingNtiTrends',
          text: 'NTI Trends',
          value: '/Reports/TrailingNtiTrends',
        },
      ]
    }

    // Conditionally add non-financial report based on permission
    if (meQuery.data?.user?.reportsNonFinancialPermission) {
      reports.push({
        key: 'PatronActivity',
        text: 'Patron Activity',
        value: '/Reports/PatronActivity',
      })
    }

    reports.sort((a, b) => a.text.localeCompare(b.text))
    return reports
  }, [
    meQuery.data?.user?.reportsFinancialPermission,
    meQuery.data?.user?.reportsNonFinancialPermission,
  ])

  const reportMenuItems = financialReports.map((report) => (
    <MenuItem key={report.key} value={report.value}>
      {report.text}
    </MenuItem>
  ))

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="View Reports"
            isSecondary={true}
            hasDivider={true}
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!meQuery.data?.user?.reportsNonFinancialPermission &&
      !meQuery.data?.user?.reportsFinancialPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view reports. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          {!licensedEstablishments || licensedEstablishments.length === 0 ? (
            <NoDataIndicator
              imageSource={NoPatronsCheckedInIcon}
              noDataMessage="Cannot view reports because there are no locations available for this corporate account"
            />
          ) : (
            <FormControl
              required
              sx={{ width: isMobile.isMobile ? '90vw' : '500px' }}
            >
              <RequiredSelectReport />
              <Select
                labelId="report-select-label"
                id="report-select"
                value={''}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select a report
                </MenuItem>
                {reportMenuItems}
              </Select>
            </FormControl>
          )}
        </>
      )}
    </Page>
  )
}
