import { compact, includes } from 'lodash'
import { useMemo } from 'react'
import { useApiQuery } from 'hooks/useApiQuery'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const useGetLesWithCaAndOrgAssociatedToUsersCa = () => {
  const { currentCorporateAccountId } = useCurrentCorporateAccountStore()

  const meQuery = useApiQuery({
    path: '/me',
    method: 'get',
    queryKey: ['/me', currentCorporateAccountId],
    queryArgs: {},
  })
  const data = meQuery.data

  const { licensedEstablishments, organizations, corporateAccounts } =
    useMemo(() => {
      const licensedEstablishments = compact(
        data?.licensedEstablishments?.filter(
          (x) => x.corporateAccountId === currentCorporateAccountId && x.active
        )
      )
      const orgIds = licensedEstablishments.map((x) => x.organizationId)

      const caIds = licensedEstablishments.map((x) => x.corporateAccountId)

      return {
        corporateAccounts: compact(
          data?.corporateAccounts?.filter((ca) => includes(caIds, ca.id))
        ),
        organizations: compact(
          data?.organizations?.filter((org) => includes(orgIds, org.id))
        ),
        licensedEstablishments,
      }
    }, [data, currentCorporateAccountId])

  return {
    isPending: meQuery.isPending,
    isError: meQuery.isError,
    licensedEstablishments,
    organizations,
    corporateAccounts,
    refetch: meQuery.refetch,
  }
}
