import { Tabs, styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import { colorPrimitives } from 'components/Theme'

export const HeaderStyledTabs = ({
  currentTab,
  children,
}: {
  currentTab: string
  children: React.ReactNode
}) => {
  const StyledTabs = styled(Tabs)({
    borderBottom: `0px solid ${colorPrimitives.borderGray}`,
    backgroundColor: grey[50],
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${colorPrimitives.borderGray}`,
    },
    '& .Mui-selected': {
      fontWeight: '700',
    },
  })
  return (
    <StyledTabs value={currentTab} variant="scrollable" scrollButtons="auto">
      {children}
    </StyledTabs>
  )
}
