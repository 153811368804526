import {
  Box,
  IconButton,
  AppBar as MuiAppBar,
  Stack,
  Toolbar,
} from '@mui/material'
import LogoImage from 'assets/jjConnectLogo.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { MainContent } from './MainContent'
import { useNavBar } from 'hooks/useNavBar'
import { CorporateAccountDetails } from './CorporateAccountDetails'

export const APP_BAR_HEIGHT = 65
export const MOBILE_APP_BAR_HEIGHT = 57

export const AppBar = () => {
  const toggle = useNavBar((state) => state.toggle)

  return (
    <MuiAppBar position="fixed" sx={{ bgcolor: 'white' }}>
      <Toolbar>
        <Box
          color="text.primary"
          display={{ xs: 'block', sm: 'block', md: 'block', lg: 'none' }}
        >
          <IconButton
            onClick={() => {
              toggle()
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box className="flex flex-row w-[80%]">
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
              },
            }}
          >
            <img
              alt="J&J Connect"
              src={LogoImage}
              width={200}
              style={{ height: 46 }}
              className=""
            />
          </Box>
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <CorporateAccountDetails />
          </Box>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
        >
          <MainContent />
        </Stack>
      </Toolbar>
    </MuiAppBar>
  )
}
