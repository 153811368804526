import { Box, Button, Typography } from '@mui/material'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { useGetMe } from 'hooks/api/useGetMe'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { useState } from 'react'
import { EditAttendantPointMaximumModal } from './EditAttendantPointMaximumModal'

export const AttendantPointMaximum = ({
  rewardProgramId,
}: {
  rewardProgramId: string
}) => {
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId,
  })
  const leUserQuery = useGetMe()
  const [editEnabled, setEditMode] = useState(false)

  if (rewardProgramQuery.isPending || leUserQuery.isPending) {
    return null
  }

  if (rewardProgramQuery.isError || leUserQuery.isError) {
    return <ErrorIndicator />
  }

  const canEditRewards = leUserQuery.data?.user?.rewardsPermission === true

  return (
    <>
      <Box>
        <Box className="flex flex-row items-center justify-between">
          <Typography variant="h3">Attendant Point Maximum</Typography>
          {canEditRewards && (
            <Button variant="text" onClick={() => setEditMode(!editEnabled)}>
              Edit
            </Button>
          )}
        </Box>
        <Typography variant="body-3" className="block">
          This is the amount of points attendants will be able to award a patron
          on a daily basis.
        </Typography>
        <Typography variant="body-3" className="block">
          Note: This is only available through the attendant view when a patron
          has checked-in at a location.
        </Typography>
        <Box className="rounded-md border-outline-light border-1 my-2 p-4">
          <Typography variant="body-3">Daily Max Points to Award</Typography>
          <Typography variant="body-1">
            {rewardProgramQuery.data.maxAllowedDailyPoints ?? '0'}
          </Typography>
        </Box>
        <Typography variant="body-3" className="block">
          * To enable this functionality, enter an amount greater than 0 by
          clicking the edit button above.
        </Typography>
      </Box>
      <EditAttendantPointMaximumModal
        isOpen={editEnabled && canEditRewards}
        closeModal={() => setEditMode(false)}
        rewardsProgram={rewardProgramQuery.data}
      />
    </>
  )
}
