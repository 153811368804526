import { type AlertColor } from '@mui/material'
import { create } from 'zustand'

interface SnackbarState {
  visible: boolean
  message: string | null
  setMessage: (message: string | null, severity?: AlertColor) => void
  severity?: AlertColor
}

export const useSnackbar = create<SnackbarState>((set) => ({
  visible: false,
  message: null,
  setMessage: (message, severity = undefined) => {
    if (message !== null) {
      set({ visible: true, message, severity })
    } else {
      set({ visible: false, message: null })
    }
  },
}))
