// VgtTrendsChart.tsx
import { chartColors, colorPrimitives } from 'components/Theme'
import { format, parse, parseISO } from 'date-fns'
import { useLayout } from 'hooks/useLayout'
import type React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  type TooltipProps,
} from 'recharts'

interface TransformedDataItem {
  date: string
  [key: string]: { amount: number; name: string; tagNumber: number } | string
}

interface VgtTrendsChartProps {
  data: TransformedDataItem[]
  endDate: Date
}

interface TooltipData {
  amount: number
  name: string
  tagNumber: number
}

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          textAlign: 'center',
        }}
      >
        <p style={{ fontWeight: 'bold' }}>{formatDate(label)}</p>
        <hr style={{ margin: '5px 0' }} />
        {payload.map((p) => {
          if (typeof p.name === 'string' && p.name in p.payload) {
            const vgtData = p.payload[p.name] as TooltipData
            return (
              <p key={p.name} style={{ textAlign: 'left' }}>
                {`${p.name}: $${vgtData.amount.toLocaleString()} (#${
                  vgtData.tagNumber
                }) - ${vgtData.name}`}
              </p>
            )
          }
          return null
        })}
      </div>
    )
  }

  return null
}

const formatCurrency = (value: number) => `$${value.toLocaleString()}`

const formatDate = (dateString: string) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date())
  return format(date, 'MMM dd')
}

export const VgtTrendsChart: React.FC<VgtTrendsChartProps> = ({
  data,
  endDate,
}) => {
  const filteredData = data.filter((datum) => {
    const date = parseISO(datum.date)
    return date <= endDate
  })

  const uniqueVGTNumbers = Array.from(
    new Set(
      filteredData.flatMap((item) =>
        Object.keys(item).filter((key) => key !== 'date')
      )
    )
  )

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={filteredData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          label={{
            value: 'Week of',
            position: 'insideBottom',
            offset: -4,
            fill: colorPrimitives.black,
          }}
          tickFormatter={formatDate}
          height={40}
          tick={{ fill: colorPrimitives.black }}
        />
        <YAxis
          tickFormatter={formatCurrency}
          color={colorPrimitives.black}
          tickCount={10}
          tick={{ fill: colorPrimitives.black }}
          label={{
            value: 'Credits Wagered',
            angle: -90,
            textAnchor: 'middle',
            dx: -35,
            fill: colorPrimitives.black,
          }}
          width={100}
        />
        <Tooltip content={<CustomTooltip />} />

        <Legend
          align="right"
          verticalAlign="top"
          layout="horizontal"
          wrapperStyle={{
            fontSize: useLayout().isMobile ? '16px' : '18px',
            top: '0px',
          }}
          formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
        />

        {uniqueVGTNumbers.map((vgtNumber, index) => (
          <Line
            key={vgtNumber}
            type="linear"
            dataKey={`${vgtNumber}.amount`}
            name={vgtNumber}
            stroke={chartColors[index % chartColors.length]}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
