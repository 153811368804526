import { Page } from 'components/Page'
import { RewardProgramDetailHeader } from './RewardProgramDetailHeader'
import { useParams } from 'react-router-dom'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { AttendantPointMaximum } from './AttendantPointMaximum'
import { isNil } from 'lodash'

export const RewardProgramSettingsPage = () => {
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId,
  })

  if (isNil(rewardProgramId)) {
    return <p>Reward program is missing</p>
  }

  if (rewardProgramQuery.isPending) {
    return <ActivityIndicator />
  }

  if (rewardProgramQuery.isError || isNil(rewardProgramQuery.data)) {
    return <p>An error occurred.</p>
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/RewardsProgram/${rewardProgramId}/Settings`}
        />
      }
    >
      <AttendantPointMaximum rewardProgramId={rewardProgramId} />
    </Page>
  )
}
