import { formatDateTime } from 'utils/util'
import { type Sweepstake } from '../../../types/api'
import { Box, Grid, Typography } from '@mui/material'
import { SectionHeaderButton } from 'components/Sweepstakes/SectionHeaderButton'

export const SweepstakesDetailsSection = ({
  sweepstake,
}: {
  sweepstake: Sweepstake
}) => {
  return (
    <>
      <SectionHeaderButton titleText="Sweepstakes Overview" />
      <Box
        sx={{
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          p: '16px',
          mb: '40px',
          mt: '15px',
        }}
      >
        <Grid container sx={{ rowGap: 5 }}>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Sweepstakes Name
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.name}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Start Date
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {formatDateTime(sweepstake.startDate, 'P p')}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              End Date
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {formatDateTime(sweepstake.endDate, 'P p')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="body-2" color="text.secondary">
              URL
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.url}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="body-2" color="text.secondary">
              Sweepstakes Description
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.description}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
