import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetMe } from 'hooks/api/useGetMe'
import { uniqBy } from 'lodash'
import RefreshIcon from 'assets/RefreshIcon.svg'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { PageHeader } from 'components/Shared/PageHeader'
import { PatronCard } from './PatronCard'
import { formatDateTime, pluralize } from 'utils/util'
import { useMemo, useState } from 'react'
import { usePatronCheckInsByLeId } from 'hooks/api/usePatronCheckInsByLeId'
import { colorPrimitives } from 'components/Theme'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { ActivityButton } from 'components/ActivityButton/ActivityButton'
import { useSnackbar } from 'stores/useSnackbar'
import { useLayout } from 'hooks/useLayout'

export const LiveAtLocationPage = () => {
  const meQuery = useGetMe()

  if (!meQuery.data?.user?.liveAtLocationViewPermission) {
    return <NoPermissions />
  }

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Live at Location"
            isSecondary={true}
            hasDivider={true}
            subtitle="Select a location to see which patrons are currently at that location"
          />
        </Box>
      }
      childrenClassName="min-h-[79vh] bg-cover flex flex-1"
    >
      <Content />
    </Page>
  )
}

const NoPermissions = () => {
  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Live at Location"
            isSecondary={true}
            hasDivider={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      <NoDataIndicator
        noImage
        noDataMessage="Your current permissions do not allow access to view Live at Location. To obtain access, please contact your Account Manager for assistance."
      />
    </Page>
  )
}

const Content = () => {
  const { isMobile } = useLayout()
  const meQuery = useGetMe()
  const { setMessage } = useSnackbar()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = useMemo(
    () =>
      uniqBy(
        meQuery.data?.licensedEstablishments?.filter(
          (le) => le.corporateAccountId === selectedCorporateAccountId
        ),
        'id'
      ),
    [meQuery, selectedCorporateAccountId]
  )

  const [selectedLE, setSelectedLE] = useState<number | undefined>(-1)

  const patronCheckInQuery = usePatronCheckInsByLeId({
    licensedEstablishmentId: selectedLE !== -1 ? selectedLE : undefined,
    refetchOnWindowFocus: false,
  })

  const patronList = useMemo(
    () => uniqBy(patronCheckInQuery.data, 'patronId'),
    [patronCheckInQuery]
  )

  const totalPatrons = patronList?.length ?? 0

  if (meQuery.isPending) {
    return (
      <Box className="flex flex-1 items-center justify-center">
        <ActivityIndicator />
      </Box>
    )
  }

  if (!licensedEstablishments || licensedEstablishments.length === 0) {
    return (
      <Box width="100%">
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="No locations currently available to view Live at Location"
        />
      </Box>
    )
  }

  return (
    <Box className="w-full">
      <Typography variant="h3">
        Select a location <span style={{ color: '#CC2027' }}>*</span>
      </Typography>

      <FormControl sx={{ width: isMobile ? '90vw' : '500px' }}>
        <Select
          displayEmpty
          className="mt-4"
          onChange={(event) => {
            if (event.target.value === '') {
              setSelectedLE(undefined)
            } else {
              setSelectedLE(Number(event.target.value))
            }
          }}
          value={selectedLE}
        >
          <MenuItem value={-1} disabled>
            Select a location
          </MenuItem>
          {licensedEstablishments.map((le) => {
            return (
              <MenuItem key={le.id} value={le.id}>
                {le.name} (#{le.licenseNumber})
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      {selectedLE !== -1 && (
        <>
          <Stack
            direction={'row'}
            className="justify-between items-center pt-4"
          >
            <Typography variant="h3">
              {pluralize(totalPatrons, 'Patron')} Checked In
            </Typography>
            <ActivityButton
              active={patronCheckInQuery.isRefetching}
              onClick={async () => {
                try {
                  await patronCheckInQuery.refetch()
                  setMessage(
                    'Successfully refreshed Live at Location dashboard.',
                    'success'
                  )
                } catch {
                  setMessage(
                    'Error refreshing Live at Location dashboard.',
                    'error'
                  )
                }
              }}
            >
              <Stack direction={'row'} spacing={1}>
                <img src={RefreshIcon} />
                <Typography color={colorPrimitives.redGaming}>
                  Refresh Dashboard
                </Typography>
              </Stack>
            </ActivityButton>
          </Stack>
          <Box className="flex flex-row flex-wrap gap-6 mt-2">
            {totalPatrons === 0 && (
              <Box width="100%">
                <NoDataIndicator
                  imageSource={NoPatronsCheckedInIcon}
                  noDataMessage=""
                />
              </Box>
            )}
            {patronList.map((patronCheckin) => (
              <PatronCard
                className="w-[14rem] rounded-md"
                key={`patron-checkin-for-${patronCheckin?.id}`}
                id={patronCheckin?.patronId}
                name={`${patronCheckin?.patron?.firstName} ${
                  String(patronCheckin?.patron?.lastName)[0]
                }.`}
                checkinTime={
                  formatDateTime(patronCheckin?.createdOn, 'hh:mm a') ?? ''
                }
                tier={patronCheckin?.patron?.currentTier?.tier?.type ?? 'None'}
                licensedEstablishmentId={Number(selectedLE)}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  )
}
