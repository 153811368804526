import { Box, Divider, Typography } from '@mui/material'
import type { GridColDef, GridEventListener, MuiEvent } from '@mui/x-data-grid'
import { isNil } from 'lodash'

const MobileRow = ({
  row,
  columns,
}: {
  row: any
  columns: readonly GridColDef[]
}) => {
  return columns.map((col: any, index: number) => {
    const {
      field,
      valueGetter,
      valueFormatter,
    }: {
      field: string
      valueGetter?: (params: { row: object }) => any
      valueFormatter?: (params: { value: any }) => string
    } = col

    const variant = index === 0 ? 'h3' : 'body1'
    const color = index !== 0 ? '#292B2699' : '#2C2C27'

    let value = row[field]
    if (valueGetter) {
      value = valueGetter({ row })
    }
    if (valueFormatter) {
      value = valueFormatter({ value })
    }

    return (
      <Typography variant={variant} color={color} key={col.field}>
        {value}
      </Typography>
    )
  })
}

export const MobileDataTable = ({
  columns,
  rows,
  onRowClick,
}: {
  columns: readonly GridColDef[]
  rows: any
  onRowClick?: GridEventListener<'rowClick'>
}) => {
  return (
    <>
      {rows.map((row: any) => (
        <>
          <Box
            padding={2}
            onClick={(
              event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>
            ) => {
              if (!isNil(onRowClick)) {
                onRowClick(row, event, {})
              }
            }}
            key={row.id}
          >
            <MobileRow row={row} columns={columns} />
          </Box>
          <Divider />
        </>
      ))}
    </>
  )
}
