import { useApiQuery } from 'hooks/useApiQuery'
import { format } from 'date-fns'
const path = '/le-connect/reports/multi-location-weekly-snapshot'
const method = 'get'

interface UseGetMultiLocationWeeklySnapshotParams {
  licenses: string[]
  startDate: Date
  endDate: Date
}

const formatDate = (d: Date) => format(d, 'yyyy-MM-dd')

export const useGetMultiLocationWeeklySnapshot = ({
  licenses,
  startDate,
  endDate,
}: UseGetMultiLocationWeeklySnapshotParams) =>
  useApiQuery({
    path,
    method,
    queryKey: [
      path,
      licenses.join(','),
      startDate.getTime(),
      endDate.getTime(),
    ],
    queryArgs: {
      licenses,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
  })
