import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type FullLicensedEstablishmentFlexPayStatementDTO } from 'src/types/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UseGetDownloadFlexPayStatementsOptionsParams {
  licensedEstablishmentId: string
  blobName: string
  blobDate: string
  license: string
  type: string
}

interface UseGetDownloadFlexPayStatementsOptions {
  onSuccess?: (
    data: FullLicensedEstablishmentFlexPayStatementDTO,
    variables: UseGetDownloadFlexPayStatementsOptionsParams
  ) => void
  onError?: (
    error: Error,
    variables: UseGetDownloadFlexPayStatementsOptionsParams
  ) => void
}

export const useGetDownloadFlexPayStatements = ({
  onSuccess,
  onError,
}: UseGetDownloadFlexPayStatementsOptions) => {
  const get = useTypedApiClient({
    path: '/le-connect/flexpaystatements',
    method: 'get',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, UseGetDownloadFlexPayStatementsOptionsParams>({
    mutationFn: async (input) => {
      const { licensedEstablishmentId, blobName, blobDate, license, type } =
        input

      const { data } = await get(
        {
          LicensedEstablishmentId: licensedEstablishmentId,
          BlobName: blobName,
          BlobDate: blobDate,
          License: license,
          Type: type,
        },
        {
          headers: {
            licensedEstablishmentId,
            blobName,
            blobDate,
            license,
            type,
          },
        }
      )

      return data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/le-connect/flexpaystatements'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
