import { useApiQuery } from 'hooks/useApiQuery'

const path = '/le-connect/corporate-accounts/{id}/patrons/checkins'

interface UseGetPatronCheckinsParams {
  corporateAccountId?: number
}

export const useGetPatronCheckins = ({
  corporateAccountId,
}: UseGetPatronCheckinsParams) =>
  useApiQuery({
    path,
    method: 'get',
    queryKey: [path, corporateAccountId],
    queryArgs: {
      id: corporateAccountId,
    },
  })
