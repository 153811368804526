import type React from 'react'
import { useMemo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  type TooltipProps,
} from 'recharts'
import { format, getMonth, parse } from 'date-fns'
import { useLayout } from 'hooks/useLayout'
import { chartColors, colorPrimitives } from 'components/Theme'

interface DataPoint {
  month: string
  [key: string]: number | string
}

interface Metric {
  amount: number
  month: number
  year: number
}

interface Props {
  data: Metric[]
  displayType: 'Location' | 'Total'
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  data: DataPoint[]
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && label) {
    const formatDollarAmount = (value: number) => `$${value.toLocaleString()}`

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          textAlign: 'center',
        }}
      >
        <p style={{ fontWeight: 'bold' }}>Month: {label}</p>
        <br />
        {payload.map((entry) => {
          const year = entry.name?.replace('year', '')
          const monthYear = `${year}`
          const amount = formatDollarAmount(entry.value as number)
          return <p key={entry.name}>{`${monthYear}: ${amount}`}</p>
        })}
      </div>
    )
  }

  return null
}

export const TrailingNtiTrendsChart: React.FC<Props> = ({
  data,
  displayType,
}) => {
  const isMobile = useLayout()

  const uniqueYears = useMemo(() => {
    const years = new Set(data.map((item) => item.year))
    return Array.from(years).sort((a, b) => a - b)
  }, [data])
  const processedData = useMemo(() => {
    const monthlyTotals: Record<string, DataPoint> = {}

    data.forEach((datum) => {
      const monthKey = format(new Date(datum.year, datum.month - 1), 'MMMM')
      if (!monthlyTotals[monthKey]) {
        monthlyTotals[monthKey] = { month: monthKey }
      }

      monthlyTotals[monthKey][`year${datum.year}`] = datum.amount
    })

    const sortedMonthlyTotals = Object.values(monthlyTotals).sort((a, b) => {
      const year = new Date().getFullYear()
      const dateA = parse(a.month, 'MMMM', new Date(year))
      const dateB = parse(b.month, 'MMMM', new Date(year))
      return getMonth(dateA) - getMonth(dateB)
    })

    return sortedMonthlyTotals
  }, [data, displayType])

  const formatDollarAmount = (tickItem: number): string => {
    return `$${tickItem.toLocaleString()}`
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart
        data={processedData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="month"
          height={45}
          label={{
            value: 'Month',
            position: 'insideBottom',
            offset: -2,
            fill: colorPrimitives.black,
          }}
          tick={{ fill: colorPrimitives.black }}
        />
        <YAxis
          tickFormatter={formatDollarAmount}
          style={{ fill: colorPrimitives.black }}
          tickCount={8}
          label={{
            value: displayType === 'Location' ? 'Location NTI' : 'Total NTI',
            angle: -90,
            position: 'insideLeft',
            fill: colorPrimitives.black,
          }}
          width={90}
        />
        <Tooltip content={<CustomTooltip data={processedData} />} />
        <Legend
          align="right"
          verticalAlign="top"
          layout="horizontal"
          wrapperStyle={{
            fontSize: isMobile ? '16px' : '18px',
            top: '0px',
          }}
          formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
        />
        {uniqueYears.map((year, index) => (
          <Line
            key={year}
            type="linear"
            dataKey={`year${year}`}
            name={`Year ${year}`}
            stroke={chartColors[index % chartColors.length]}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
