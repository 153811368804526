import { Box } from '@mui/material'
import { type GridEventListener, type GridColDef } from '@mui/x-data-grid'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { DataTable } from 'components/Shared/DataTable'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { useGetMe } from 'hooks/api/useGetMe'
import { useGetSweepstakes } from 'hooks/api/useGetSweepstakes'
import { formatFullDate } from 'utils/util'
import { useNavigate } from 'react-router-dom'
import { SweepstakesStatusLabel } from './SweepstakesStatusLabel'

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Sweepstakes Name',
    minWidth: 400,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Sweepstake Status',
    minWidth: 300,
    renderCell: (params) => {
      const status = params.row.sweepstakeData?.state ?? 'Draft'
      return <SweepstakesStatusLabel status={status} />
    },
  },
  {
    field: 'startDate',
    headerName: 'Start',
    minWidth: 150,
    renderCell: (params) => <Box>{formatFullDate(params.value)}</Box>,
  },
  {
    field: 'endDate',
    headerName: 'End',
    minWidth: 150,
    renderCell: (params) => <Box>{formatFullDate(params.value)}</Box>,
  },
]

export const SweepstakesPage = () => {
  const meQuery = useGetMe()
  const query = useGetSweepstakes()
  const navigate = useNavigate()

  const selectRow: GridEventListener<'rowClick'> = (params) => {
    if (params.id !== null) {
      navigate(`/Sweepstakes/${params.id}/Overview`)
    }
  }

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Sweepstakes"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!meQuery.data?.user?.sweepstakesPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view sweepstakes. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          {query.isPending && <ActivityIndicator />}
          {query.isError && <p>An error occurred.</p>}
          {!query.isError && query.data && (
            <DataTable
              columns={columns}
              rows={query.data}
              sort="asc"
              sortFieldName="startDate"
              onRowClick={selectRow}
            />
          )}
        </>
      )}
    </Page>
  )
}
