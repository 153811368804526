import { Box } from '@mui/material'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import cx from 'classnames'
import { colorPrimitives } from 'components/Theme'

export enum FormFieldMessageVariant {
  Default = 'default',
  Success = 'success',
  Error = 'error',
}

export enum FormFieldMessageIconPosition {
  Left = 'left',
  Right = 'right',
}

const VariantClassNameMap = {
  [FormFieldMessageVariant.Success]: colorPrimitives.successGreen,
  [FormFieldMessageVariant.Error]: colorPrimitives.redGaming,
  [FormFieldMessageVariant.Default]: colorPrimitives.darkGray,
}

interface FormFieldMessageProps {
  message: string
  variant?: FormFieldMessageVariant
  icon?: string | null
  iconPosition?: FormFieldMessageIconPosition
}

export const FormFieldMessage = ({
  message,
  variant = FormFieldMessageVariant.Default,
  icon,
  iconPosition = FormFieldMessageIconPosition.Left,
}: FormFieldMessageProps) => {
  const styleClassName: string = VariantClassNameMap[variant]

  return (
    <Box className="flex flex-row pt-2 items-start">
      {iconPosition === FormFieldMessageIconPosition.Left && icon && (
        <img src={icon} alt={`formFieldMessage-icon-${variant}`} />
      )}
      <ErrorIndicator
        style={cx(
          'py-0 pt-0.5 leading-5',
          styleClassName,
          icon ? 'px-1.5' : ''
        )}
        color={styleClassName}
        message={message}
      />
      {iconPosition === FormFieldMessageIconPosition.Right && icon && (
        <img src={icon} />
      )}
    </Box>
  )
}
