// B2C authorization flow config
// Paste + tweak from this template:
//   https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/tree/main/3-Authorization-II/2-call-api-b2c

import { LogLevel } from '@azure/msal-browser'

const loggerCallback = (
  level: LogLevel,
  message: string,
  containsPii: boolean
) => {
  if (containsPii) {
    return
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message)
      return
    case LogLevel.Info:
      console.info(message)
      return
    case LogLevel.Verbose:
      console.debug(message)
      return
    case LogLevel.Warning:
      console.warn(message)
      break

    default:
  }
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const b2cMsalConfig = {
  auth: {
    clientId: import.meta.env.VITE_LE_CONNECT_MSAL_CONFIG_CLIENTID,
    authority: import.meta.env.VITE_LE_CONNECT_MSAL_CONFIG_AUTHORITY,
    knownAuthorities: [
      import.meta.env.VITE_LE_CONNECT_MSAL_CONFIG_AUTHORITY_DOMAIN,
    ],
    redirectUri: window.location.origin + '/',
    postLogoutRedirectUri: window.location.origin + '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: { loggerCallback },
  },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const b2cLoginRequest = {
  scopes: [import.meta.env.VITE_LE_CONNECT_MSAL_CONFIG_SCOPE],
}
