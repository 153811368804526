import { isPresent } from '@jjvgaming/player-payback-library'
import { Box, Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetPatronActivityLog } from 'hooks/api/useGetPatronActivityLog'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { editProfileMetricSelectData } from './EditGamingHabitsModal'
import { format } from 'date-fns'

interface LogEntryType {
  date: Date
  message: string
}

export const ActivityLog = ({ leUserName }: { leUserName: string }) => {
  const { id, licensedEstablishmentId } = useParams()
  const activityLogQuery = useGetPatronActivityLog({
    patronId: Number(id),
    licensedEstablishmentId: Number(licensedEstablishmentId),
  })

  const sortedLog: LogEntryType[] = useMemo(() => {
    const res: LogEntryType[] = []
    // Get Logs for Profile Metrics
    activityLogQuery.data?.licensedEstablishmentPatronProfileMetrics?.forEach(
      (metric) => {
        isPresent(metric.averageBetInCents) &&
          res.push({
            date: new Date(String(metric.createdOn)),
            message: `Perceived average bet updated to ${editProfileMetricSelectData.avgBet.find(
              (x) => x.value === metric.averageBetInCents
            )?.title} by ${leUserName}`,
          })
        isPresent(metric.perceivedLengthOfStayInMinutes) &&
          res.push({
            date: new Date(String(metric.createdOn)),
            message: `Perceived average visit length updated to ${editProfileMetricSelectData.visitLength.find(
              (x) => x.value === metric.perceivedLengthOfStayInMinutes
            )?.title} by ${leUserName}`,
          })
      }
    )
    // Get Logs for Manual Awards For CA/LE
    // CA/LE is differentiated by the reward program
    activityLogQuery.data?.pointsLedgerEntries?.forEach((pointsLedgerEntry) => {
      res.push({
        date: new Date(String(pointsLedgerEntry.createdOn)),
        message: `+${pointsLedgerEntry.amount} Manual Award added for the ${pointsLedgerEntry.rewardsProgram?.name}`,
      })
    })
    // Sort
    return orderBy(res, ['date'], ['desc'])
  }, [activityLogQuery.data])

  if (activityLogQuery.isPending) {
    return <ActivityIndicator />
  }

  return (
    sortedLog.length > 0 && (
      <Box px={'24px'} pt={'48px'}>
        <Typography variant="h3">30 Day Activity Log</Typography>
        <Stack className="pt-4" spacing={'12px'}>
          {sortedLog?.map((log, index) => {
            return (
              <Stack
                className="bg-white p-3"
                key={log.date.toDateString() + index}
                flexDirection={'row'}
                gap={'16px'}
                justifyContent={'start'}
                alignItems={'center'}
              >
                <Typography
                  className=" min-w-[190px]"
                  variant="label-form"
                  color={'text.secondary'}
                >
                  [{format(log.date, 'MM-dd-yyyy pp')}]
                </Typography>
                <Typography className="" variant="label-form">
                  {log.message}
                </Typography>
              </Stack>
            )
          })}
        </Stack>
      </Box>
    )
  )
}
