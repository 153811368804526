import Accordion from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { type LicensedEstablishmentLicense } from 'src/types/api'
import { LicenseCardHeaderLicensedEstablishmentPage } from './LicenseCardHeaderLicensedEstablishmentPage'
import { LicenseCardBodyLicensedEstablishmentPage } from './LicenseCardBodyLicensedEstablishmentPage'

interface LicenseDetailLicensedEstablishmentPageProps {
  licensedEstablishmentId: number
  licensedEstablishmentName: string
  licensedEstablishmentLicenses: LicensedEstablishmentLicense[]
  defaultExpanded: boolean
  uniqueLicensedEstablishment: boolean
}

export const LicenseDetailLicensedEstablishmentPage = ({
  licensedEstablishmentId,
  licensedEstablishmentName,
  licensedEstablishmentLicenses,
  defaultExpanded = false,
  uniqueLicensedEstablishment = false,
}: LicenseDetailLicensedEstablishmentPageProps) => {
  return (
    <div>
      <Accordion
        sx={{
          backgroundColor: 'inherit',
          border: 0,
          borderBottom: uniqueLicensedEstablishment
            ? '0'
            : `1px solid #0000001f`,
          borderRadius: '0 !important',
          boxShadow: 'none',
          paddingBottom: 1,
        }}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          expandIcon={!uniqueLicensedEstablishment ? <ExpandMoreIcon /> : <></>}
          id={`panel-${licensedEstablishmentName}`}
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            width: '100%',
          }}
        >
          <LicenseCardHeaderLicensedEstablishmentPage
            licensedEstablishmentId={licensedEstablishmentId}
            licensedEstablishmentName={licensedEstablishmentName}
            licensedEstablishmentLicenses={licensedEstablishmentLicenses}
          />
        </AccordionSummary>
        <AccordionDetails>
          <LicenseCardBodyLicensedEstablishmentPage
            licensedEstablishmentLicenses={licensedEstablishmentLicenses}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
