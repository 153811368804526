import { Box, Grid, Typography } from '@mui/material'
import { type FullPointsLedgerSummaryDTO } from 'src/types/api'
import { RewardBox } from './RewardBox'

export const LivePoints = ({
  fullPointsLedgerSummary,
  licensedEstablishmentUserId,
  patronName,
}: {
  fullPointsLedgerSummary: FullPointsLedgerSummaryDTO[]
  licensedEstablishmentUserId?: number
  patronName: string
}) => {
  return (
    <Box>
      {fullPointsLedgerSummary.length > 0 && (
        <Box pt={6} pb={3}>
          <Typography variant="h3">Points</Typography>
        </Box>
      )}

      <Grid container className="flex flex-row flex-wrap justify-between gap-5">
        {fullPointsLedgerSummary?.map((pointsLedgerEntry) => {
          return pointsLedgerEntry.balance !== undefined &&
            pointsLedgerEntry.rewardsProgram ? (
            <RewardBox
              key={`${pointsLedgerEntry.rewardsProgram.name}-${pointsLedgerEntry.balance}`}
              rewardsProgram={pointsLedgerEntry.rewardsProgram}
              points={pointsLedgerEntry.balance}
              patronId={pointsLedgerEntry.patronId}
              patronName={patronName}
              licensedEstablishmentUserId={licensedEstablishmentUserId}
            />
          ) : null
        })}
      </Grid>
    </Box>
  )
}
