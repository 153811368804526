import { Box, Button, CircularProgress, type ButtonProps } from '@mui/material'
import { white } from './Theme'

interface ActivityButtonProps {
  active: boolean
}

export const ActivityButton = ({
  active,
  variant,
  children,
  ...buttonProps
}: ActivityButtonProps & ButtonProps) => {
  const progressBarColorOverride = variant === 'contained' ? white : undefined

  return (
    <Button variant={variant} {...buttonProps}>
      <Box sx={{ opacity: active ? 0 : 1 }}>{children}</Box>
      <Box position="absolute">
        {active && (
          <CircularProgress
            size={24}
            color="primary"
            sx={{ color: progressBarColorOverride }}
          />
        )}
      </Box>
    </Button>
  )
}
