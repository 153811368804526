import { Typography, Link, Stack } from '@mui/material'
import { TreeDataGrid } from 'components/TreeDataGrid/TreeDataGrid'
import {
  type TreeDataGridRow,
  prepareTreeDataGridRows,
  buildTreeDataGridRows,
} from 'components/TreeDataGrid/utils'
import { type components } from 'api/playerPayback/api'
import { type GridColDef, type DataGridProps } from '@mui/x-data-grid'
import { Link as RouterLink } from 'react-router-dom'
import { isNil } from 'lodash'
import { IndentedCell } from 'components/TreeDataGrid/IndentedCell'
import { useMemo } from 'react'
import {
  type CorporateAccount,
  type LicensedEstablishment,
  type Organization,
} from 'src/types/api'
import { colorPrimitives } from 'components/Theme'

const dataTableIdFormatter =
  (isMobile: boolean) => (params: { value: string }) => {
    return isMobile ? `ID #${params.value}` : params.value
  }

enum AccountTypes {
  CorporateAccount,
  Organization,
  LicensedEstablishment,
}

const AccountTypesLabels = new Map([
  [AccountTypes.CorporateAccount, 'Corporate Account'],
  [AccountTypes.Organization, 'Organization'],
  [AccountTypes.LicensedEstablishment, 'Licensed Establishment'],
])

const AccountTypesLinks = new Map([
  [AccountTypes.CorporateAccount, '/CorporateAccounts'],
  [AccountTypes.Organization, '/Organization'],
  [AccountTypes.LicensedEstablishment, '/LicensedEstablishments'],
])

interface Item extends TreeDataGridRow {
  type: AccountTypes
  id: number
  licenseNumber?: string
  name: string
}

export const MobileColumns: Array<GridColDef<Item>> = [
  {
    field: 'name',
    headerName: 'Account Name',
    flex: 2,
    sortable: false,
    renderCell: (params) => (
      <IndentedCell depth={params.row.parentIndexPath.length}>
        <Stack>
          <Link
            to={`${AccountTypesLinks.get(params.row.type)}/${params.row.id}`}
            component={RouterLink}
            underline="hover"
            color="text.primary"
            sx={{
              '&:hover': {
                color: '#1A4F64',
              },
            }}
          >
            <Typography>{params.row.name}</Typography>
          </Link>
          <Typography>
            Type: {AccountTypesLabels.get(params.row.type)}
          </Typography>
          <Typography>
            {dataTableIdFormatter(true)({ value: params.row.id.toString() })}
          </Typography>
          {params.row.licenseNumber && (
            <Typography>License Number: #{params.row.licenseNumber}</Typography>
          )}
        </Stack>
      </IndentedCell>
    ),
  },
]

export const DesktopColumns: Array<GridColDef<Item>> = [
  {
    field: 'name',
    headerName: 'Account Name',
    minWidth: 150,
    flex: 2,
    renderCell: (params) =>
      params.row.type === AccountTypes.LicensedEstablishment ? (
        <Link
          to={`${AccountTypesLinks.get(params.row.type)}/${params.row.id}`}
          component={RouterLink}
          underline="hover"
          color="text.primary"
          sx={{
            '&:hover': {
              color: colorPrimitives.redGaming,
            },
          }}
        >
          <Typography>{params.row.name}</Typography>
        </Link>
      ) : (
        <Typography>{params.row.name}</Typography>
      ),
  },
  {
    field: 'type',
    headerName: 'Account Type',
    minWidth: 100,
    flex: 1,
    valueFormatter: (params: { value: AccountTypes }) =>
      AccountTypesLabels.get(params.value),
  },
  {
    field: 'licenseNumber',
    headerName: 'License Number',
    minWidth: 100,
    flex: 1,
    valueFormatter: (params: { value: number }) => {
      if (isNil(params.value)) {
        return '-'
      } else {
        return params.value
      }
    },
  },
]

const generateCorporateAccountTreeData = (
  corporateAccounts: Array<components['schemas']['FullCorporateAccountDTO']>,
  organizations: Array<components['schemas']['FullOrganizationDTO']>,
  licensedEstablishments: Array<
    components['schemas']['FullLicensedEstablishmentDTO']
  >,
  expandAll: boolean
): TreeDataGridRow[] => {
  const corporateAccountsPrepared = prepareTreeDataGridRows({
    records: corporateAccounts,
    rowIdPrefix: 'corporateAccount',
    newFields: {
      type: AccountTypes.CorporateAccount,
      expanded: expandAll,
    },
  })

  const organizationsPrepared = prepareTreeDataGridRows({
    records: organizations,
    rowIdPrefix: 'organization',
    parentAggregationMaps: [
      {
        parentFieldName: 'corporateAccountId',
        parentRowIdPrefix: 'corporateAccount',
      },
      {
        parentFieldName: 'parentOrganizationId',
        parentRowIdPrefix: 'organization',
      },
    ],
    newFields: {
      type: AccountTypes.Organization,
      expanded: expandAll,
    },
  })

  const licensedEstablishmentPrepared = prepareTreeDataGridRows({
    records: licensedEstablishments,
    rowIdPrefix: 'licensedEstablishment',
    parentAggregationMaps: [
      {
        parentFieldName: 'corporateAccountId',
        parentRowIdPrefix: 'corporateAccount',
      },
      {
        parentFieldName: 'organizationId',
        parentRowIdPrefix: 'organization',
      },
    ],
    newFields: {
      type: AccountTypes.LicensedEstablishment,
    },
  })

  return buildTreeDataGridRows([
    ...corporateAccountsPrepared,
    ...licensedEstablishmentPrepared,
    ...organizationsPrepared,
  ])
}

const sortComparatorByName = (
  a: { name?: string | null },
  b: { name?: string | null }
) => {
  const nameA = a.name ?? ''
  const nameB = b.name ?? ''
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

interface CorporateAccountsTreeDataGridProps
  extends Omit<DataGridProps, 'rows' | 'columns'> {
  columns?: Array<GridColDef<Item>>
  mobileColumns?: Array<GridColDef<Item>>
  corporateAccounts: CorporateAccount[]
  organizations: Organization[]
  licensedEstablishments: LicensedEstablishment[]
}
export const CorporateAccountsTreeDataGrid = ({
  corporateAccounts,
  organizations,
  licensedEstablishments,
  columns,
  mobileColumns,
  ...dataGridProps
}: CorporateAccountsTreeDataGridProps) => {
  // TS doesn't like us assigning Item to TreeDataGridRow :thinking:
  const castedDesktopColumns = (columns ?? DesktopColumns) as Array<
    GridColDef<TreeDataGridRow>
  >
  const castedMobileColumns = (mobileColumns ??
    columns ??
    MobileColumns) as Array<GridColDef<TreeDataGridRow>>

  const rows = useMemo(() => {
    corporateAccounts.sort(sortComparatorByName)
    organizations.sort(sortComparatorByName)
    licensedEstablishments.sort(sortComparatorByName)

    return generateCorporateAccountTreeData(
      corporateAccounts,
      organizations,
      licensedEstablishments,
      corporateAccounts.length === 1
    )
  }, [corporateAccounts, organizations, licensedEstablishments])

  return (
    <TreeDataGrid
      columns={castedDesktopColumns}
      mobileColumns={castedMobileColumns}
      rows={rows}
      {...dataGridProps}
    />
  )
}
