import { Box, Divider, Drawer } from '@mui/material'
import { APP_BAR_HEIGHT } from 'components/AppBar/AppBar'
import { useNavBar } from 'hooks/useNavBar'
import { Content } from './Content'
import LogoImage from 'assets/jjConnectLogo.svg'
import { useLayout } from 'hooks/useLayout'
import { DeployVersion } from 'components/DeployVersion'

export const NAVIGATION_BAR_WIDTH = 251

export const NavigationBar = () => {
  const { isMobile } = useLayout()
  const isOpen = useNavBar((state) => state.isOpen)
  const close = useNavBar((state) => state.close)

  return (
    <>
      <Drawer
        variant="temporary"
        PaperProps={{
          sx: { top: { xs: 0, sm: 0, md: 0, lg: `${APP_BAR_HEIGHT}px` } },
        }}
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'none',
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isMobile
              ? `${NAVIGATION_BAR_WIDTH * 1.35}px`
              : `${NAVIGATION_BAR_WIDTH}px`,
          },
        }}
        open={isOpen}
        onClose={close}
        data-testid="mobile-navigation-bar"
      >
        <Box m={2} sx={{ ml: 1.3 }}>
          <img src={LogoImage} />
        </Box>
        <Divider />
        <Content />
      </Drawer>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: { top: `${APP_BAR_HEIGHT}px` },
        }}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: `${NAVIGATION_BAR_WIDTH}px`,
            height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
            overflowX: 'hidden',
          },
        }}
        open={true}
        data-testid="desktop-navigation-bar"
      >
        <Content />
        <DeployVersion />
      </Drawer>
    </>
  )
}
