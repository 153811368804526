import { useApiQuery } from 'hooks/useApiQuery'

export const useGetContestLicensedEstablishment = ({
  contestId,
}: {
  contestId: number
}) => {
  return useApiQuery({
    method: 'get',
    path: '/le-connect/contests/{contestId}/licensed-establishments',
    queryKey: ['/contests/{contestId}/licensed-establishments', contestId],
    queryArgs: {
      contestId,
    },
  })
}
