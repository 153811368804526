import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useLayout } from 'hooks/useLayout'

export const ConfirmEnrollmentModal = ({
  isModalOpen,
  onClose,
  onConfirm,
  sweepstakeName,
}: {
  isModalOpen: boolean
  onClose: () => void
  onConfirm: () => void | Promise<void>
  sweepstakeName: string
}) => {
  const { isMobile, isHeightUnder600, isHeightUnder400 } = useLayout()

  return (
    <Dialog
      fullScreen={isMobile}
      open={isModalOpen}
      onClose={() => {
        onClose()
      }}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '800px' } }}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={{ xs: 2, sm: 4 }} py={{ xs: 3, sm: 4 }}>
          <Typography variant="h3">Enroll in Sweepstakes</Typography>
          <Typography variant="label-form" color={'text.secondary'}>
            {sweepstakeName}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          mb: '0px',
          zIndex: 0,
          overflow: 'auto',
          minHeight: 'fit-content',
          maxHeight: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Box paddingTop={isHeightUnder400 ? 15 : isHeightUnder600 ? 5 : 3}>
          <Typography variant="h6" fontWeight={700} pb={'8px'}>
            Are you sure you want to enroll in {sweepstakeName}?
          </Typography>
          <Typography variant="label-form" color={'text.secondary'}>
            Submitting this enrollment form represents and warrants to Terminal
            Operator that (a) the person executing this Enrollment is an
            authorized signatory of the Video Gaming Location and (b) Video
            Gaming Location authorizes Terminal Operator to deduct the
            applicable Participation Fee from Net Terminal Income as set forth
            above.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className="border-t">
        <Box className="flex flex-row justify-end py-4 md:px-8 sm:px-4">
          <Button
            variant="text"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={async () => {
              await onConfirm()
              onClose()
            }}
          >
            Enroll
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
