import { Card, type SxProps, type Theme, Typography } from '@mui/material'
import { isNil } from 'lodash'

export const SimpleImageCard = ({
  imageSource,
  sx,
}: {
  imageSource: string | undefined | null
  sx?: SxProps<Theme>
}) => {
  if (isNil(imageSource)) {
    return <Typography>No image</Typography>
  }

  return (
    <Card sx={sx}>
      <img
        alt="ImageCard"
        src={imageSource}
        className="w-full h-full object-center object-cover"
      />
    </Card>
  )
}
