import { first, isNil } from 'lodash'
import { Box, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishmentLicense } from 'src/types/api'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

export const LicenseCardHeaderLicensedEstablishmentPage = ({
  licensedEstablishmentId,
  licensedEstablishmentName,
  licensedEstablishmentLicenses,
}: {
  licensedEstablishmentId: number
  licensedEstablishmentName: string
  licensedEstablishmentLicenses: LicensedEstablishmentLicense[]
}) => {
  const theme = useTheme()
  const IconLicenseLicense = () => {
    const validLicensedEstablishmentLicense: boolean = isNil(
      first(
        licensedEstablishmentLicenses.filter(
          (x) => x.licenseStatus === 'Expired'
        )
      )
    )

    return validLicensedEstablishmentLicense &&
      licensedEstablishmentLicenses.length > 0 ? (
      <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
    ) : (
      <ErrorOutlineOutlinedIcon sx={{ color: theme.palette.error.main }} />
    )
  }

  return (
    <Box className="flex items-center">
      <IconLicenseLicense />
      <Typography variant="subtitle-2" sx={{ px: 2 }}>
        {licensedEstablishmentName}
        <Typography
          variant="body-3"
          sx={{ color: theme.palette.text.primary }}
        >{` - #${String(licensedEstablishmentId)}`}</Typography>
      </Typography>
    </Box>
  )
}
