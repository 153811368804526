import { colorPrimitives } from 'components/Theme'
import { v4 as uuid } from 'uuid'

export const ErrorIndicator = ({
  message = 'Something went wrong',
  style,
  color = colorPrimitives.black,
}: {
  message?: string
  style?: string
  color?: string
}) => {
  const newText = message.split('\n')
  return (
    <div>
      {newText.map((str) => (
        <p className={style} style={{ color }} key={uuid()}>
          {str}
        </p>
      ))}
    </div>
  )
}
