import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useState } from 'react'
import { type SweepstakeWinner } from 'src/types/api'

interface SweepstakesRequestRedrawModalProps {
  isModalOpen: boolean
  toggleIsOpen: () => void
  onConfirm: (reason: string) => void
  onCancel: () => void
  locationName: string
  licenseNumber: string
  winnerName: string
  sweepstakesWinner: SweepstakeWinner
}

export const SweepstakesRequestRedrawModal = ({
  isModalOpen,
  onConfirm,
  onCancel,
  locationName,
  licenseNumber,
  winnerName,
}: SweepstakesRequestRedrawModalProps) => {
  const [reason, setReason] = useState('')

  const handleConfirm = () => {
    if (reason.trim()) {
      onConfirm(reason)
    }
  }

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '800px' } }}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0, mb: 2 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Request Redraw
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {`${locationName} (${licenseNumber})`}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ pt: 4, px: 4 }}>
        <Stack>
          <Stack direction="row" spacing={2} pb={4}>
            <Typography
              variant="body-1"
              fontSize={18}
              pt={2}
              sx={{ width: '50%' }}
            >
              Winner Name
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              value={winnerName}
              InputProps={{
                readOnly: true,
              }}
              contentEditable={false}
            />
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Typography variant="body-1" fontSize={18} sx={{ width: '50%' }}>
              Reason for Request{' '}
              <Box component="span" sx={{ color: 'red' }}>
                *
              </Box>
            </Typography>
            <TextField
              margin="normal"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              multiline
              rows={4}
              fullWidth
              placeholder="Enter redraw request reasoning"
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          disabled={!reason.trim()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
