import _, { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { Page } from 'components/Page'
import { Typography, Box } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { RewardProgramDetailHeader } from './RewardProgramDetailHeader'
import { colorPrimitives } from 'components/Theme'
import { type GridColDef } from '@mui/x-data-grid'
import { type RewardsCatalogReward } from 'src/types/api'
import { DataTable } from 'components/Shared/DataTable'
import { useGetRewardsByRewardsProgramId } from 'hooks/api/useGetRewardsByRewardsProgramId'

export const RewardProgramDetailPage = () => {
  const getColumns = (): Array<GridColDef<RewardsCatalogReward>> => [
    {
      field: 'name',
      headerName: 'Reward Item',
      valueGetter: (params) => params.row.reward?.name,
      minWidth: 480,
      flex: 2,
    },
    {
      field: 'amount',
      headerName: 'Point Value',
      valueGetter: (params) => `${params.row.amount} Points`,
      minWidth: 100,
      flex: 3,
    },
  ]

  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId,
  })

  const rewardsByProgramIdQuery = useGetRewardsByRewardsProgramId({
    rewardProgramId: String(rewardProgramId),
  })

  if (rewardProgramQuery.isPending || rewardsByProgramIdQuery.isPending) {
    return <ActivityIndicator />
  }

  if (
    rewardProgramQuery.isError ||
    rewardsByProgramIdQuery.isError ||
    (!rewardProgramQuery.data.corporateAccount &&
      !rewardProgramQuery.data.licensedEstablishment &&
      rewardProgramQuery.data.type !== 'Global')
  ) {
    return <p>An error occurred.</p>
  }

  const getParent = (
    type:
      | 'Global'
      | 'CorporateAccount'
      | 'LicensedEstablishment'
      | 'Organization'
      | undefined
  ) => {
    if (type === 'Global') {
      return <></>
    }

    const rewardProgram = rewardProgramQuery.data
    const parentType =
      type === 'CorporateAccount'
        ? 'Corporate Account'
        : 'Licensed Establishment'
    const parentName =
      type === 'CorporateAccount'
        ? rewardProgram.corporateAccount?.name
        : rewardProgram.licensedEstablishment?.name

    return (
      <>
        <Typography variant="h3">{parentType}</Typography>
        <div className="flex flex-col border rounded pt-2 px-4 min-h-[118px] mt-5 mb-5 space-y-4 sm:space-y-0">
          <div className="flex flex-col min-h-[118px] justify-center space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-4 space-y-4 sm:space-y-0">
              <div>
                <Typography variant="body-3">{`${parentType} Name`}</Typography>
                <Typography variant="body-1">{parentName}</Typography>
              </div>
              {type === 'LicensedEstablishment' && (
                <div>
                  <Typography variant="body-3">License Number</Typography>
                  <Typography variant="body-1">
                    #
                    {
                      rewardProgramQuery.data.licensedEstablishment
                        ?.licenseNumber
                    }
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/RewardsProgram/${Number(rewardProgramQuery.data.id)}`}
        />
      }
    >
      {getParent(rewardProgramQuery.data.type)}

      <Box className="flex flex-col w-full space-y-6 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center pt-4">
        <Typography variant="h3">Rewards Offered</Typography>
      </Box>

      <div className="grid grid-cols-1 gap-5 space-y-1 pt-2 pb-2 ">
        {isEmpty(rewardProgramQuery.data.rewardsCatalogs) ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor={colorPrimitives.lightGray}
            padding={{ xs: 7 }}
            marginTop={{ xs: 2 }}
          >
            <Typography
              variant="label-form"
              marginTop={{ xs: 3 }}
              color="rgba(41, 43, 38, 0.60)"
            >
              No rewards offered
            </Typography>
          </Box>
        ) : (
          <div className="py-1">
            <Box>
              <DataTable
                slotProps={{
                  row: {
                    style: { cursor: 'default' },
                  },
                }}
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'inherit',
                  },
                }}
                columns={getColumns()}
                rows={_.orderBy(rewardsByProgramIdQuery.data, 'reward.name')}
              />
            </Box>
          </div>
        )}
      </div>
    </Page>
  )
}
