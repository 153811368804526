import { type components } from 'api/playerPayback/api'
import { useApiQuery } from 'hooks/useApiQuery'

const meRoute = '/me'
export type GetMeData = components['schemas']['MeResponse']

export const useGetMe = () => {
  return useApiQuery({
    path: meRoute,
    method: 'get',
    queryKey: [meRoute],
    queryArgs: {},
  })
}
