import { Box, Typography } from '@mui/material'
import BackIcon from 'assets/arrow-left-red.svg'

export const SectionsHeader = ({
  title,
  changeSection,
  previousSection,
}: {
  title: string
  changeSection: (section: string) => void
  previousSection: string
}) => {
  return (
    <Box className="flex pb-8">
      <img
        width={32}
        height={32}
        src={BackIcon}
        onClick={() => {
          changeSection(previousSection)
        }}
      />

      <Box className="w-11/12">
        <Typography variant="h1" textAlign="center">
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
