import { Box, Typography, type SxProps, type Theme } from '@mui/material'

export const SectionHeaderButton = ({
  titleText,
  sx,
}: {
  titleText: string
  sx?: SxProps<Theme>
}) => {
  return (
    <Box display="flex" justifyContent="space-between" sx={sx}>
      <Box className="pt-3">
        <Typography variant="h3">{titleText}</Typography>
      </Box>
    </Box>
  )
}
