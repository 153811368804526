import { Box, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { DataTable } from 'components/Shared/DataTable'
import { Colors } from 'components/Theme'
import { type GridColDef } from '@mui/x-data-grid'
import { useGetMe } from 'hooks/api/useGetMe'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import _, { flatten, some, uniq } from 'lodash'
import { useGetMultipleRewardsByLicensedEstablishmentId } from 'hooks/api/useGetMultipleRewardsByLicensedEstablishmentId'
import { useGetMultipleRewardsByCorporateAccountId } from 'hooks/api/useGetMultipleRewardsByCorporateAccountId'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { PageHeader } from 'components/Shared/PageHeader'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'

export const RewardsPage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const navigate = useNavigate()

  const leRewardsQuery = useGetMultipleRewardsByLicensedEstablishmentId({
    licensedEstablishmentIds:
      meQuery?.data?.licensedEstablishments
        ?.filter((x) => x.corporateAccountId === selectedCorporateAccountId)
        .map((x) => x.id) ?? [],
  })

  const caRewardsQuery = useGetMultipleRewardsByCorporateAccountId({
    corporateAccountIds:
      meQuery?.data?.user?.licensedEstablishmentUserCorporateAccounts
        ?.filter(
          (x) =>
            x.allowed && x.corporateAccountId === selectedCorporateAccountId
        )
        .map((x) => x.corporateAccountId) ?? [],
  })

  const rewards = useMemo(() => {
    const leRewards = uniq(flatten(leRewardsQuery.map((x) => x.data)))
    const caRewards = uniq(flatten(caRewardsQuery.map((x) => x.data)))

    const corporateRewards = caRewards.filter(
      (reward) => reward?.type === 'CorporateAccount'
    )
    const locationRewards = leRewards.filter(
      (reward) => reward?.type === 'LicensedEstablishment'
    )

    return { corporateRewards, locationRewards }
  }, [leRewardsQuery, caRewardsQuery])

  if (
    meQuery.isPending ||
    some(leRewardsQuery, (x) => x.isPending) ||
    some(caRewardsQuery, (x) => x.isPending)
  ) {
    return (
      <Page>
        <ActivityIndicator />
      </Page>
    )
  }

  if (meQuery.isError) {
    return <Typography>Something went wrong</Typography>
  }

  const getColumns = (): GridColDef[] => [
    { field: 'name', headerName: 'Program Name', minWidth: 480, flex: 4 },
    {
      field: 'active',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const active = params.value
        const promoBackgroundColor: Record<string, string> = {
          ACTIVE: Colors.rewardsStatus.background.Active,
          INACTIVE: Colors.rewardsStatus.background.Inactive,
        }

        return (
          <Box
            style={{
              backgroundColor:
                promoBackgroundColor[active ? 'ACTIVE' : 'INACTIVE'],
              color: active
                ? Colors.rewardsStatus.text.Active
                : Colors.rewardsStatus.text.Inactive,
              fontWeight: 'bold',
              borderRadius: '4px',
              paddingTop: '3px',
              paddingBottom: '3px',
              display: 'inline-block',
              minWidth: '84px',
              textAlign: 'center',
            }}
          >
            {active ? 'ACTIVE' : 'INACTIVE'}
          </Box>
        )
      },
    },
  ]
  const leUser = meQuery.data

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Reward Programs"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!meQuery.data?.user?.rewardsPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view reward programs. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          {leUser?.user?.rewardsPermission && (
            <>
              {!isPresent(rewards.corporateRewards) &&
                !isPresent(rewards.locationRewards) && (
                  <Box>
                    <NoDataIndicator
                      noImage
                      noDataMessage="No Reward Programs"
                    />
                  </Box>
                )}
              {isPresent(rewards.corporateRewards) &&
                rewards.corporateRewards.length > 0 && (
                  <Box py={3}>
                    <Typography pb={3} variant="h3">
                      Corporate Reward Programs
                    </Typography>
                    <DataTable
                      columns={getColumns()}
                      rows={_.orderBy(rewards.corporateRewards, 'name')}
                      onRowClick={(row) => {
                        navigate(`/RewardsProgram/${row.id}`)
                      }}
                    />
                  </Box>
                )}

              {isPresent(rewards.locationRewards) &&
                rewards.locationRewards.length > 0 && (
                  <Box py={6}>
                    <Typography pb={3} variant="h3">
                      Location Reward Programs
                    </Typography>
                    <DataTable
                      columns={getColumns()}
                      rows={_.orderBy(rewards.locationRewards, 'name')}
                      onRowClick={(row) => {
                        navigate(`/RewardsProgram/${row.id}`)
                      }}
                    />
                  </Box>
                )}
            </>
          )}
        </>
      )}
    </Page>
  )
}
