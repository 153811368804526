import Box from '@mui/material/Box'
import { type components } from 'api/playerPayback/api'
import { Divider } from '@mui/material'
import { AddressDetailsMobile } from './AddressDetails/AddressDetailsMobile'
import { ScheduleDetails } from './ScheduleDetails/ScheduleDetails'
import { BusinessDetailsMobile } from './BusinessDetails/BusinessDetailsMobile'
import { ContactDetailsMobile } from './ContactDetails/ContactDetailsMobile'

type licensedEstablishmentType =
  components['schemas']['FullLicensedEstablishmentDTO']
interface LicensedEstablishmentDetailsProps {
  licensedEstablishment: licensedEstablishmentType
}

export const LicensedEstablishmentDetailsMobile = ({
  licensedEstablishment,
}: LicensedEstablishmentDetailsProps) => {
  return (
    <Box className={'pt-4'}>
      <BusinessDetailsMobile
        licensedEstablishment={licensedEstablishment}
      ></BusinessDetailsMobile>
      <Divider className="pt-4" />
      <Box className="pt-12">
        <ContactDetailsMobile
          licensedEstablishment={licensedEstablishment}
        ></ContactDetailsMobile>
      </Box>

      <Divider className="pt-3" />
      <Box className="pt-12">
        <AddressDetailsMobile
          licensedEstablishment={licensedEstablishment}
        ></AddressDetailsMobile>
      </Box>

      <Divider className="pt-3" />
      <Box className="pt-12">
        <ScheduleDetails
          licensedEstablishment={licensedEstablishment}
        ></ScheduleDetails>
      </Box>
    </Box>
  )
}
