import { Box, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'

export const footerMaxHeight = 150

interface FooterProps {
  children: React.ReactNode
  navigationBarWidth: number
}
export const Footer = ({ children, navigationBarWidth }: FooterProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        p: '16px',
        borderTop: 1,
        borderTopColor: grey[300],
        position: 'fixed',
        left: { xs: 0, sm: 0, md: 0, lg: `${navigationBarWidth}px` },
        bottom: 0,
        right: 0,
        maxHeight: `${footerMaxHeight}px`,
      }}
    >
      {children}
    </Box>
  )
}
