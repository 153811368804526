import { useApiQuery } from 'hooks/useApiQuery'

interface UseGetRewardProgramByIdParams {
  rewardProgramId?: string
}

export const useGetRewardProgramById = ({
  rewardProgramId,
}: UseGetRewardProgramByIdParams) => {
  return useApiQuery({
    path: '/le-connect/rewards-programs/{id}',
    method: 'get',
    queryKey: ['/le-connect/rewards-programs/', rewardProgramId],
    queryArgs: {
      id: Number(rewardProgramId),
    },
  })
}
