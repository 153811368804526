export const white = '#ffffff'

export const colorPrimitives = {
  lightGray: '#f7f7f6',
  borderGray: '#292b261a',
  regalBlue: '#19475A',
  yellow: '#F9D578',
  redGaming: '#CC2027',
  lightRed: '#f9e4e5',
  black: '#2C2C27',
  darkGray: '#2C2C27AD',
  darkGreen: '#1B5E20',
  lightGreen: '#009688',
  orange: '#E56A2B',
  darkBlue: '#4527A0',
  magentaPink: '#880E4F',
  darkCyan: '#009688',
  lightPink: '#F06292',
  lightCyanBlue: '#4B96F7',
  darkRed: '#B62124',
  successGreen: '#2e7d32',
  red5F: '#5F2120',
  redFD: '#FDEDED',
  white,
}

export const chartColors = [
  colorPrimitives.redGaming,
  colorPrimitives.yellow,
  colorPrimitives.regalBlue,
  colorPrimitives.darkGreen,
  colorPrimitives.orange,
  colorPrimitives.darkBlue,
  colorPrimitives.magentaPink,
  colorPrimitives.darkCyan,
  colorPrimitives.lightPink,
  colorPrimitives.lightCyanBlue,
]

export const Colors = {
  sweepstakesStatus: {
    background: {
      Active: '#C1EBC2',
      Pending: '#F9D578',

      Draft: 'rgb(235, 235, 235)',
      Enrollable: 'rgb(243, 214, 133)',
      EnrollmentClosed: 'rgb(235, 235, 235)',
      Live: 'rgb(201, 233, 197)',
      InternalValidation: 'rgba(249, 213, 120, 1)',
      WinnerValidation: 'rgb(243, 214, 133)',
      Closed: 'rgb(235, 235, 235)',
    },
    text: {
      Active: '#1B5E20',
      Pending: '#663C00',
      Draft: 'rgb(35, 35, 35)',
      Enrollable: 'rgb(96, 62, 17)',
      EnrollmentClosed: 'rgb(35, 35, 35)',
      Live: 'rgb(42, 82, 35)',
      InternalValidation: 'rgba(102, 60, 0, 1)',
      WinnerValidation: 'rgb(96, 62, 17)',
      Closed: 'rgb(35, 35, 35)',
    },
  },
  sweepstakesWinnerStatus: {
    background: {
      LocationValidation: colorPrimitives.borderGray,
      RedrawRequest: '#CC20271F',
      WinnerValidation: '#F9D578',
      WinnerNotified: colorPrimitives.borderGray,
      AffidavitReceived: '#09749E1F',
      Awarded: colorPrimitives.borderGray,
      PrizeClaim: colorPrimitives.borderGray,
      NoWinner: colorPrimitives.borderGray,
      GrandPrizeValidation: colorPrimitives.borderGray,
    },
    text: {
      LocationValidation: 'black',
      RedrawRequest: '#CC2027',
      WinnerValidation: '#663C00',
      WinnerNotified: 'black',
      AffidavitReceived: '#1A4F64',
      Awarded: 'black',
      PrizeClaim: 'black',
      NoWinner: 'black',
      GrandPrizeValidation: 'black',
    },
  },
  promotionsStatus: {
    background: {
      Active: '#C1EBC2',
      Scheduled: '#bbdffc',
      Expired: '#ebebeb',
    },
    text: '#034463',
  },
  rewardsStatus: {
    background: {
      Active: '#C1EBC2',
      Inactive: '#EBEBEB',
    },
    text: { Active: '#1e4620', Inactive: colorPrimitives.black },
  },
  muiLabels: {
    placeholder: '#292B2699',
  },
}
