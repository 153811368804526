import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import DartBackgroundImage from 'assets/DartBackground.png'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { PatronTierIcon, type TierOption } from './PatronTierIcon'
import RefreshIcon from '@mui/icons-material/Refresh'
import { type UseQueryResult } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { colorPrimitives } from 'components/Theme'
import { useSnackbar } from 'stores/useSnackbar'

export const PatronProfileHeader = ({
  name,
  checkedInTime,
  tier,
  patronCheckInQuery,
}: {
  name: string
  checkedInTime?: string
  tier: TierOption | undefined
  patronCheckInQuery: UseQueryResult
}) => {
  const tierOption = tier as TierOption
  const { setMessage } = useSnackbar()

  const handleRefresh = async () => {
    try {
      await patronCheckInQuery.refetch()
      setMessage('Successfully refreshed profile.', 'success')
    } catch (error) {
      setMessage('There was an error refreshing the profile.', 'error')
    }
  }

  return (
    <Box
      className="flex flex-col w-full bg-cover bg-center bg-no-repeat max-width-1280px"
      pt={'70px'}
      px={'40px'}
      height={'400px'}
      sx={{
        backgroundImage: 'url(' + DartBackgroundImage + ')',
      }}
    >
      <Box>
        <Link to={'/LiveAtLocation'} id="back-link">
          <Box
            display={'flex'}
            flexDirection="row"
            alignItems="center"
            gap={1}
            pl={2}
            mb={3}
            color="text.secondary"
          >
            <ArrowBackIcon style={{ color: 'white', fontSize: '15px' }} />
            <Typography pt={0.5} fontWeight={'bold'} color={'white'}>
              Back to Live at Location Dashboard
            </Typography>
          </Box>
        </Link>
      </Box>
      <Grid
        container
        className="flex flex-row flex-wrap"
        pl={'21px'}
        alignItems="center"
      >
        {!isNil(tierOption) && (
          <Grid item>
            <Box
              width={'100px'}
              height={'100px'}
              style={{
                backgroundColor: colorPrimitives.redGaming,
                borderRadius: '5px',
              }}
              className="flex items-center flex-col justify-center"
            >
              {PatronTierIcon({ tier: tierOption })}
              <Typography pt={1} variant="body-1" color={'white'}>
                {tier}
              </Typography>
            </Box>
          </Grid>
        )}

        <Grid item>
          <Box ml={2}>
            <Typography variant="h1" color={'white'}>
              {name}
            </Typography>
            <Box display="flex" alignItems="center">
              {checkedInTime && (
                <>
                  <TaskAltIcon style={{ color: '#69F0AE', fontSize: '15px' }} />
                  <Typography
                    pl={1}
                    variant="body-3"
                    color={'white'}
                    style={{ opacity: 0.85 }}
                  >
                    {`Checked-in: ${checkedInTime}`}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid className="pr-4" item xs>
          {name && (
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleRefresh}>
                <RefreshIcon style={{ color: 'white' }} />
                <Typography color={'white'} sx={{ ml: 1 }}>
                  Refresh Profile
                </Typography>
              </IconButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
