import { Box, Checkbox, Stack, Typography } from '@mui/material'

export const CheckboxInput = ({
  checked,
  onClick,
  text,
}: {
  checked: boolean
  onClick: () => void
  text: string
}) => {
  return (
    <Stack alignItems="center" direction="row" paddingBottom={1}>
      <Checkbox
        checked={checked}
        onClick={onClick}
        style={{ padding: 1, marginRight: 8 }}
      />
      <Box onClick={onClick} className="cursor-pointer mt-0.5">
        <Typography variant="body-1">{text}</Typography>
      </Box>
    </Stack>
  )
}
