import { type GridColDef } from '@mui/x-data-grid'
import { formatDateTime } from 'utils/util'

export const RedemptionColumns: GridColDef[] = [
  {
    field: 'patronId',
    headerName: 'Patron Id',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.patronId,
    valueFormatter: ({ value }) => `#${value}`,
  },
  {
    field: 'rewardItemId',
    headerName: 'Reward Item Id',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.rewardItemId,
    valueFormatter: ({ value }) => `#${value}`,
  },
  {
    field: 'rewardItemName',
    headerName: 'Reward Item Name',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.rewardItemName,
  },
  {
    field: 'licensedEstablishmentId',
    headerName: 'Licensed Establishment Id',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.licensedEstablishmentId,
    valueFormatter: ({ value }) => `#${value}`,
  },
  {
    field: 'licensedEstablishmentName',
    headerName: 'Licensed Establishment Name',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.licensedEstablishmentName,
  },
  {
    field: 'pointCost',
    headerName: 'Points Cost',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.pointCost,
  },
  {
    field: 'timeOfredemption',
    headerName: 'Time of Redemption',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.timeOfredemption,
    valueFormatter: ({ value }) => formatDateTime(value),
  },
  {
    field: 'rewardProgramName',
    headerName: 'Reward Program Name',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.rewardProgramName,
  },
  {
    field: 'rewardProgramId',
    headerName: 'Reward Program Id',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => params.row.rewardProgramId,
  },
]
