import { useApiQuery } from 'hooks/useApiQuery'

export const usePatronCheckInsByLeId = ({
  licensedEstablishmentId,
  refetchOnWindowFocus = true,
}: {
  licensedEstablishmentId?: number
  refetchOnWindowFocus?: boolean
}) =>
  useApiQuery({
    path: '/le-connect/patron/checkins/recent',
    method: 'get',
    queryArgs: { licensedEstablishmentId },
    queryKey: ['get-recent-patron-checkin-by-le-id', licensedEstablishmentId],
    refetchOnWindowFocus,
  })
