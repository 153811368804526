import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'
import { useGetMe } from 'hooks/api/useGetMe'
import { useOutlet, Navigate, useLocation } from 'react-router-dom'
import { isPathAvailable } from 'src/navigation/pathPermissions'
import { useSnackbar } from 'stores/useSnackbar'
import { isPath } from './paths'

export const ProtectedLayout = () => {
  const location = useLocation()
  const leUserQuery = useGetMe()
  const { isPending, loginRequired } = useAccessTokenContext()
  const outlet = useOutlet()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  if (loginRequired) {
    return <Navigate to="/" />
  }

  if (isPending || leUserQuery.isPending) {
    return <ActivityIndicator />
  }

  if (isPath(location.pathname)) {
    if (!isPathAvailable(location.pathname, leUserQuery.data?.user)) {
      setSnackbarMessage('Forbidden', 'error')
      return <Navigate to="/" replace />
    }
  } else {
    setSnackbarMessage('Forbidden', 'error')
    return <Navigate to="/" replace />
  }

  return outlet
}
