import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'
import { type SweepstakeWinner } from 'src/types/api'

interface UsePutSweepstakeWinnerProps {
  onSuccess?: (data: SweepstakeWinner) => void
  onError?: (error: ApiError) => void
}

export interface RequestFields {
  sweepstakeDrawingId: number
  patronId: number
  contestEntryId: number
  redrawReason: string
  id: number
  status: 'RedrawRequest'
}

export const usePutSweepstakeWinner = ({
  onSuccess,
  onError,
}: UsePutSweepstakeWinnerProps) => {
  const queryClient = useQueryClient()
  const putSweepstakeWinner = useTypedApiClient({
    path: '/le-connect/sweepstake-winners/{id}',
    method: 'put',
  })

  return useMutation<SweepstakeWinner, ApiError, RequestFields>({
    mutationFn: async (data) => {
      const { data: putSweepstakeWinnerResult } = await putSweepstakeWinner({
        sweepstakeDrawingId: data.sweepstakeDrawingId,
        patronId: data.patronId,
        contestEntryId: data.contestEntryId,
        redrawReason: data.redrawReason,
        status: data.status,
        id: data.id,
      })
      return putSweepstakeWinnerResult
    },
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data)
      }
      await queryClient.invalidateQueries({
        queryKey: ['/sweepstakes', data.contestEntry?.contestId],
      })
    },
    onError,
  })
}
