import { isNil } from 'lodash'
import { formatDateTimeInUTC } from 'utils/util'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishmentLicense } from 'src/types/api'
import { LicensesContact } from './LicensesContact'

export const LicenseCardLicensedEstablishmentPage = ({
  license,
}: {
  license?: LicensedEstablishmentLicense
}) => {
  const theme = useTheme()
  const colorStatusLicense = isNil(license)
    ? theme.palette.error.main
    : license.licenseStatus === 'UpToDate'
    ? theme.palette.success.main
    : theme.palette.error.main

  const statusTypeLicenseDictionary: Record<string, string> = {
    Gaming: 'Gaming',
    StateLiquor: 'State Liquor',
    LocalLiquor: 'Local Liquor',
  }

  const statusDateExpLicenseDictionary: Record<string, string> = {
    UpToDate: 'Up to date',
    Expired: 'Expired',
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Box
        sx={{
          borderLeft: `3px solid ${colorStatusLicense}`,
          backgroundColor: theme.palette.background.paper,
          py: 1,
          px: 2,
        }}
        className="shadow"
      >
        <Typography
          variant="body-2"
          sx={{
            color: isNil(license)
              ? theme.palette.text.primary
              : colorStatusLicense,
            paddingBottom: 1,
            fontWeight: 'bolder',
          }}
          className="uppercase"
        >
          {isNil(license)
            ? 'No Licenses Found'
            : statusDateExpLicenseDictionary[String(license.licenseStatus)]}
        </Typography>
        <Typography variant={isNil(license) ? 'body-3' : 'subtitle-2'}>
          {isNil(license) ? (
            <LicensesContact />
          ) : (
            statusTypeLicenseDictionary[
              String(license.licensedEstablishmentLicenseType)
            ]
          )}
        </Typography>
        {!isNil(license) && (
          <Typography
            variant="body-2"
            sx={{ color: theme.palette.text.secondary }}
          >
            Expiration Date:
            <Typography
              variant="body-3"
              sx={{ color: colorStatusLicense, mx: '1%' }}
            >
              {formatDateTimeInUTC(license.expirationDate)}
            </Typography>
          </Typography>
        )}
      </Box>
    </Grid>
  )
}
