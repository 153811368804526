import { Box, Grid, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishment } from 'src/types/api'
import { formatPhoneNumber } from 'utils/phoneUtils'

interface BusinessDetailsProps {
  licensedEstablishment: LicensedEstablishment
}

export const BusinessDetailsDesktop = ({
  licensedEstablishment,
}: BusinessDetailsProps) => {
  const theme = useTheme()

  return (
    <Box>
      <Typography variant="h3" pb={2}>
        Business Details
      </Typography>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={13}>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Legal Company Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.name ?? ''}
            </Typography>
            <Typography variant="body2" pb={1} color="text.secondary">
              Establishment Trade Type
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.type ?? ''}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              DBA Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.standardName ?? ''}
            </Typography>
            <Typography variant="body2" pb={1} color="text.secondary">
              License Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.licenseNumber
                ? `#${licensedEstablishment.licenseNumber}`
                : ''}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Short Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.shortName ?? ''}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Corporate Account
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.corporateAccount?.name ?? ''}
            </Typography>
            <Typography variant="body2" pb={1} color="text.secondary">
              Organization
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.organization?.name ?? ''}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Phone Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {formatPhoneNumber(licensedEstablishment.phoneNumber)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
