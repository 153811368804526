import { Button } from '@mui/material'
import { appInsights } from 'utils/appInsights'

export const DevelopHome = () => {
  return (
    <div>
      <h1>Develop Home</h1>
      <Button
        onClick={() => {
          throw new Error('Oops!')
        }}
      >
        Unhandled error
      </Button>
      <Button
        onClick={() => {
          appInsights.trackEvent({ name: 'My Custom Event' })
        }}
      >
        Track event
      </Button>
      <Button
        onClick={() => {
          appInsights.trackException({
            exception: new Error('Tracked exception'),
          })
        }}
      >
        Track exception
      </Button>
    </div>
  )
}
