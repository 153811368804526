import { Box, Typography, Button, useTheme } from '@mui/material'
import { useLayout } from 'hooks/useLayout'

interface EmptyStateHomePageProps {
  onContactClick: () => void
}

export const EmptyStateHomePage = ({
  onContactClick,
}: EmptyStateHomePageProps) => {
  const theme = useTheme()
  const { isMobile } = useLayout()

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '120px',
        width: isMobile ? '95%' : '97%',
        left: 0,
        right: 0,
        margin: 'auto',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: isMobile ? 'stretch' : 'center',
        textAlign: 'left',
        padding: theme.spacing(2),
        backgroundColor: 'white',
        boxShadow: 1,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          marginRight: theme.spacing(2),
          paddingLeft: isMobile ? 0 : theme.spacing(2),
        }}
      >
        <Typography
          variant="h3"
          sx={{ pt: theme.spacing(1), pb: theme.spacing(1) }}
        >
          Please Contact J&J Gaming
        </Typography>
        <Typography variant="body-3" sx={{ pb: theme.spacing(1) }}>
          To obtain access, please contact J&J Gaming for assistance with the
          setup process.
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="error"
        onClick={onContactClick}
        sx={{
          fontSize: '16px',
          marginTop: isMobile ? theme.spacing(2) : 0,
          padding: isMobile ? theme.spacing(1) : theme.spacing(3),
        }}
      >
        Contact J&J Gaming
      </Button>
    </Box>
  )
}
