import { Typography, useTheme } from '@mui/material'

export const LicensesContact = () => {
  const theme = useTheme()
  return (
    <Typography variant="body-3">
      {`Send any uploaded files from Liquor and Gaming license updates to: `}
      <Typography
        component="a"
        href="mailto:jjconnect@jjventures.com"
        variant="body-3"
        sx={{ color: theme.palette.error.main, textDecoration: 'underline' }}
      >
        jjconnect@jjventures.com
      </Typography>
    </Typography>
  )
}
