import _, { isEmpty } from 'lodash'
import NoDataFound from 'assets/noData.svg'
import { useParams } from 'react-router-dom'
import { DataTable } from 'components/Shared/DataTable'
import { Page } from 'components/Page'
import { type GridColDef } from '@mui/x-data-grid'
import { Typography, Box } from '@mui/material'
import { type RewardsCatalogReward } from 'src/types/api.ts'
import { getLastUpdated } from 'utils/util'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardCatalogById } from 'hooks/api/useGetRewardCatalogById'
import { PageHeader } from 'components/Shared/PageHeader'
import { colorPrimitives } from 'components/Theme'

export const RewardCatalogDetailPage = () => {
  const { id: rewardProgramId, rewardCatalogId } = useParams()
  const rewardCatalogQuery = useGetRewardCatalogById({
    rewardsProgramId: Number(rewardProgramId),
    rewardsCatalogId: Number(rewardCatalogId),
  })

  if (rewardCatalogQuery.isPending) {
    return <ActivityIndicator />
  }

  if (rewardCatalogQuery.isError) {
    return <p>An error occurred.</p>
  }

  const getColumns = (): Array<GridColDef<RewardsCatalogReward>> => [
    {
      field: 'name',
      headerName: 'Reward Items',
      valueGetter: (params) => params.row.reward?.name,
      minWidth: 480,
      flex: 2,
    },
    {
      field: 'amount',
      headerName: 'Point Value',
      valueGetter: (params) => `${params.row.amount} Points`,
      minWidth: 100,
      flex: 3,
    },
  ]

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title={`${rewardCatalogQuery.data.name}`}
            subtitle={`${
              rewardCatalogQuery.data.modifiedOn
                ? getLastUpdated(rewardCatalogQuery.data.modifiedOn)
                : ''
            }`}
            backPath={`/RewardsProgram/${rewardProgramId}`}
            backText={`Rewards Program`}
            isSecondary={true}
          />
        </Box>
      }
    >
      <div className="py-1">
        {isEmpty(rewardCatalogQuery.data) ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor={colorPrimitives.lightGray}
            padding={{ xs: 7 }}
            marginTop={{ xs: 2 }}
          >
            <img src={NoDataFound} alt="No data found icon" />
            <Typography
              variant="label-form"
              marginTop={{ xs: 3 }}
              color="rgba(41, 43, 38, 0.60)"
            >
              No data found
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography pb={3} variant="h3">
              Catalog Items
            </Typography>
            <DataTable
              slotProps={{
                row: {
                  style: { cursor: 'default' },
                },
              }}
              sx={{
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: 'inherit',
                },
              }}
              columns={getColumns()}
              rows={_.orderBy(
                rewardCatalogQuery.data.rewardsCatalogRewards,
                'name'
              )}
            />
          </Box>
        )}
      </div>
    </Page>
  )
}
