import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { type LicensedEstablishment } from 'src/types/api'
import { SelectLicensedEstablishment } from '../SelectLicensedEstablishment'
import { endOfDay, format, startOfDay, subDays } from 'date-fns'
import { useGetVgtSnapshotReport } from 'hooks/api/Reports/useGetVgtSnapshotReport'
import { VgtSnapshotChart } from './VgtSnapshotChart'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'

export const VgtSnapshotPage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const endDate = endOfDay(subDays(new Date(), 1))
  const startDate = startOfDay(subDays(endDate, 6))

  const reportQuery = useGetVgtSnapshotReport({
    licensedEstablishmentId: licensedEstablishment?.id,
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  const [displayType, setDisplayType] = useState<'$' | '%'>('$')

  const buttonStyle = (type: '$' | '%') => ({
    maxWidth: '40px',
    maxHeight: '40px',
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '1.8rem',
    backgroundColor: displayType === type ? '#CC2027' : '#e0e0e0',
    color: displayType === type ? 'white' : 'black',
  })

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="VGT Snapshot"
            backText="View Reports"
            backPath="/Reports"
            subtitle={`Last 7 Days: ${formattedStartDate} - ${formattedEndDate}`}
            isSecondary={true}
          />
        </Box>
      }
    >
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <SelectLicensedEstablishment
                onSelectLicensedEstablishment={setLicensedEstablishment}
              />
            </Grid>
            <Grid item sx={{ mt: 5 }}>
              <Button
                variant="contained"
                onClick={() => setDisplayType('$')}
                style={buttonStyle('$')}
              >
                $
              </Button>
            </Grid>
            <Grid item sx={{ mt: 5 }}>
              <Button
                variant="contained"
                onClick={() => setDisplayType('%')}
                style={buttonStyle('%')}
              >
                %
              </Button>
            </Grid>
          </Grid>

          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.metrics.length === 0 ? (
              <Typography variant="h4">
                No data available for the selected location
              </Typography>
            ) : (
              <VgtSnapshotChart
                data={reportQuery.data.metrics}
                displayType={displayType}
              />
            ))}
        </Stack>
      )}
    </Page>
  )
}
