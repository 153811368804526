import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useQuery } from '@tanstack/react-query'

export const useGetEULA = () => {
  const get = useTypedApiClient({
    path: '/setting/eula',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/settings/eula'],
    queryFn: async () => {
      const { data } = await get({})
      return data
    },
  })
}
