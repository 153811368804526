import { Box, Modal as MUIModal, type SxProps, type Theme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const commonSxModalProps: SxProps<Theme> = {
  backgroundColor: 'white',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: '8px',
}

export const Modal = ({
  children,
  isOpen,
  toggleIsOpen,
  showIconClose,
  sx,
}: {
  children: React.ReactNode
  isOpen: boolean
  toggleIsOpen: () => void
  showIconClose?: boolean
  sx?: SxProps<Theme>
}) => {
  return (
    <MUIModal open={isOpen} onClose={toggleIsOpen}>
      <Box sx={sx ? { ...commonSxModalProps, ...sx } : commonSxModalProps}>
        {showIconClose && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'end',
              p: '16px',
            }}
          >
            <Box onClick={toggleIsOpen} sx={{ cursor: 'pointer' }}>
              <CloseIcon />
            </Box>
          </Box>
        )}
        {children}
      </Box>
    </MUIModal>
  )
}
