import { Box, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import { useGetVgtTrends } from 'hooks/api/Reports/useGetVgtTrends'
import { useState } from 'react'
import { type LicensedEstablishment } from 'src/types/api'
import { SelectLicensedEstablishment } from '../SelectLicensedEstablishment'
import { VgtTrendsChart } from './VgtTrendsChart'
import { PageHeader } from 'components/Shared/PageHeader'
import { getReportStartAndEndDates } from 'utils/util'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetMe } from 'hooks/api/useGetMe'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'

export const VgtTrendsPage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const { startDate, endDate } = getReportStartAndEndDates(13, 1)

  const reportQuery = useGetVgtTrends({
    licensedEstablishmentId: licensedEstablishment?.id,
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  interface RawDataItem {
    amount: number
    vgtNumber: string
    date: string
    name: string
    tagNumber: number
  }

  interface TransformedDataItem {
    date: string
    [key: string]: { amount: number; name: string; tagNumber: number } | string
  }

  const transformData = (rawData: RawDataItem[]): TransformedDataItem[] => {
    const groupedByDate: Record<
      string,
      Record<string, { amount: number; name: string; tagNumber: number }>
    > = {}

    rawData.forEach((item) => {
      const formattedDate = format(new Date(item.date), 'yyyy-MM-dd')
      if (!groupedByDate[formattedDate]) {
        groupedByDate[formattedDate] = {}
      }
      groupedByDate[formattedDate][item.vgtNumber] = {
        amount: item.amount,
        name: item.name,
        tagNumber: item.tagNumber,
      }
    })

    return Object.keys(groupedByDate).map((date) => ({
      date,
      ...groupedByDate[date],
    }))
  }

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="VGT Trends"
            backText="View Reports"
            backPath="/Reports"
            subtitle={`Last 13 Weeks: ${formattedStartDate} - ${formattedEndDate}`}
            isSecondary={true}
          />
        </Box>
      }
    >
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          <SelectLicensedEstablishment
            onSelectLicensedEstablishment={setLicensedEstablishment}
          />

          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.metrics.length === 0 ? (
              <Typography variant="h4">
                No data available for the selected location
              </Typography>
            ) : (
              <VgtTrendsChart
                data={transformData(reportQuery.data.metrics)}
                endDate={endDate}
              />
            ))}
        </Stack>
      )}
    </Page>
  )
}
