import { create } from 'zustand'

interface UseSignUpScreenSchemaState {
  isValidatingEmail: boolean
  setValidatingEmail: (x: boolean) => void
  isValidatingPhoneNumber: boolean
  setValidatingPhoneNumber: (x: boolean) => void
}

export const useSignUpPageSchemaState = create<UseSignUpScreenSchemaState>(
  (set) => ({
    isValidatingEmail: false,
    setValidatingEmail(x) {
      set({ isValidatingEmail: x })
    },
    isValidatingPhoneNumber: false,
    setValidatingPhoneNumber(x) {
      set({ isValidatingPhoneNumber: x })
    },
  })
)
