import { useQueries } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetMultipleRewardsByCorporateAccountId = ({
  corporateAccountIds,
}: {
  corporateAccountIds: number[]
}) => {
  const apiCall = useTypedApiClient({
    path: '/le-connect/rewards-programs/corporate-accounts/{id}',
    method: 'get',
  })

  return useQueries({
    queries: corporateAccountIds.map((corporateAccountId) => {
      return {
        queryKey: ['get-rewards-by-ca-id', corporateAccountId],
        queryFn: async () => {
          const { data } = await apiCall({ id: corporateAccountId })
          return data
        },
      }
    }),
  })
}
