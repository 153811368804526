import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface CurrentCorporateAccountState {
  currentCorporateAccountName: string | undefined
  currentCorporateAccountId: number | undefined
  setCurrentCorporateAccount: (accountName: string, accountId: number) => void
}

export const useCurrentCorporateAccountStore =
  create<CurrentCorporateAccountState>()(
    persist(
      (set) => ({
        currentCorporateAccountName: undefined,
        currentCorporateAccountId: undefined,
        setCurrentCorporateAccount: (accountName: string, accountId: number) =>
          set({
            currentCorporateAccountName: accountName,
            currentCorporateAccountId: accountId,
          }),
      }),
      {
        name: 'current-corporate-account',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
