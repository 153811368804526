import { create } from 'zustand'

interface EULAAcceptedDate {
  acceptedDate: string
  setAcceptedDate: (acceptedDate: string) => void
}

export const useEULAAcceptedDate = create<EULAAcceptedDate>((set) => ({
  acceptedDate: '',
  setAcceptedDate: (acceptedDate: any) => {
    set({ acceptedDate })
  },
}))
