import KeyIcon from 'assets/KeyIcon.svg'
import DiamondIcon from 'assets/DiamondIcon.svg'
import StarIcon from 'assets/StarIcon.svg'
import MemberIcon from 'assets/MemberIcon.svg'
import type { components } from 'api/playerPayback/api'

export type TierOption = components['schemas']['TierType']
type iconMapType = {
  [key in TierOption]: string
}

const iconMap: iconMapType = {
  None: '',
  Elite: StarIcon,
  Insider: KeyIcon,
  VIP: DiamondIcon,
  Member: MemberIcon,
}

export const PatronTierIcon = ({
  tier,
  width = '20px',
  height = '20px',
}: {
  tier: TierOption
  width?: string
  height?: string
}) => {
  if (tier === 'None') {
    return <></>
  }
  return <img src={iconMap[tier]} width={width} height={height} />
}
