import { type Sweepstake } from '../../../types/api'
import { SectionHeaderButton } from 'components/Sweepstakes/SectionHeaderButton'
import { Box, Typography } from '@mui/material'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export const SweepstakeParticipatingGuidelineSection = ({
  sweepstake,
}: {
  sweepstake: Sweepstake
}) => {
  const sweepstakeGuideline =
    sweepstake?.sweepstakeData?.participationGuidelines

  return (
    <>
      <SectionHeaderButton titleText="Details"></SectionHeaderButton>
      <Box
        sx={{
          p: '16px',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          ul: { paddingLeft: '24px' },
          mt: '15px',
          mb: '40px',
        }}
      >
        <Typography variant="body-2" color="text.secondary">
          Description for Locations to Enroll
        </Typography>

        <Typography variant="body-1" color="text.primary">
          {sweepstakeGuideline ? (
            <Markdown rehypePlugins={[rehypeRaw]}>
              {sweepstakeGuideline.replace(/\n\n/gi, '&nbsp;\n\n')}
            </Markdown>
          ) : (
            '-'
          )}
        </Typography>
      </Box>
    </>
  )
}
