import { Tab } from '@mui/material'
import { HeaderStyledTabs } from 'components/Shared/Tabs/HeaderStyledTabs'
import { Link } from 'react-router-dom'
export const SweepstakesDetailsTabs = ({
  currentTab,
  sweepstakeId,
}: {
  currentTab: any
  sweepstakeId: number
}) => {
  return (
    <HeaderStyledTabs currentTab={currentTab}>
      <Tab
        label="Overview"
        value={`/Sweepstakes/${sweepstakeId}/Overview`}
        to={`/Sweepstakes/${sweepstakeId}/Overview`}
        component={Link}
      />
      <Tab
        label="My Participating Locations"
        value={`/Sweepstakes/${sweepstakeId}/ParticipatingLocations`}
        to={`/Sweepstakes/${sweepstakeId}/ParticipatingLocations`}
        component={Link}
      />
      <Tab
        label="My Winners"
        value={`/Sweepstakes/${sweepstakeId}/Winners`}
        to={`/Sweepstakes/${sweepstakeId}/Winners`}
        component={Link}
      />
    </HeaderStyledTabs>
  )
}
