import { useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type FetchReturnType, type ApiError } from 'openapi-typescript-fetch'

export const usePutEULAAcceptedDate = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: (err: ApiError) => void
}) => {
  const putEULAAcceptedDate = useTypedApiClient({
    method: 'put',
    path: '/me/EULAAccepted',
  })

  return useMutation<FetchReturnType<typeof putEULAAcceptedDate>, ApiError>({
    mutationFn: async () => {
      const { data: putEULAAcceptedResult } = await putEULAAcceptedDate({})
      return putEULAAcceptedResult
    },
    onSuccess,
    onError,
  })
}
