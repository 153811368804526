import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { isNil } from 'lodash'
import { type LicensedEstablishment } from 'src/types/api'

const getAllRoute = '/le-connect/licensed-establishments/{id}'

export const useGetLicensedEstablishmentById = (
  licensedEstablishmentId: number
): UseQueryResult<LicensedEstablishment> => {
  const get = useTypedApiClient({
    path: getAllRoute,
    method: 'get',
  })
  return useQuery({
    queryKey: [getAllRoute, licensedEstablishmentId],
    queryFn: async () => {
      const { data } = await get({ id: licensedEstablishmentId })
      return data
    },
    enabled: !isNil(licensedEstablishmentId),
  })
}
