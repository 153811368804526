import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import {
  Box,
  Button,
  capitalize,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ActivityButton } from 'components/ActivityButton'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { colorPrimitives } from 'components/Theme'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import Markdown from 'react-markdown'
import { useNavigate, useParams } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'
import { useSweepstakeParticipatingLocationsAndEnrollmentAgreement } from 'stores/useSweepstakeParticipatingLocationsAndEnrollmentAgreement'
import { bool, object, string } from 'yup'
import { useEffect } from 'react'
import {
  FormFieldMessage,
  FormFieldMessageVariant,
} from 'components/FormFieldMessage/FormFieldMessage'

export const AdditionalEnrollmentDetailsSchema = object({
  signageOne: string().required(),
  signageTwo: string().required(),
  notes: string(),
  email: string().when('usePrimaryContact', {
    is: (usePrimaryContact: boolean) => !usePrimaryContact,
    then: (schema) =>
      schema.required('Must enter a valid email.').test({
        name: 'ValidEmail',
        exclusive: false,
        message: 'Must enter a valid email.',
        test: (value) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  usePrimaryContact: bool(),
  agreement: bool().required().isTrue('Agreement is required.'),
})

export const SweepstakesAdditionalEnrollmentDetailsPage = () => {
  const { id: idParam } = useParams()
  const sweepstakeId = Number(idParam)
  const navigate = useNavigate()
  const { setEnrollmentDetails, participatingLocations, enrollmentDetails } =
    useSweepstakeParticipatingLocationsAndEnrollmentAgreement()
  const sweepstakeQuery = useGetSweepstakeById({ sweepstakeId })

  const formMethods = useForm({
    resolver: yupResolver(AdditionalEnrollmentDetailsSchema),
    mode: 'all',
    defaultValues: {
      signageOne:
        enrollmentDetails === undefined ? '' : enrollmentDetails.signageOne,
      signageTwo:
        enrollmentDetails === undefined ? '' : enrollmentDetails.signageTwo,
      notes: enrollmentDetails === undefined ? '' : enrollmentDetails.notes,
      email: enrollmentDetails === undefined ? '' : enrollmentDetails.email,
      usePrimaryContact:
        enrollmentDetails === undefined
          ? false
          : enrollmentDetails.usePrimaryContact,
    },
  })

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = formMethods

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (sweepstakeQuery.isPending) {
    return <ActivityIndicator />
  }

  const sweepstake = sweepstakeQuery.data
  const previouslyEnrolledLesCount =
    sweepstake?.licensedEstablishments?.length ?? 0
  const numOfLes =
    (participatingLocations?.length ?? 0) - previouslyEnrolledLesCount

  const sweepstakeGuideline =
    sweepstake?.sweepstakeData?.participationGuidelines

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(async (data) => {
          const { signageOne, signageTwo, email, notes, usePrimaryContact } =
            data
          setEnrollmentDetails({
            email: email ?? '',
            notes: notes ?? '',
            usePrimaryContact: usePrimaryContact ?? false,
            signageOne,
            signageTwo,
          })
          navigate(`/Sweepstakes/${sweepstakeId}/ReviewLocationsToEnroll`)
        })}
        noValidate
      >
        <Page
          header={
            <>
              <PageHeader
                title="Additional Enrollment Details"
                isSecondary={true}
                backText="Enroll Locations List"
                backPath={`/Sweepstakes/${sweepstakeId}/EnrollLocations`}
              >
                <Typography
                  variant="body-1"
                  color="rgba(41, 43, 38, 0.60)"
                  paddingTop="12px"
                  paddingBottom="24px"
                >{`Read through and fill out this form to opt in to the sweepstakes.`}</Typography>
              </PageHeader>
              <Divider />
            </>
          }
          footer={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              columnGap={2}
            >
              <Box width={'100%'} alignContent={'center'}>
                <Typography variant="body-1" color={'text.secondary'}>
                  {numOfLes ?? 0} Licensed Establishment
                  {numOfLes && numOfLes > 1 ? 's ' : ' '}
                  Selected to Enroll in Sweepstakes
                </Typography>
              </Box>
              <Button
                variant="text"
                onClick={() => {
                  setEnrollmentDetails(undefined)
                  navigate(
                    `/Sweepstakes/${sweepstakeId}/ParticipatingLocations`
                  )
                }}
              >
                Cancel
              </Button>
              <ActivityButton
                active={false}
                variant="contained"
                disabled={!isValid}
                type="submit"
              >
                Review
              </ActivityButton>
            </Box>
          }
        >
          <Box>
            <Typography variant="h3">Details</Typography>

            <Box
              sx={{
                p: '16px',
                borderWidth: 1,
                borderColor: 'grey.300',
                borderRadius: 2,
                ul: { paddingLeft: '24px' },
                marginTop: '8px',
              }}
            >
              <Typography variant="body-1" color="text.primary">
                {sweepstakeGuideline ? (
                  <Markdown rehypePlugins={[rehypeRaw]}>
                    {sweepstakeGuideline.replace(/\n\n/gi, '&nbsp;\n\n')}
                  </Markdown>
                ) : (
                  '-'
                )}
              </Typography>
            </Box>
          </Box>

          <Stack className="pt-10" spacing={'20px'}>
            <Typography variant="h3">
              Sweepstakes Signage{' '}
              <sup style={{ color: colorPrimitives.redGaming }}>&#42;</sup>
            </Typography>
            <Box>
              <Typography variant="h4">Decal</Typography>
              <Controller
                render={({ field }) => (
                  <RadioGroup
                    aria-label="type"
                    {...field}
                    onChange={(e) => {
                      setValue('signageOne', `${e.target.value}`)
                    }}
                  >
                    {[true, false].map((type) => (
                      <FormControlLabel
                        key={type ? 'Yes' : 'No'}
                        value={type}
                        control={<Radio />}
                        label={type ? 'Yes' : 'No'}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="signageOne"
                control={control}
              />
            </Box>

            <Box>
              <Typography variant="h4">Banner</Typography>

              <Controller
                render={({ field }) => (
                  <RadioGroup
                    aria-label="type"
                    {...field}
                    onChange={(e) => {
                      setValue('signageTwo', `${e.target.value}`)
                    }}
                  >
                    {[true, false].map((type) => (
                      <FormControlLabel
                        key={type ? 'Yes' : 'No'}
                        value={type}
                        control={<Radio />}
                        label={type ? 'Yes' : 'No'}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="signageTwo"
                control={control}
              />
            </Box>
          </Stack>

          <Stack className="pt-10">
            <Typography variant="h3">
              Email Address{' '}
              <sup style={{ color: colorPrimitives.redGaming }}>&#42;</sup>
            </Typography>
            <Typography
              variant="body-1"
              color={'text.secondary'}
              sx={{ pb: 1 }}
            >
              Winner notification will be sent to the email address provided
            </Typography>
            <TextField
              {...register('email')}
              placeholder="Enter Email Address"
              fullWidth
              disabled={watch('usePrimaryContact')}
            />
            {isPresent(errors.email) &&
              isPresent(errors.email.message) &&
              !watch('usePrimaryContact', false) && (
                <FormFieldMessage
                  message={capitalize(errors.email.message)}
                  variant={FormFieldMessageVariant.Error}
                />
              )}

            <FormControlLabel
              className="pt-2"
              style={{ pointerEvents: 'none' }}
              control={
                <Checkbox
                  {...register('usePrimaryContact', {})}
                  checked={watch('usePrimaryContact') ?? false}
                  onChange={(event) => {
                    setValue('usePrimaryContact', event.target.checked)
                    if (event.target.checked) {
                      setValue('email', '')
                    }
                  }}
                  style={{ pointerEvents: 'auto' }}
                />
              }
              label="Use primary contact associated with LE"
            />
          </Stack>

          <Box className="pt-10">
            <Typography variant="h3" sx={{ pb: 1 }}>
              Notes
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={4}
              {...register('notes')}
              placeholder="Enter optional notes"
            />
          </Box>

          <Box className="pt-10">
            <Typography variant="h3">
              Agreement{' '}
              <sup style={{ color: colorPrimitives.redGaming }}>&#42;</sup>
            </Typography>
            <FormControlLabel
              control={<Checkbox {...register('agreement', {})} />}
              label={
                <Typography variant="body-1" mt={3}>
                  By selecting this check box and submitting this enrollment
                  form represents and warrants to Terminal Operator that (a) the
                  person executing this Enrollment is an authorized signatory of
                  the Video Gaming Location and (b) Video Gaming Location
                  authorizes Terminal Operator to deduct the applicable
                  Participation Fee from Net Terminal Income as set forth above.
                </Typography>
              }
            />
          </Box>
        </Page>
      </form>
    </FormProvider>
  )
}
