import { useApiQuery } from 'hooks/useApiQuery'

interface UseGetFlexPayStatementsByIdParams {
  licensedEstablishmentId: number | undefined
  enabled?: boolean
}

export const useGetFlexPayStatementsByLeId = ({
  licensedEstablishmentId,
  enabled = true,
}: UseGetFlexPayStatementsByIdParams) => {
  return useApiQuery({
    path: '/le-connect/flexpaystatements/{licensedEstablishmentId}',
    method: 'get',
    queryKey: ['flexpaystatements', licensedEstablishmentId],
    queryArgs: {
      licensedEstablishmentId,
    },
    enabled,
  })
}
