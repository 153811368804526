import * as _ from 'lodash'

export type DateString = string // eg "1990-02-28"
export type MeasureName =
  | 'CashIn'
  | 'CreditsWagered'
  | 'CreditsWageredComp'
  | 'NTI'
  | 'LocationNTI'

// Expected SMART API payload
export interface WeeklySnapshotDatum {
  date: string // Trim "2023-11-14T00:00:00"
  measure: MeasureName // eg "CashIn"
  amount: number // eg 3177
}

export type Measures = Record<MeasureName, number>
type DateMeasures = Record<DateString, Measures>

// sum the respective fields of two Measures
export const addMeasures = (a: Measures, b: Measures): Measures => {
  return {
    CashIn: a.CashIn + b.CashIn,
    CreditsWagered: a.CreditsWagered + b.CreditsWagered,
    CreditsWageredComp: a.CreditsWageredComp ?? 0 + b.CreditsWageredComp ?? 0,
    NTI: a.NTI + b.NTI,
    LocationNTI: a.LocationNTI + b.LocationNTI,
  }
}

// divide a Measures by a single divisor
export const divideMeasures = (
  measures: Measures,
  divisor: number
): Measures => {
  return {
    CashIn: measures.CashIn / divisor,
    CreditsWagered: measures.CreditsWagered / divisor,
    CreditsWageredComp: measures.CreditsWageredComp / divisor,
    NTI: measures.NTI / divisor,
    LocationNTI: measures.LocationNTI / divisor,
  }
}

// Consolidate upstream data to DateMeasures type
export const buildMeasures = (data: WeeklySnapshotDatum[]) => {
  return data.reduce<DateMeasures>((m, d) => {
    const dateString = d.date.substring(0, 10) // Trim "2023-11-14T00:00:00" -> "2023-11-14"
    const existingData = m[dateString] ?? {}

    return {
      ...m,
      [dateString]: { ...existingData, [d.measure]: d.amount },
    }
  }, {})
}

// return array of Measure grouped by week, ordered by time DESC
//   ie the last 7 days will be index 0, previous week index 1, etc.
export const groupMeasuresByWeek = (dateMeasures: DateMeasures) => {
  const latestDay = _.max(Object.keys(dateMeasures))

  if (!latestDay) {
    throw new Error('Could not determine the latest day')
  }

  const latestDate = new Date(latestDay)
  const datesByWeek = _.groupBy(Object.keys(dateMeasures), (dateString) => {
    // compute how many weeks ago our date occurred versus today
    const date = new Date(dateString)
    const dayOffset = Math.floor(
      (latestDate.getTime() - date.getTime()) / (86400 * 1000)
    )

    const weekOffset = Math.floor(dayOffset / 7)
    return weekOffset
  })

  const measuresByWeek = Object.keys(datesByWeek).map((index) => {
    return datesByWeek[index].map((dateString) => {
      return {
        date: dateString,
        measures: dateMeasures[dateString],
      }
    })
  })

  return measuresByWeek
}
