import { useApiQuery } from 'hooks/useApiQuery'

interface UseGetRewardCatalogByIdParams {
  rewardsProgramId?: number
  rewardsCatalogId?: number
}

export const useGetRewardCatalogById = ({
  rewardsProgramId,
  rewardsCatalogId,
}: UseGetRewardCatalogByIdParams) => {
  return useApiQuery({
    method: 'get',
    path: '/le-connect/rewards-program-catalogs/{rewardsProgramId}/rewards-catalogs/{rewardsCatalogId}',
    queryKey: [
      '/le-connect/rewards-catalogs/',
      rewardsProgramId,
      rewardsCatalogId,
    ],
    queryArgs: { rewardsProgramId, rewardsCatalogId },
  })
}
