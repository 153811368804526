import { Box } from '@mui/material'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetMe } from 'hooks/api/useGetMe'

export const PromotionsPage = () => {
  const meQuery = useGetMe()
  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Specials & Events"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!meQuery.data?.user?.promotionsPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view specials & events. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <></>
      )}
    </Page>
  )
}
