import { isPresent } from '@jjvgaming/player-payback-library'
import { compact, flatten, some, uniq, uniqBy } from 'lodash'
import { type ContestLE } from 'src/types/api'
import { useGetOrganizationsByIds } from './api/useGetOrganizationsByIds'
import { useMemo } from 'react'

export const useGetParticipatingLocations = ({
  contestLes,
  currentCorporateAccountId,
}: {
  contestLes: ContestLE[] | undefined
  currentCorporateAccountId: number | undefined
}) => {
  const licensedEstablishments = useMemo(() => {
    return contestLes
      ?.map((x) => {
        return {
          ...x.licensedEstablishment,
          signage1: x.signage1,
          signage2: x.signage2,
          dateAdded: x.createdOn,
        }
      })
      .filter((x) => x.corporateAccountId === currentCorporateAccountId)
  }, [contestLes])

  const childOrgAndParentOrgIds = uniq(
    compact(
      flatten(
        (licensedEstablishments ?? []).map((x) => [
          x.organizationId,
          x.organization?.parentOrganizationId,
        ])
      )
    )
  )
  const orgsQuery = useGetOrganizationsByIds({
    ids: childOrgAndParentOrgIds,
    enabled: isPresent(licensedEstablishments),
  })

  return {
    licensedEstablishments,
    corporateAccounts: compact(
      uniqBy(licensedEstablishments?.map((x) => x.corporateAccount), 'id')
    ),
    organizations: compact(uniqBy(orgsQuery?.map((x) => x.data), 'id')),
    isPending: some(orgsQuery, (x) => x.isPending),
  }
}
