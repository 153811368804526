import { Box, type Theme, type SxProps } from '@mui/material'

const commonSxModalProps: SxProps<Theme> = {
  width: '100%',
  borderTop: '1px solid #e5e7eb',
}

export const ModalFullFooter = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Box sx={commonSxModalProps}>
      <Box className="flex flex-row justify-end px-4 md:px-8 sm:px-4 py-4 gap-2">
        {children}
      </Box>
    </Box>
  )
}
