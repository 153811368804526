import { colorPrimitives } from 'components/Theme'
import { useLayout } from 'hooks/useLayout'
import type React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  type TooltipProps,
} from 'recharts'

interface DataItem {
  name: string
  tagNumber: number
  vgtNumber: string
  cashIn: number
  nti: number
  creditsWagered: number
  cashInPercentage: number
  ntiPercentage: number
  creditsWageredPercentage: number
}

interface VgtSnapshotChartProps {
  data: DataItem[]
  displayType: '$' | '%'
}

export const VgtSnapshotChart: React.FC<VgtSnapshotChartProps> = ({
  data,
  displayType,
}) => {
  const isMobile = useLayout()
  // Create a mapping of vgtNumber to DataItem
  const vgtNumberMapping = data.reduce<Record<string, DataItem>>(
    (acc, item) => {
      acc[item.vgtNumber] = item
      return acc
    },
    {}
  )

  const yAxisFormatter = (value: number) => {
    if (displayType === '$') {
      // Format as currency
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(value)
    } else {
      // Format as percentage
      return `${value}%`
    }
  }

  const yAxisLabel = displayType === '$' ? 'Amount' : 'Percentage'

  interface CustomizedAxisTickProps {
    x: number
    y: number
    payload: {
      value: string
    }
    vgtNumberMapping: Record<string, DataItem>
  }

  const CustomizedAxisTick: React.FC<CustomizedAxisTickProps> = ({
    x,
    y,
    payload,
    vgtNumberMapping,
  }) => {
    const item = vgtNumberMapping[payload.value]
    const xAxisLabel = isMobile.isMobile
      ? Number(payload.value.replace(/^VGT\s*/, '')).toString()
      : payload.value

    return (
      <g transform={`translate(${x - 10},${y + 15})`}>
        <text
          x={0}
          y={0}
          textAnchor="middle"
          fill={colorPrimitives.black}
          fontWeight="bold"
          fontSize={16}
        >
          {xAxisLabel}
        </text>
        {!isMobile.isMobile && (
          <text
            x={0}
            y={20}
            textAnchor="middle"
            fill={colorPrimitives.black}
            fontSize={14}
          >
            Tag # {item.tagNumber}
          </text>
        )}
      </g>
    )
  }

  interface CustomTooltipProps extends TooltipProps<number, string> {
    data: DataItem[]
    displayType: '$' | '%'
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
    data,
    displayType,
  }) => {
    if (active && payload?.length) {
      const dataItem = data.find((item) => item.vgtNumber === label)

      const formatValue = (value: number) => {
        if (displayType === '$') {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })
        } else {
          return `${value}%`
        }
      }

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            textAlign: 'center', // Centers the text horizontally
          }}
        >
          <p>
            <strong>VGT: {dataItem?.vgtNumber}</strong>
          </p>
          <p>Tag: {dataItem?.tagNumber}</p>
          <p>Name: {dataItem?.name}</p>
          <p>&nbsp;</p> {/* Empty line */}
          {payload.map((entry, index) => (
            <p key={index}>{`${entry.name}: ${formatValue(
              entry.value ?? 0
            )}`}</p>
          ))}
        </div>
      )
    }

    return null
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="vgtNumber"
          interval={0}
          label={{ fill: colorPrimitives.black }}
          height={60}
          scale="auto"
          tick={(props) => (
            <CustomizedAxisTick
              {...props}
              vgtNumberMapping={vgtNumberMapping}
            />
          )}
        />
        <YAxis
          tickFormatter={yAxisFormatter}
          tickCount={12}
          style={{ fill: colorPrimitives.black }}
          label={{
            value: yAxisLabel,
            angle: -90,
            textAnchor: 'middle',
            dx: -45,
            fill: colorPrimitives.black,
          }}
          width={80}
        />
        <Tooltip
          content={<CustomTooltip data={data} displayType={displayType} />}
        />
        <Legend
          align="right"
          verticalAlign="top"
          layout="horizontal"
          wrapperStyle={{
            fontSize: isMobile ? '16px' : '18px',
            top: '0px',
          }}
          formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
        />
        {displayType === '$' ? (
          <>
            <Bar
              dataKey="cashIn"
              fill={colorPrimitives.redGaming}
              name="Cash In"
            />
            <Bar dataKey="nti" fill={colorPrimitives.yellow} name="NTI" />
            <Bar
              dataKey="creditsWagered"
              fill={colorPrimitives.regalBlue}
              name="Credits Wagered"
            />
          </>
        ) : (
          <>
            <Bar
              dataKey="cashInPercentage"
              fill={colorPrimitives.redGaming}
              name="Cash In (%)"
            />
            <Bar
              dataKey="ntiPercentage"
              fill={colorPrimitives.yellow}
              name="NTI (%)"
            />
            <Bar
              dataKey="creditsWageredPercentage"
              fill={colorPrimitives.regalBlue}
              name="Credits Wagered (%)"
            />
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}
