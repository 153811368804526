import { useApiQuery } from 'hooks/useApiQuery'

export const useGetRewardProgramActivityLog = ({
  rewardsProgramId,
  numOfDays,
}: {
  rewardsProgramId: number
  numOfDays: number
}) => {
  return useApiQuery({
    method: 'get',
    path: '/le-connect/activity-logs/attendants/rewards-programs/{rewardsProgramId}',
    queryKey: [
      'le-connect/rewards-program-activity-log',
      rewardsProgramId,
      numOfDays,
    ],
    queryArgs: { rewardsProgramId, numOfDays },
  })
}
