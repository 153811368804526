import { Page } from 'components/Page'
import { Stack } from '@mui/material'
import { DashBoardHeader } from './DashBoard/DashBoardHeader'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { LicensesLicensedEstablishmentPage } from './LicensesLicensedEstablishment/LicensesLicensedEstablishmentPage'
import { useGetMe } from 'hooks/api/useGetMe'
import { EmptyStateHomePage } from './EmptyStateHomePage'
import { colorPrimitives } from 'components/Theme'
import { useGetEULA } from 'hooks/api/Settings/useGetEULA'
import { useEffect, useState } from 'react'
import { usePutEULAAcceptedDate } from 'hooks/api/Settings/usePutEULAAcceptedDate'
import { EULADialog } from 'pages/Login/CreateAccount/EULADialog'

export const HomePage = () => {
  const meQuery = useGetMe()
  const { currentCorporateAccountId, currentCorporateAccountName } =
    useCurrentCorporateAccountStore()
  const hasNoAccess =
    !meQuery.data?.corporateAccounts ||
    meQuery.data?.corporateAccounts.length === 0

  const handleContactClick = () => {
    const url = 'https://www.jjventures.com/contact/'
    window.open(url, '_blank')
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  const saveEULAAcceptedDate = () => {
    mutation.mutate()
    toggleModalIsOpen()
  }

  const mutation = usePutEULAAcceptedDate({
    onError: () => {},
    onSuccess: () => {},
  })

  const settingsEULAQuery = useGetEULA()
  const settingEULA = settingsEULAQuery.data

  const eulaLastModifiedDateTime = settingEULA?.lastModified
  const eulaAcceptedDateTime = meQuery.data?.user?.eulaAccepted

  let isEULAUpdated = false

  useEffect(() => {
    if (eulaLastModifiedDateTime !== undefined) {
      if (eulaAcceptedDateTime !== null && eulaAcceptedDateTime !== undefined) {
        isEULAUpdated = eulaAcceptedDateTime < eulaLastModifiedDateTime
        console.log(isEULAUpdated)
      }

      if (
        eulaAcceptedDateTime === null ||
        eulaAcceptedDateTime === undefined ||
        eulaAcceptedDateTime < eulaLastModifiedDateTime
      ) {
        toggleModalIsOpen()
      }
    }
  }, [eulaLastModifiedDateTime])

  return (
    <Page
      header={
        <DashBoardHeader
          corporateAccountId={currentCorporateAccountId}
          corporateAccountName={currentCorporateAccountName}
        />
      }
      fitBody={true}
    >
      <Stack
        className="h-screen"
        sx={{ backgroundColor: colorPrimitives.lightGray }}
      >
        {hasNoAccess ? (
          <EmptyStateHomePage onContactClick={handleContactClick} />
        ) : (
          <>
            <LicensesLicensedEstablishmentPage />
          </>
        )}
      </Stack>
      <EULADialog
        showEULAUpdatedLabel={!isEULAUpdated}
        showCancelButton={false}
        isModalOpen={isModalOpen}
        onConfirm={saveEULAAcceptedDate}
        onClose={() => {}}
      ></EULADialog>
    </Page>
  )
}
