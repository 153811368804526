import { Box, Typography } from '@mui/material'
import { TextField } from 'components/TextField/TextField'
import { SectionsHeader } from './SectionsHeader'
import { Controller, useFormContext } from 'react-hook-form'
import type { SignUpFormFields } from './FormUtils/SignUpFormFields'
import { applyPhoneMask, isPresent } from '@jjvgaming/player-payback-library'
import { capitalize } from 'lodash'
import {
  FormFieldMessage,
  FormFieldMessageVariant,
} from 'components/FormFieldMessage/FormFieldMessage'
import ErrorIcon from 'assets/errorIcon.svg'
import { useState } from 'react'
import { useSignUpPageSchemaState } from 'stores/useSignUpPageSchemaStateStore'
import { ActivityButton } from 'components/ActivityButton/ActivityButton'

export const PhoneNumberSection = ({
  changeSection,
}: {
  changeSection: (section: string) => void
}) => {
  const formMethods = useFormContext<SignUpFormFields>()
  const {
    formState: { errors },
    trigger,
    watch,
    setValue,
  } = formMethods
  const [phoneNumber, setPhoneNumber] = useState(watch('phoneNumber'))
  const isValidating = useSignUpPageSchemaState(
    (state) => state.isValidatingPhoneNumber
  )
  const setIsValidating = useSignUpPageSchemaState(
    (state) => state.setValidatingPhoneNumber
  )

  const validateAndNavigateToNextSection = async () => {
    setValue('phoneNumber', phoneNumber)
    setIsValidating(true)
    const validationPassed = await trigger(['phoneNumber'])
    if (validationPassed) {
      changeSection('nameSection')
    }
    setIsValidating(false)
  }

  return (
    <Box className="flex justify-center flex-col w-full">
      <SectionsHeader
        title="Enter Phone Number"
        changeSection={changeSection}
        previousSection="passwordSection"
      />
      <Controller
        name="phoneNumber"
        render={() => (
          <TextField
            name="phoneNumber"
            placeholder="Phone Number"
            type="text"
            value={phoneNumber}
            error={isPresent(errors.phoneNumber)}
            onChange={(e) => {
              const maskedValue = applyPhoneMask(e.target.value)
              setPhoneNumber(maskedValue)
            }}
          />
        )}
      />

      {isPresent(errors.phoneNumber) &&
        isPresent(errors.phoneNumber.message) && (
          <FormFieldMessage
            message={capitalize(errors.phoneNumber.message)}
            variant={FormFieldMessageVariant.Error}
            icon={ErrorIcon}
          />
        )}
      <Box className="py-6">
        <ActivityButton
          sx={{ width: '100%' }}
          variant="contained"
          onClick={async () => {
            await validateAndNavigateToNextSection()
          }}
          active={isValidating}
        >
          <Typography variant="label-CTA">Next</Typography>
        </ActivityButton>
      </Box>
    </Box>
  )
}
