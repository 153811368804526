import { Box, Grid } from '@mui/material'
import { CheckedInPatronsTable } from 'components/CheckedInPatronsTable/CheckedInPatronsTable'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetMe } from 'hooks/api/useGetMe'

export const PatronAccountsPage = () => {
  const meQuery = useGetMe()
  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Patron Accounts"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!meQuery.data?.user?.patronsAccountsPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view patron accounts. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          <Grid container columnGap={24}>
            <Grid item sm={12}>
              <CheckedInPatronsTable />
            </Grid>
          </Grid>
        </>
      )}
    </Page>
  )
}
