import { useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type FetchReturnType, type ApiError } from 'openapi-typescript-fetch'

const route = '/le-connect/patrons/{patronId}/points-ledgers/manual'

export const useManualTransactionPointAward = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: (err: ApiError) => void
}) => {
  const post = useTypedApiClient({ method: 'post', path: route })

  return useMutation<FetchReturnType<typeof post>, ApiError, Variables>({
    mutationFn: async (input) => {
      const { data } = await post({
        patronId: input.patronId,
        amount: input.amount,
        rewardsProgramId: input.rewardProgramId,
        licensedEstablishmentUserId: input.licensedEstablishmentUserId,
      })
      return data
    },
    onSuccess,
    onError,
  })
}

interface Variables {
  patronId: number
  rewardProgramId: number
  amount: number
  licensedEstablishmentUserId?: number
}
