import { Box, Button, Stack, Typography } from '@mui/material'
import DownloadIcon from 'assets/DownloadIcon.svg'
import { DataGrid, useGridApiRef, GridToolbar } from '@mui/x-data-grid'
import { random } from 'lodash'
import { RedemptionColumns } from './RedemptionColumns'
import { useGetRewardRedemptionByRewardProgram } from 'hooks/api/Rewards/useGetRewardRedemptionByRewardProgram'
import DocumentIcon from 'assets/Document.svg'
import { isPresent } from '@jjvgaming/player-payback-library'

export const RedemptionSection = ({
  endDate,
  startDate,
  rewardProgramName,
  rewardProgramId,
}: {
  startDate?: string
  endDate?: string
  rewardProgramName?: string
  rewardProgramId: number
}) => {
  let lastResultLength = 0
  const redemptionQuery = useGetRewardRedemptionByRewardProgram({
    rewardProgramId,
    startTransactionDate: startDate,
    endTransactionDate: endDate,
  })

  const dataTableApiRef = useGridApiRef()
  const exportRewardsRedemptions = () => {
    dataTableApiRef.current.exportDataAsCsv({
      fileName: `${rewardProgramName} - Redemptions From ${startDate} - to ${endDate}`,
      utf8WithBom: true,
    })
  }

  if (redemptionQuery.isError) {
    return <p>An error occurred.</p>
  }

  if (redemptionQuery.isSuccess) {
    lastResultLength = redemptionQuery.data.length
  }

  return (
    <>
      <Stack spacing={'8px'}>
        <Typography variant="subtitle-2">Records</Typography>
        <Stack
          className="border rounded-lg"
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          p={'24px'}
        >
          {isPresent(startDate) && isPresent(endDate) ? (
            <>
              <Stack>
                <Typography variant="body-1" color={'text.secondary'}>
                  Records
                </Typography>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  textAlign={'center'}
                >
                  <img src={DocumentIcon} className="h-[20px]" />
                  <Typography variant="body-1">
                    {`${
                      redemptionQuery.isLoading
                        ? lastResultLength
                        : redemptionQuery.data?.length
                    } redemption records found`}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography variant="body-1" color={'text.secondary'}>
                  Selected Dates
                </Typography>
                <Typography variant="body-1">{`${startDate.split(' ')[0]} - ${
                  endDate.split(' ')[0]
                }`}</Typography>
              </Stack>
            </>
          ) : (
            <Box>
              <Typography>
                Enter the start and end dates to search for records
              </Typography>
            </Box>
          )}
          <Button
            variant="contained"
            startIcon={<img src={DownloadIcon} />}
            onClick={() => {
              exportRewardsRedemptions()
            }}
            disabled={
              redemptionQuery.data?.length === 0 || redemptionQuery.isLoading
            }
          >
            Export
          </Button>
        </Stack>
      </Stack>

      <div className="hidden h-full w-full">
        <DataGrid
          autoHeight
          apiRef={dataTableApiRef}
          columns={RedemptionColumns}
          rows={
            redemptionQuery.data?.map((redemption) => {
              return {
                patronId: redemption.patronId,
                rewardItemId: redemption.rewardsCatalogReward?.rewardId,
                rewardItemName: redemption.rewardsCatalogReward?.reward?.name,
                licensedEstablishmentId:
                  redemption.rewardsProgram?.licensedEstablishmentId,
                licensedEstablishmentName:
                  redemption.rewardsProgram?.licensedEstablishment?.name,
                pointCost: redemption.rewardsCatalogReward?.amount,
                timeOfredemption: redemption.createdOn,
                rewardProgramName: redemption.rewardsProgram?.name,
                rewardProgramId: redemption.rewardsProgramId,
              }
            }) ?? []
          }
          slots={{ toolbar: GridToolbar }}
          getRowId={() => random(0, 1000, true)}
        />
      </div>
    </>
  )
}
