import { useApiQuery } from 'hooks/useApiQuery'

interface UseGetRewardsByRewardsProgramIdParams {
  rewardProgramId?: string
}

export const useGetRewardsByRewardsProgramId = ({
  rewardProgramId,
}: UseGetRewardsByRewardsProgramIdParams) => {
  return useApiQuery({
    path: '/le-connect/rewards-program-catalogs/{rewardsProgramId}',
    method: 'get',
    queryKey: [
      '/le-connect/rewards-program-catalogs/{rewardsProgramId}',
      rewardProgramId,
    ],
    queryArgs: {
      rewardsProgramId: Number(rewardProgramId),
    },
  })
}
