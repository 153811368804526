import CloseIcon from '@mui/icons-material/Close'
import { isPresent } from '@jjvgaming/player-payback-library'
import { Box, type Theme, Typography, type SxProps } from '@mui/material'
import { useEffect, useRef, type MutableRefObject, useCallback } from 'react'
import { useHeightActiveModalHeader } from './useHeightActiveModalHeader'

const commonSxModalProps: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  p: '16px',
  backgroundColor: 'rgba(247, 247, 246, 1)',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  borderBottom: '1px solid #e5e7eb',
}

export const ModalFullHeader = ({
  showIconClose,
  toggleIsOpen,
  title,
  subTitle,
}: {
  showIconClose?: boolean
  toggleIsOpen: () => void
  title?: string
  subTitle?: string
}) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const setHeightModalHeader = useHeightActiveModalHeader(
    (state) => state.setHeight
  )
  const adjustHeight = () => {
    if (ref.current) {
      setHeightModalHeader(ref.current.offsetHeight)
    }
  }

  const onResize = useCallback(() => {
    adjustHeight()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <Box sx={commonSxModalProps} ref={ref}>
      <Box px={{ xs: 1, sm: 2 }} py={{ xs: 2, sm: 1 }} className="w-[80%]">
        {isPresent(title) && (
          <Typography variant="h3" fontWeight={'bold'}>
            {title}
          </Typography>
        )}
        {isPresent(subTitle) && (
          <Typography variant="body-1">{subTitle}</Typography>
        )}
      </Box>
      <Box
        onClick={toggleIsOpen}
        sx={{ cursor: 'pointer' }}
        className="w-[20%]"
      >
        {isPresent(showIconClose) && <CloseIcon />}
      </Box>
    </Box>
  )
}
