import { useQueries } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetMultipleRewardsByLicensedEstablishmentId = ({
  licensedEstablishmentIds,
}: {
  licensedEstablishmentIds: number[]
}) => {
  const apiCall = useTypedApiClient({
    path: '/le-connect/rewards-programs/licensed-establishments/{id}',
    method: 'get',
  })
  return useQueries({
    queries: licensedEstablishmentIds.map((licensedEstablishmentId) => {
      return {
        queryKey: ['get-rewards-by-le-id', licensedEstablishmentId],
        queryFn: async () => {
          const { data } = await apiCall({ id: licensedEstablishmentId })

          return data
        },
      }
    }),
  })
}
