import type React from 'react'
import { useMemo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  type TooltipProps,
} from 'recharts'
import { parseISO, startOfWeek, format } from 'date-fns'
import { useLayout } from 'hooks/useLayout'
import { chartColors, colorPrimitives } from 'components/Theme'

const formatHour = (hour: number): string => {
  if (hour === 0) {
    return `12 AM`
  } else if (hour < 12) {
    return `${hour} AM`
  } else if (hour === 12) {
    return `12 PM`
  } else {
    return `${hour % 12} PM`
  }
}

interface DataPoint {
  hour: string
  [key: string]: number | string
}

interface Metric {
  amount: number
  percentage: number
  hour: number
  date: string
}

interface Props {
  data: Metric[]
  displayType: '$' | '%'
  endDate: Date
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  data: DataPoint[]
  displayType: '$' | '%'
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  data,
  displayType,
}) => {
  if (active && payload?.length) {
    const dataItem = data.find((item) => item.hour === label)

    const formatValue = (value: number) => {
      return displayType === '$'
        ? value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0, // This will remove decimals
          })
        : `${value}%` // Display as percentage
    }

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          textAlign: 'center', // Centers the text horizontally
        }}
      >
        <p>
          <strong>Hour: {dataItem?.hour}</strong>
        </p>
        <br />
        {payload.map((entry, index) => (
          <p key={index}>{`${entry.name}: ${formatValue(entry.value ?? 0)}`}</p>
        ))}
      </div>
    )
  }

  return null
}

export const CreditsWageredHourlyTrendsChart: React.FC<Props> = ({
  data,
  displayType,
  endDate,
}) => {
  const isMobile = useLayout()
  const processedData = useMemo((): DataPoint[] => {
    const weeklyData: Record<string, number[]> = {}

    const filteredData = data.filter((datum) => {
      const date = parseISO(datum.date)
      return date <= endDate
    })

    filteredData.forEach((datum) => {
      const date = parseISO(datum.date)
      // Format the date to "Week of MM/dd"
      const weekLabel = `Week of ${format(
        startOfWeek(date, { weekStartsOn: 1 }),
        'MM/dd'
      )}`
      weeklyData[weekLabel] = weeklyData[weekLabel] || Array(24).fill(0)

      const value = displayType === '$' ? datum.amount : datum.percentage
      weeklyData[weekLabel][datum.hour] += value
    })

    const reorderedData: DataPoint[] = []
    // Hours from 5 AM to 11 PM
    for (let hour = 5; hour < 24; hour++) {
      const hourlyData: DataPoint = { hour: formatHour(hour) }
      Object.keys(weeklyData).forEach((weekLabel) => {
        hourlyData[weekLabel] = weeklyData[weekLabel][hour]
      })
      reorderedData.push(hourlyData)
    }

    // Hours from 12 AM to 4 AM
    for (let hour = 0; hour < 5; hour++) {
      const hourlyData: DataPoint = { hour: formatHour(hour) }
      Object.keys(weeklyData).forEach((weekLabel) => {
        hourlyData[weekLabel] = weeklyData[weekLabel][hour]
      })
      reorderedData.push(hourlyData)
    }

    return reorderedData
  }, [data, displayType])

  const formatXAxis = (tickItem: number): string => {
    return displayType === '$'
      ? `$${tickItem.toLocaleString()}`
      : `${tickItem}%`
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart
        width={500}
        height={300}
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="hour"
          height={45}
          label={{
            value: 'Hour',
            position: 'insideBottom',
            offset: -2,
            fill: colorPrimitives.black,
          }}
          tick={{ fill: colorPrimitives.black }}
        />
        <YAxis
          tickFormatter={formatXAxis}
          style={{ fill: colorPrimitives.black }}
          label={{
            value: displayType === '$' ? 'Amount' : 'Percentage',
            angle: -90,
            position: 'insideLeft',
            fill: colorPrimitives.black,
          }}
          width={90}
        />
        <Tooltip
          content={
            <CustomTooltip data={processedData} displayType={displayType} />
          }
        />
        <Legend
          align="right"
          verticalAlign="top"
          layout="horizontal"
          wrapperStyle={{
            fontSize: isMobile ? '16px' : '18px',
            top: '0px',
          }}
          formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
        />
        {Object.keys(processedData[0])
          .filter((key) => key !== 'hour')
          .map((weekKey, index) => (
            <Line
              key={weekKey}
              type="linear"
              dataKey={weekKey}
              stroke={chartColors[index % chartColors.length]}
            />
          ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
